import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PaymentRazorpayService {

  constructor( private router: Router,
    private http: HttpClient) { }
  
  createOrder(inputAmount:Number, inputDealerId:String, inputComment: String) {
    var options = {
      amount: inputAmount,  // amount in the smallest currency unit
      currency: "INR",
      receipt: "RCPT_" + new Date().toString(),
      notes: {
        dealerId : inputDealerId,
        comment: inputComment
      } 
    };   
      return this.http.post<any>(`${environment.apiUrl}/gateway/createOrder`,options);
    }

  savePayment(paymentResponse:any){
      console.log("here: "  + JSON.stringify(paymentResponse));
      return this.http.post<any>(`${environment.apiUrl}/gateway/savePayment`,paymentResponse);

  }
}
