import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from '@app/_models';
import { Certificate } from '@app/_models/certificate';
import { AccountService } from '@app/_services';
import { CertificateService } from '@app/_services/certificate.service';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    user: User;
    showImage = false;
    masterPolicyNumber =  {
        key: "MasterPolicyNumber",
        heading: "Master Policy Maintain",
        configType:"String"
    } 
    financeMasterPolicyNumber =  {
        key: "financeMasterPolicyNumber",
        heading: "Finance Master Policy Maintain",
        configType:"String"
    } 
    fourWheelerMasterPolicyNumber =  {
        key: "fourWheelerMasterPolicyNumber",
        heading: "Four Wheeler Master Policy Maintain",
        configType:"String"
    } 
    fourWheelerMasterFinancePolicyNumber =  {
        key: "fourWheelerMasterFinancePolicyNumber",
        heading: "Four Wheeler Master Finance Policy Maintain",
        configType:"String"
    } 

    iffcotokioCpaPolicy=  {
        key: "iffcotokioCpaPolicy",
        heading: "Iffco Tokio CPA Policy",
        configType:"String"
    } 


    constructor(private accountService: AccountService) {
        this.user = this.accountService.userValue;
}

}