import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { DealerService } from '@app/_services/dealer-service';
import { SearchService } from '@app/_services/search.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dealer-balance-report',
  templateUrl: './dealer-balance-report.component.html',
  styleUrls: ['./dealer-balance-report.component.less']
})
export class DealerBalanceReportComponent implements OnInit {

  @ViewChild('aggrid') aggrid: AgGridAngular;
  certificateReportForm: UntypedFormGroup;
  submitted=false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  gridOptions: GridOptions;
  loading= false;
  isAdjustedBalancePositive = false;
  gridReady = true;
  reportSearch: ReportSearch;
  issueDateFromSearch : string;
  issueDateToSearch : string;
  reportCertificateData : any[];
  finalReportData: any[];
  currentDate = new Date();
  reportCreated = false;
  dealer : Dealer;
  totalCredits = 0;
  totalDebits = 0;


  user: User;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService, 
    private alertService: AlertService,
    private accountService: AccountService,
    private dealerService: DealerService

  ) { }

  ngOnInit(): void {
    this.user = this.accountService.userValue;
    this.gridOptions = <GridOptions>{}; 
    this.certificateReportForm = this.formBuilder.group({
      issueDateFrom: [''],
      issueDateTo: [''],
      dealerId: [''],
      balance: [''],
      adjustedBalance: [''],
      openingBalance:[''],
          });
    
    if (this.user.role == "admin") {
            this.certificateReportForm.get('dealerId').setValidators(Validators.required);
          this.certificateReportForm.controls['dealerId'].updateValueAndValidity();
        }
    
    
    this.gridOptions.defaultColDef = {
          resizable: true,      
        };
      
    this.gridOptions.columnDefs = [
             {headerName: "Serial No", valueGetter: "node.rowIndex + 1"}
            ,{headerName: "Transaction Date", field: "transactionDate",sortable: true, filter:true }
            ,{headerName: "Type", field: "transactionType",sortable: true, filter:true }
            ,{headerName: "Description", field: "comments",sortable: true, filter:true }
            ,{headerName: "Credited Amount", field: "credit",sortable: true, filter:true }
            ,{headerName: "Debited Amount", field: "debit",sortable: true, filter:true }
            ,{headerName: "Dealer Balance", field: "dealerBalance",sortable: true, filter:true }
          ]
          this.gridOptions.rowData = [];
          this.gridReady = true;
      
          this.certificateReportForm.get('issueDateFrom').valueChanges
          .subscribe(value => {
            if (value != undefined) {
              this.issueDateFromSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
            }
          });
      
          this.certificateReportForm.get('issueDateTo').valueChanges
          .subscribe(value => {
            if (value != undefined) {
              this.issueDateToSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
            }
            //console.log("issueDateToSearch: " + this.issueDateToSearch)
          });
      
    
        }
            // convenience getter for easy access to form fields
            get f() { return this.certificateReportForm.controls; }

            onSubmit(){
              this.submitted = true;
              this.loading = true;
              this.reportCreated = false;
              if (this.certificateReportForm.invalid) {
                this.loading = false;
                return;
              }
              
              this.alertService.clear();
              if (this.user.role !="admin" && this.user.role !="dealerOwner" && !(this.user.specialRoleAccess && this.user.specialRoleAccess.includes('CanViewDealerBalanceReport')))
              {
              this.alertService.error("Unauthorized access");
              this.submitted = false;
              this.loading = false;
              return;              
              }
              this.reportSearch = new ReportSearch();
              if (this.issueDateFromSearch == undefined)
              {
                this.reportSearch.issueDateFrom = "0001-01-01";
                this.issueDateFromSearch = this.currentDate.getFullYear() + "-" + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.currentDate.getDate()).slice(-2);; 
              }
              else
              this.reportSearch.issueDateFrom = this.issueDateFromSearch;
          
              if (this.issueDateToSearch == undefined)
              {
                this.reportSearch.issueDateTo = "9999-99-99";
              }
              else
              this.reportSearch.issueDateTo = this.issueDateToSearch;
              this.reportSearch.dealerId = this.certificateReportForm.get('dealerId').value;
              this.totalCredits = 0;
              this.totalDebits = 0;
              this.searchService.getDealerBalanceReport(this.reportSearch)
              .pipe(first())
              .subscribe({
                next: (reportData) => {
                  var reportTransactionRecord =   reportData.filter(x => x.dealerBalance != "NA")
                  if (reportTransactionRecord.length == 0)
                  {
                    if (this.user.role == "dealerOwner" || this.user.role == "executive" || this.user.role == "manager")
                    {
                      this.reportSearch.dealerId = this.user.dealerId;
                    }
                    this.dealerService.getByDealerId(this.reportSearch.dealerId)
                    .pipe(first())
                    .subscribe((a)=>{
                      this.dealer = a;
                      this.processReportData(reportData)
                    })
                  }
                  else
                  this.processReportData(reportData)
                },
                error: error => {
                  this.alertService.error(error)
                  this.loading = false;
                }
              })  
                  
            }  
            onReset(){
              this.reportCreated = false;
              this.submitted = false;
              this.loading = false;

            }
            exportToExcel(){
              const params = {
                columnGroups: true,
                allColumns: true,
                fileName: 'excelReport',
           //     columnSeparator: document.querySelector("#columnSeparator").value
              };
              this.aggrid.api.exportDataAsCsv(params);
              // this.aggrid.api.exportDataAsExcel(params);
            }
                 
processReportData(reportData: any[]){
  this.reportCertificateData = reportData.sort((a,b) => {
    if (a.transactionDate > b.transactionDate)
      return 1;
    if (a.transactionDate < b.transactionDate)
      return -1;
    else
      return 0;                    
  }
    ).map(
      y => {
        this.totalCredits = this.totalCredits + y.credit;
        this.totalDebits = this.totalDebits + y.debit;
        return y;
      }
    );
  var reportTransactionRecord =   this.reportCertificateData.filter(x => x.dealerBalance != "NA")
  var totalCredit = 0;
  for (let index=0; this.reportCertificateData[index] && this.reportCertificateData[index].dealerBalance == "NA"; index++) {
    totalCredit = totalCredit + this.reportCertificateData[index].credit - this.reportCertificateData[index].debit;    
  }
  var openingBalance = reportTransactionRecord.length > 0?(reportTransactionRecord[0].dealerBalance - reportTransactionRecord[0].credit - totalCredit + reportTransactionRecord[0].debit):this.dealer?this.dealer.dealerBalance:0;
  var adjustedBalance = this.totalCredits - this.totalDebits + openingBalance;
  this.reportCertificateData.push({
    "comments": "Total Credits and Debits :"
    ,"credit": (this.totalCredits).toFixed(2)
    ,"debit": (this.totalDebits).toFixed(2)
    ,"dealerBalance":(adjustedBalance).toFixed(2)
  });
  // this.reportCertificateData.push({
  //   "comments": "Balance (Credit - Debit) :"
  //   ,"credit": (this.totalCredits - this.totalDebits).toFixed(2)
  // });
  this.finalReportData = [];
  this.finalReportData[0] = {
    "comments": "Opening Balance :"
    ,"credit": 0
    ,"debit": 0
    ,"dealerBalance":(openingBalance).toFixed(2)
  };
  this.reportCertificateData.map(a => this.finalReportData.push(a));
  this.aggrid.api.setRowData(this.finalReportData);
  this.certificateReportForm.controls['balance'].setValue((this.totalCredits - this.totalDebits).toFixed(2));
  this.certificateReportForm.controls['adjustedBalance'].setValue((adjustedBalance).toFixed(2));
  this.certificateReportForm.controls['openingBalance'].setValue((reportTransactionRecord.length > 0?(reportTransactionRecord[0].dealerBalance - reportTransactionRecord[0].credit + reportTransactionRecord[0].debit):this.dealer.dealerBalance).toFixed(2));
  if (adjustedBalance >= 0)
      this.isAdjustedBalancePositive = true;
  else 
  this.isAdjustedBalancePositive = false;
  this.gridReady = true;
  this.loading = false;
  this.reportCreated = true;

}
  }
