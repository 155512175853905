<div class="container">
    <div class="row">
        <div class="col">
            <h4 >Package - {{selectPackage.packageNumber}}</h4>
        </div>
        <div class="col">
        </div>
        <div class="col">
            <h4 >Dealer - {{selectPackage.dealerName}}</h4>
        </div>
        </div>
</div>
<div *ngIf="showInsuranceDetails">
    <button (click)="backToSearch()" class="btn btn-sm btn-success mb-2">Back To Search Screen
    </button ><a>&nbsp;&nbsp;</a>
<nav aria-label="breadcrumb" >
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page" >
            <div class="breadcrumb-content">
                <i class="fa fa-car breadcrumb-icon" ></i>
        <p class="h5 breadcrumb-text" > Insurance Details</p>
    </div></li>
    </ol>
  </nav>
  <div class="row">
    <div class="col">
        <h5>{{isCorporate?"Organisation Name: ":"Name: " }}</h5>
        <h6 style="color:grey">{{selectPackage.firstName + " " + selectPackage.lastName}}</h6>
</div>
    <div class="col">
        <h5>{{isCorporate?"Organisation Address: ":"First Address: " }}</h5>
        <h6 style="color:grey">{{selectPackage.address}}</h6>
    </div>
    <div class="col" >
        <h5>{{"State: " }}</h5>
        <h6 style="color:grey">{{selectPackage.state}}</h6>
    </div>
    <div class="col" >
        <h5>{{"City: " }}</h5>
        <h6 style="color:grey">{{selectPackage.city}}</h6>
    </div>
    </div>
<div class="row">
    <div class="col">
        <h5>{{"Pincode: " }}</h5>
        <h6 style="color:grey">{{selectPackage.pincode}}</h6>
    </div>
    <div class="col">
        <h5 *ngIf="!isCorporate">{{"Aadhar Number: " }}</h5>
        <h5 *ngIf="isCorporate">{{"GST Number: " }}</h5>
        <h6 style="color:grey">{{selectPackage.aadhaarNumber}}</h6>
    </div>
    <div class="col">
        <h5>{{isCorporate?"Organisation Email: ":"Email: " }}</h5>
        <h6 style="color:grey">{{selectPackage.email}}</h6>
    </div>
    <div class="col">
        <h5>{{"Contact Number: " }}</h5>
        <h6 style="color:grey">{{selectPackage.contactNumber}}</h6>
    </div>
</div>
<div class="row">
    <div class="col">
        <h5>{{"Vehicle Code: " }}</h5>
        <h6 style="color:grey">{{selectPackage.dealerModelPrice && selectPackage.dealerModelPrice.orientalVariantCode ? selectPackage.dealerModelPrice.orientalVariantCode : selectPackage.model}}</h6>
    </div>
    <div class="col">
        <h5>{{"Vehicle Type: " }}</h5>
        <h6 style="color:grey">{{selectPackage.type == "New"?"W":""}}</h6>
    </div>
    <div class="col">
        <h5>{{"Vehicle Class " }}</h5>
        <h6 style="color:grey">{{"CLASS_3"}}</h6>
    </div>
    <div class="col">
        <h5>{{"IDV: " }}</h5>
        <h6 style="color:grey">{{selectPackage.idv}}</h6>
    </div>
</div>
<div class="row">
    <div class="col">
        <h5>{{"Risk Start Date (YYYY-MM-DD): " }}</h5>
        <h6 style="color:grey">{{selectPackage.startDate| date:'dd-MM-yyyy':'+0530'}}</h6>
</div>
    <div class="col">
        <h5>{{"RTO: " }}</h5>
        <h6 style="color:grey">{{selectPackage.rto}}</h6>
    </div>
    <div class="col">
        <h5>{{"Engine Number: " }}</h5>
        <h6 style="color:grey">{{selectPackage.engineNumber}}</h6>
    </div>
    <div class="col">
        <h5>{{"Chasis Number: " }}</h5>
        <h6 style="color:grey">{{selectPackage.chasisNumber}}</h6>
    </div>
</div>
<div class="row">
    <div class="col">
        <h5>{{"Vehicle Color " }}</h5>
        <h6 style="color:grey">{{selectPackage.vehicleColor ? selectPackage.vehicleColor : "As Per Invoice"}}</h6>
</div>
    <div class="col">
        <h5>{{"Nil Dep: " }}</h5>
        <h6 style="color:grey">{{selectPackage.nilDep}}</h6>
    </div>
    <div class="col"  *ngIf="selectPackage.type == 'Old'">
        <h5>{{"Vehicle Registration Date(YYYY-MM-DD): " }}</h5>
        <h6 style="color:grey">{{selectPackage.vehicleRegistrationDate.year + "-" + selectPackage.vehicleRegistrationDate.month + "-" + selectPackage.vehicleRegistrationDate.day}}</h6>
    </div>
    <div class="col" >
        <h5>{{"RTI Cover: " }}</h5>
        <h6 style="color:grey">{{selectPackage.rtiCover}}</h6>
    </div>
    <div class="col"  *ngIf="selectPackage.type == 'New'">
    </div>
</div>
<div class="row" *ngIf="selectPackage.financeCompanyName">
    <div class="col">
        <h5>{{"Hypothication Co State " }}</h5>
        <h6 style="color:grey">{{selectPackage.financeCompanyState ? selectPackage.financeCompanyState : ""}}</h6>
</div>
    <div class="col">
        <h5>{{"Hypothication Co City: " }}</h5>
        <h6 style="color:grey">{{selectPackage.financeCompanyCity ? selectPackage.financeCompanyCity: ""}}</h6>
    </div>
    <div class="col"  *ngIf="selectPackage.financeCompanyName">
        <h5>{{"Hypothication Co Name: " }}</h5>
        <h6 style="color:grey">{{selectPackage.financeCompanyName}}</h6>
    </div>
    <div class="col" *ngIf="selectPackage.financeCompanyAddress">
        <h5>{{"Hypothication Co Address: " }}</h5>
        <h6 style="color:grey">{{selectPackage.financeCompanyAddress}}</h6>
    </div>
</div>
</div>
<nav aria-label="breadcrumb" >
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">
            <div class="breadcrumb-content">
                <i class="fa fa-car breadcrumb-icon" ></i>
        <p class="h5 breadcrumb-text" > Vehicle Details</p>
    </div></li>

    </ol>
  </nav>
  <div class="row">
    <div class="col">
        <h5>{{"Vehicle Type: " }}</h5>
        <h6 style="color:grey">{{selectPackage.type}}</h6>
    </div>
    <div class="col">
        <h5>{{"Vehicle Make: " }}</h5>
        <h6 style="color:grey">{{selectPackage.vehicleMake}}</h6>
    </div>
    <div class="col">
        <h5>{{"Model: " }}</h5>
        <h6 style="color:grey">{{selectPackage.model}}</h6>
    </div>
    <div class="col">
        <h5>{{"Variant: " }}</h5>
        <h6 style="color:grey">{{selectPackage.variant}}</h6>
    </div>
    </div>
    <div class="row">
        <div class="col">
            <h5>{{"Manufacturing Year: " }}</h5>
            <h6 style="color:grey">{{selectPackage.vehicleYear}}</h6>
        </div>
        <div class="col">
            <h5>{{"Registration Number: " }}</h5>
            <h6 style="color:grey">{{selectPackage.registrationNumber}}</h6>
        </div>
        <div class="col">
            <h5>{{"Chasis Number: " }}</h5>
            <h6 style="color:grey">{{selectPackage.chasisNumber}}</h6>
        </div>
        <div class="col">
            <h5>{{"Engine Number: " }}</h5>
            <h6 style="color:grey">{{selectPackage.engineNumber}}</h6>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h5>{{"RTO: " }}</h5>
            <h6 style="color:grey">{{selectPackage.rto}}</h6>
        </div>
        <div class="col">
            <h5>{{"Hypothecated?: " }}</h5>
            <h6 style="color:grey">{{selectPackage.hypothecated}}</h6>
        </div>
        <div class="col"  *ngIf="selectPackage.financeCompanyName">
            <h5>{{"Finance Company: " }}</h5>
            <h6 style="color:grey">{{selectPackage.financeCompanyName}}</h6>
        </div>
        <div class="col" *ngIf="selectPackage.financeCompanyAddress">
            <h5>{{"Finance Company Address: " }}</h5>
            <h6 style="color:grey">{{selectPackage.financeCompanyAddress}}</h6>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card" >
                <img *ngIf="invoiceImageSrc" height="200px" [src]="invoiceImageSrc" class="card-img-top"  alt="...">
                <div class="card-body">
                  <h5 class="card-title">Invoice</h5>
                  <a href="javascript:void(0);" (click)="downloadImage('invoice')" *ngIf="invoiceImageSrc" >Download</a>
                </div>
              </div>   
        </div>
        <div class="col">
            <h5 >{{selectPackage.oldValueType && selectPackage.oldValueType =='PrevIdv'?"Previous Year IDV":"Invoice Amount: " }}</h5>
            <h6 style="color:grey">{{selectPackage.invoiceValue}}</h6>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h5>{{"Cubic Capacity: "}}</h5>
            <h6 style="color:grey">{{selectPackage.cubicCapacity}}</h6>
        </div>
    </div>
    <nav aria-label="breadcrumb" >
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
                <div class="breadcrumb-content">
                    <i class="fa fa-book breadcrumb-icon" ></i>
            <p class="h5 breadcrumb-text" > Package Details</p></div></li>
        </ol>
      </nav>
      <div class="row">
        <!-- <div class="col">
            <h5>{{"Insurance Company: " }}</h5>
            <h6 style="color:grey">{{selectPackage.insuranceCompany}}</h6>
        </div> -->
        <div class="col">
            <h5>{{"Package Name: " }}</h5>
            <h6 style="color:grey">{{selectPackage.package.packageName}}</h6>
        </div>
        <div class="col"  *ngIf="selectPackage.type !='Old'">
            <h5>{{"Invoice Value & Accessories cost: " }}</h5>
            <h6 style="color:grey">{{selectPackage.idv}}</h6>
        </div>
        <div class="col"  *ngIf="selectPackage.type =='Old'">
            <h5>{{"Previous Policy Expired: " }}</h5>
            <h6 style="color:grey">{{selectPackage.prevPolicyExpire}}</h6>
        </div>
        <div class="col">
            <h5>{{"Nil Depreciation: " }}</h5>
            <h6 style="color:grey">{{selectPackage.nilDep}}</h6>
        </div>
        <div class="col">
            <h5>{{"Estimate Insurance Cost: " }}</h5>
            <h6 style="color:grey">{{selectPackage.estimatedPolicyTotalAmount && selectPackage.estimatedPolicyTotalAmount > 0?selectPackage.estimatedPolicyTotalAmount:selectPackage.packagePolicyTotalAmount}}</h6>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h5>{{"Risk Start Date (YYYY-MM-DD): " }}</h5>
            <h6 style="color:grey">{{selectPackage.startDate| date:'dd-MM-yyyy hh:mm:ss a':'+0530'}}</h6>
        </div>
        <div class="col"  *ngIf="selectPackage.type !='Old'">
            <h5>{{"RTI Cover: " }}</h5>
            <h6 style="color:grey">{{selectPackage.rtiCover}}</h6>
        </div>
        <div class="col"  *ngIf="selectPackage.type !='Old'">
        </div>
        <div class="col"  *ngIf="selectPackage.type !='Old'">
        </div>
        <div class="col"  *ngIf="selectPackage.type =='Old'">
            <h5>{{"Previous Policy Claim: " }}</h5>
            <h6 style="color:grey">{{selectPackage.prePolClaim}}</h6>
        </div>
        <div class="col"  *ngIf="selectPackage.type =='Old'">
            <h5>{{"Claim Year: " }}</h5>
            <h6 style="color:grey">{{claimYear}}</h6>
        </div>
        <div class="col"  *ngIf="selectPackage.type =='Old'">
            <h5>{{"Transfer Case: " }}</h5>
            <h6 style="color:grey">{{selectPackage.transferCase}}</h6>
        </div>
    </div>
    <div class="row" *ngIf=" selectPackage.package.prevInsuranceDetailShow.includes('GetODDetails')">
        <div class="col">
            <h5>{{"Previous OD Insurer: " }}</h5>
            <h6 style="color:grey">{{selectPackage.prevOdInsurer}}</h6>
        </div>
        <div class="col">
            <h5>{{"Previous OD Policy Number: " }}</h5>
            <h6 style="color:grey">{{selectPackage.prevOdPolicyNo}}</h6>
        </div>
        <div class="col" >
            <h5>{{"Previous OD Policy Expiry Date: " }}</h5>
            <h6 style="color:grey">{{selectPackage.prevOdPolicyExpiry.year + "-" + (selectPackage.prevOdPolicyExpiry.month < 10?"0" + selectPackage.prevOdPolicyExpiry.month: selectPackage.prevOdPolicyExpiry.month) + "-" + (selectPackage.prevOdPolicyExpiry.day < 10?"0" + selectPackage.prevOdPolicyExpiry.day: selectPackage.prevOdPolicyExpiry.day) }}</h6>
        </div>
        <div class="col">
            <h5>{{"RTI Cover: " }}</h5>
            <h6 style="color:grey">{{selectPackage.rtiCover}}</h6>
            </div>
    </div>
    <div class="row" *ngIf=" selectPackage.package.prevInsuranceDetailShow.includes('GetTPDetails')">
        <div class="col">
            <h5>{{"Previous TP Insurer: " }}</h5>
            <h6 style="color:grey">{{selectPackage.prevTpInsurer}}</h6>
        </div>
        <div class="col">
            <h5>{{"Previous TP Policy Number: " }}</h5>
            <h6 style="color:grey">{{selectPackage.prevTpPolicyNo}}</h6>
        </div>
        <div class="col" >
            <h5>{{"Previous TP Policy Expiry Date: " }}</h5>
            <h6 style="color:grey">{{selectPackage.prevTpPolicyExpiry.year + "-" + (selectPackage.prevTpPolicyExpiry.month < 10?"0" + selectPackage.prevTpPolicyExpiry.month: selectPackage.prevTpPolicyExpiry.month) + "-" + (selectPackage.prevTpPolicyExpiry.day < 10?"0" + selectPackage.prevTpPolicyExpiry.day: selectPackage.prevTpPolicyExpiry.day) }}</h6>
        </div>
        <div class="col">
        </div>
    </div>
    <nav aria-label="breadcrumb" *ngIf="selectPackage.breakInspectionReportNumber">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
                <div class="breadcrumb-content">
                    <i class="fa fa-book breadcrumb-icon" ></i>
            <p class="h5 breadcrumb-text" > Inspection Details</p></div></li>
        </ol>
      </nav>
    <div class="row" *ngIf="selectPackage.breakInspectionReportNumber">
        <div class="col">
            <h5>{{"Inspection Number: " }}</h5>
            <h6 style="color:grey">{{selectPackage.breakInspectionReportNumber}}</h6>
            </div>
            <div class="col">
                <h5>{{"Inspection Date: " }}</h5>
                <h6 style="color:grey">{{selectPackage.breakInspectionReportDate}}</h6>
                </div>
                <div class="col">
                    <h5>{{"Inspection Time: " }}</h5>
                    <h6 style="color:grey">{{selectPackage.breakInspectionReportTime}}</h6>
                    </div>
                    <div class="col">
            <h5>{{"Inspection Report: " }}</h5>
            <div class="card" >
                <img *ngIf="breakInspectionReportImageSrc" height="200px" [src]="breakInspectionReportImageSrc" class="card-img-top"  alt="...">
                <div class="card-body">
                  <h5 class="card-title">Inspection Report</h5>
                  <a href="javascript:void(0);" (click)="downloadImage('breakInspectionReport')"  >{{selectPackage.breakInspectionReportNumber}}</a>
                </div>
              </div>   
        </div>

    </div>
    <nav aria-label="breadcrumb" *ngIf="user.role == 'admin'">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <i class="fa fa-user breadcrumb-icon" ></i>
            <p class="h5 breadcrumb-text"  > Premium Calculation</p>
        </ol>
      </nav>
      <div class="container" *ngIf="selectPackage.type == 'New' && user.role == 'admin'">
        <div class="row">
            <h4>Insurance Policy Amount</h4>
        </div>
        <div class="row" >
            <div class="col">
                <h6>{{"Policy Amount: " }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 style="color:grey">{{selectPackage.insurancePolicyAmount? selectPackage.insurancePolicyAmount.toFixed(2): 0.00}}</h6>
            </div>
        </div>
        <div class="row" >
            <div class="col">
                <h6>{{"GST Amount: " }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 style="color:grey">{{selectPackage.insurancePolicyGStAmount? selectPackage.insurancePolicyGStAmount.toFixed(2): 0.00}}</h6>
            </div>
        </div>
        <div class="row" >
            <div class="col">
                <h6>{{"Total Plan Amount: " }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 style="color:grey">{{selectPackage.insurancePolicyTotalAmount? selectPackage.insurancePolicyTotalAmount.toFixed(2): 0.00}}</h6>
            </div>
        </div>
        <div class="row">
            <h4>CPA Amount</h4>
        </div>
        <div class="row" >
            <div class="col">
                <h6>{{"Plan Amount: " }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 style="color:grey">{{selectPackage.planAmount? selectPackage.planAmount.toFixed(2): 0.00}}</h6>
            </div>
        </div>
        <div class="row" >
            <div class="col">
                <h6>{{"GST Amount: " }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 style="color:grey">{{selectPackage.planGSTAmount? selectPackage.planGSTAmount.toFixed(2): 0.00}}</h6>
            </div>
        </div>
        <div class="row" >
            <div class="col">
                <h6>{{"Total Plan Amount: " }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 style="color:grey">{{selectPackage.planTotalAmount? selectPackage.planTotalAmount.toFixed(2): 0.00}}</h6>
            </div>
        </div>
        <div class="row">
            <h4>Summary</h4>
        </div>
            <div class="container">
                <div class="row" >
                    <div class="col">
                        <h5>{{"Total Calculated Amount(Actual Amount Charged): " }}</h5> 
                    </div>
                    <div class="col" style="text-align: end;">
                        <h6 >{{selectPackage.packagePolicyTotalAmount ? selectPackage.packagePolicyTotalAmount.toFixed(2):0.00}}</h6>
                    </div>
                </div>
                <div class="row" >
                    <div class="col">
                        <h5>{{"Total Estimated Amount(Shown to Dealer): " }}</h5> 
                    </div>
                    <div class="col" style="text-align: end;">
                        <h6 >{{selectPackage.estimatedPolicyTotalAmount? selectPackage.estimatedPolicyTotalAmount.toFixed(2):0.00}}</h6>
                    </div>
                </div>
                <div class="row" >
                    <div class="col">
                        <h5>{{"Difference(Total Calculated Amount - Total Package Amount): "  }}</h5> 
                    </div>
                    <div class="col" style="text-align: end;">
                        <h6 >{{((selectPackage.packagePolicyTotalAmount ? selectPackage.packagePolicyTotalAmount:0.00) 
                        - (selectPackage.estimatedPolicyTotalAmount? selectPackage.estimatedPolicyTotalAmount:0.00) ).toFixed(2)}}</h6>
                    </div>
                </div>
            </div>

      </div>
      <div class="container" *ngIf="selectPackage.type == 'Old' &&  ['admin','employee'].includes(user.role)">
        <div class="row">
            <h4>OD Premium Calculation</h4>
        </div>
        <div class="container">
        <div class="row" >
            <div class="col">
                <h6>{{"OD Premium Amount(" + selectPackage.odPremiumRate +"): " }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 style="color:grey">{{selectPackage.odPremiumAmount.toFixed(2)}}</h6>
            </div>
        </div>
        <div class="row" >
            <div class="col">
                <h6>{{"OD Discount(" + selectPackage.odDiscountPercentage +"): "  }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 style="color:grey">-{{selectPackage.odDiscountAmount.toFixed(2)}}</h6>
            </div>
        </div>
        <div class="row" >
            <div class="col">
                <h6>{{"NCB Discount(" + selectPackage.odNCBPercentage +"): "  }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 style="color:grey">-{{selectPackage.odNCBAmount.toFixed(2)}}</h6>
            </div>
        </div>
        <div class="row" >
            <div class="col">
                <h6>{{"Nil Dep Cover Amount(" + selectPackage.nilDepPercentage +"): "  }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 style="color:grey">{{selectPackage.nilDepAmount.toFixed(2)}}</h6>
            </div>
        </div>
        <div class="row" >
            <div class="col">
                <h6>{{"RTI Cover Amount(" + selectPackage.rtiCoverPercentage +"): "  }}</h6> 
            </div>
            <div class="col" style="text-align: end;">
                 <h6 style="color:grey">{{selectPackage.rtiCoverAmount.toFixed(2)}}</h6>
            </div>
        </div>
        <div class="row" >
            <div class="col">
                <h5>{{"Total OD Premium Amount: " }}</h5> 
            </div>
            <div class="col" style="text-align: end;">
                <h6 >{{(selectPackage.odPremiumAmount - selectPackage.odDiscountAmount -selectPackage.odNCBAmount + selectPackage.nilDepAmount + selectPackage.rtiCoverAmount ).toFixed(2)}}</h6>
            </div>
        </div>

    </div>
    <div class="row">
        <h4>TP Premium Calculation</h4>
    </div>
        <div class="container">
            <div class="row" >
                <div class="col">
                    <h6>{{"TP Premium Amount: " }}</h6> 
                </div>
                <div class="col" style="text-align: end;">
                    <h6 style="color:grey">{{selectPackage.tpPremiumAmount.toFixed(2)}}</h6>
                </div>
            </div>
            <div class="row" >
                <div class="col">
                    <h6>{{"CPA Cover Amount (" + selectPackage.cpaCoverPlanName + "): " }}</h6> 
                </div>
                <div class="col" style="text-align: end;">
                    <h6 style="color:grey">{{selectPackage.CpaCoverAmount.toFixed(2)}}</h6>
                </div>
            </div>
            <div class="row" >
                <div class="col">
                    <h6>{{"TPPD Cover Amount: " }}</h6> 
                </div>
                <div class="col" style="text-align: end;">
                    <h6 style="color:grey">{{selectPackage.tpPdCoverAmount.toFixed(2)}}</h6>
                </div>
            </div>
            <div class="row" >
                <div class="col">
                    <h5>{{"Total TP Premium Amount: " }}</h5> 
                </div>
                <div class="col" style="text-align: end;">
                    <h6 >{{(selectPackage.tpPremiumAmount + selectPackage.CpaCoverAmount + selectPackage.tpPdCoverAmount ).toFixed(2)}}</h6>
                </div>
            </div>

        </div>    
        <div class="row">
            <h4>Summary</h4>
        </div>
            <div class="container">
                <div class="row" >
                    <div class="col">
                        <h6>{{"Total GST Amount: " }}</h6> 
                    </div>
                    <div class="col" style="text-align: end;">
                        <h6 style="color:grey">{{selectPackage.totalGstAmount.toFixed(2)}}</h6>
                    </div>
                </div>
                <div class="row" >
                    <div class="col">
                        <h5>{{"Total Calculated Amount(Shown to Dealer): " }}</h5> 
                    </div>
                    <div class="col" style="text-align: end;">
                        <h6 >{{(selectPackage.tpPremiumAmount + selectPackage.CpaCoverAmount + selectPackage.tpPdCoverAmount + selectPackage.totalGstAmount 
                        + selectPackage.odPremiumAmount - selectPackage.odDiscountAmount -selectPackage.odNCBAmount 
                        + selectPackage.nilDepAmount + selectPackage.rtiCoverAmount ).toFixed(2)}}</h6>
                    </div>
                </div>
                <div class="row" >
                    <div class="col">
                        <h5>{{"Total Package Amount(Actual Amount Charged): " }}</h5> 
                    </div>
                    <div class="col" style="text-align: end;">
                        <h6 >{{selectPackage.packagePolicyTotalAmount.toFixed(2)}}</h6>
                    </div>
                </div>
                <div class="row" >
                    <div class="col">
                        <h5>{{"Difference(Total Calculated Amount - Total Package Amount): "  }}</h5> 
                    </div>
                    <div class="col" style="text-align: end;">
                        <h6 >{{(selectPackage.tpPremiumAmount + selectPackage.CpaCoverAmount + selectPackage.tpPdCoverAmount + selectPackage.totalGstAmount 
                        + selectPackage.odPremiumAmount - selectPackage.odDiscountAmount -selectPackage.odNCBAmount 
                        + selectPackage.nilDepAmount + selectPackage.rtiCoverAmount - selectPackage.packagePolicyTotalAmount ).toFixed(2)}}</h6>
                    </div>
                </div>
            </div>    
          </div>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <p class="fa fa-user breadcrumb-icon" ></p>
            <p class="h5 breadcrumb-text"  > KYC Details</p>
        </ol>
      </nav>
    <div class="row">
        <div class="col">
            <div class="card" >
                <img *ngIf="panImageSrc" height="200px" [src]="panImageSrc" class="card-img-top"  alt="...">
                <div class="card-body">
                  <h5 class="card-title">PAN Number</h5>
                  <a href="javascript:void(0);" (click)="downloadImage('pan')"  >{{selectPackage.panNumber}}</a>
                </div>
              </div>   
        </div>
        <div class="col">
            <div class="card" >
                <img height="200px" *ngIf="aadhaarImageSrc" [src]="aadhaarImageSrc" class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title" *ngIf="!isCorporate">{{"Aadhaar Number: " + selectPackage.aadhaarNumber}}</h5>
                  <h5 class="card-title" *ngIf="isCorporate">{{"GST Number: " + selectPackage.aadhaarNumber}}</h5>
                  <a (click)="downloadImage('aadhaar')" href="javascript:void(0);" *ngIf="aadhaarImageSrc">{{selectPackage.aadhaarNumber}}</a>
                  <!-- <p *ngIf="!aadhaarImageSrc">{{selectPackage.aadhaarNumber}}</p> -->
                </div>
              </div>   
        </div>
    </div>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <div class="breadcrumb-content">
                <i class="fa fa-user breadcrumb-icon" ></i>
            <p class="h5 breadcrumb-text"  *ngIf = !isCorporate> Customer Details</p>
            <p class="h5 breadcrumb-text"  *ngIf = isCorporate> Corporate Details</p></div></li>
        </ol>
      </nav>

<div class="row">
    <div class="col" *ngIf = !isCorporate>
        <h5>{{"Title: " }}</h5>
        <h6 style="color:grey">{{selectPackage.title?selectPackage.title:''}}</h6>
    </div>
<div class="col">
            <h5>{{isCorporate?"Organisation Name: ":"First Name: " }}</h5>
            <h6 style="color:grey">{{selectPackage.firstName}}</h6>
        </div>
        <div class="col" *ngIf = !isCorporate>
            <h5>{{"Last Name: " }}</h5>
            <h6 style="color:grey">{{selectPackage.lastName}}</h6>
        </div>
        <div class="col">
            <h5>{{isCorporate?"Organisation Email: ":"Email: " }}</h5>
            <h6 style="color:grey">{{selectPackage.email}}</h6>
        </div>
</div>
<div class="row" >
    <div class="col" *ngIf = !isCorporate>
        <h5>{{"Gender: " }}</h5>
        <h6 style="color:grey">{{selectPackage.gender}}</h6>
    </div>
    <div class="col" *ngIf = !isCorporate>
        <h5>{{"Date of Birth: " }}</h5>
        <h6 style="color:grey">{{selectPackage.dob}}</h6>
    </div>
    <div class="col" >
        <h5>{{"Contact Number: " }}</h5>
        <h6 style="color:grey">{{selectPackage.contactNumber}}</h6>
    </div>
    <div class="col" >
    </div>
    <div class="col" *ngIf = isCorporate>
    </div>
    <div class="col" *ngIf = isCorporate>
    </div>
</div>
<div class="row">
    <div class="col">
        <h5>{{isCorporate?"Organisation Address: ":"First Address: " }}</h5>
        <h6 style="color:grey">{{selectPackage.address}}</h6>
    </div>
    <div class="col" >
        <h5>{{"State: " }}</h5>
        <h6 style="color:grey">{{selectPackage.state}}</h6>
    </div>
    <div class="col" >
        <h5>{{"City: " }}</h5>
        <h6 style="color:grey">{{selectPackage.city}}</h6>
    </div>
    <div class="col" >
        <h5>{{"Pincode: " }}</h5>
        <h6 style="color:grey">{{selectPackage.pincode}}</h6>
    </div>
</div>
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">
            <div class="breadcrumb-content">

            <i class="fa fa-user breadcrumb-icon" ></i>
        <p class="h5 breadcrumb-text"  *ngIf = !isCorporate> Nominee Details</p>
        <p class="h5 breadcrumb-text"  *ngIf = isCorporate> Contact Person Details</p></div></li>
    </ol>
  </nav>
  <div class="row">
    <div class="col">
        <h5>{{isCorporate?"Name: ":"Nominee Name: " }}</h5>
        <h6 style="color:grey">{{selectPackage.nomineeName}}</h6>
    </div>
    <div class="col">
        <h5>{{isCorporate?"Gender: ":"Nominee Gender: " }}</h5>
        <h6 style="color:grey">{{selectPackage.nomineeGender}}</h6>
    </div>
    <div class="col">
        <h5>{{isCorporate?"Age: ":"Nominee Age: " }}</h5>
        <h6 style="color:grey">{{selectPackage.nomineeAge}}</h6>
    </div>
    <div class="col" *ngIf = !isCorporate>
        <h5>{{"Nominee Relationship: " }}</h5>
        <h6 style="color:grey">{{selectPackage.nomineeRelationship}}</h6>
    </div>
    <div class="col" *ngIf = isCorporate>
        <h5>{{"Contact Number: " }}</h5>
        <h6 style="color:grey">{{selectPackage.employeeContactNumber}}</h6>
    </div>
</div>
