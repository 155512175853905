import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { Invoice } from '@app/_models/invoice';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { first } from 'rxjs/operators';
import {Payouts} from '../../_models/payout';

@Component({
  selector: 'app-add-payout',
  templateUrl: './add-payout.component.html',
  styleUrls: ['./add-payout.component.less']
})
export class AddPayoutComponent implements OnInit {
  id:string;
  payoutForm: UntypedFormGroup;
  payoutDetailForm: UntypedFormGroup;
  numberofRecords = [10,20,50,100,200,500]
  isPayoutShow = false;
  submitted=false;
  loading=false;
  isBalanceUpdated=false;
  dealer:Dealer;
  payout:Payouts;
  payoutDetails: Payouts[];
  isPayoutAddFormShow = false;
  isPayoutDetailShow = false;
  currentDate = new Date();
  user:User;
  invoices: Invoice[];
  selectedInvoice: Invoice;

  constructor(
    private route: ActivatedRoute,
    private dealerService: DealerService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private configDataService: ConfigDataService,
    private accountService: AccountService

  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.payoutForm = this.formBuilder.group({
      payoutAmount: ['', Validators.required],
      payoutGSTAmount: ['', Validators.required],
      actualPayoutAmount: ['', Validators.required],
      actualPayoutGSTAmount: ['', Validators.required],
      invoiceNumber: [''],
      receiverPanNumber: [''],
      receiverGstNumber: [''],
      receiverAccountNumber: [''],
      comments: ['',Validators.required],
          });   
    
    this.payoutDetailForm = this.formBuilder.group({
      recordCount: [10, Validators.required],
                });   
    
    this.dealerService.getByDealerId(this.id)
    .pipe(first())
    .subscribe({
      next: (x) =>{
        this.dealer = x;
        this.invoices = Array.from(Object.entries(this.dealer.dealerInvoice).map(([key,value]) => (new Invoice(value))));
        this.selectedInvoice = this.invoices.filter(a => a.invoiceStatus == "Submitted For Payment")[0];
        if (this.selectedInvoice){
        this.dealer.printInvoiceKey = this.selectedInvoice.invoiceYear + "-" + this.selectedInvoice.invoiceMonth + "-NotSubmitted";
        this.payoutForm.controls['payoutAmount'].setValue(this.selectedInvoice.dealerPendingRefund);
        this.payoutForm.controls['payoutGSTAmount'].setValue(this.selectedInvoice.dealerGstPendingRefund);
        this.payoutForm.controls['invoiceNumber'].setValue(this.selectedInvoice.invoiceNumber);
        this.payoutForm.controls['actualPayoutAmount'].setValue((parseFloat(this.selectedInvoice.dealerPendingRefund) * .9925).toFixed(2));
        this.payoutForm.controls['actualPayoutGSTAmount'].setValue(this.selectedInvoice.dealerGstPendingRefund);
        }
        this.payoutForm.controls['receiverPanNumber'].setValue(this.dealer.dealerBankOwnerPan);
        this.payoutForm.controls['receiverGstNumber'].setValue(this.dealer.dealerGstId);
        this.payoutForm.controls['receiverAccountNumber'].setValue(this.dealer.dealerBankAccountNumber);

      }
    })
    
        }
  get pmntForm() { return this.payoutForm.controls; }
  get pmntDtlForm() { return this.payoutDetailForm.controls; }

  payoutShowToggle(){
    this.isPayoutShow = !this.isPayoutShow;
  }
  payoutPlanFormToggle(){
    this.isPayoutAddFormShow = !this.isPayoutAddFormShow;
    this.isBalanceUpdated = false;
    if (this.isPayoutAddFormShow) this.isPayoutDetailShow = false;

  }
  onPayoutSubmit(){
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.payoutForm.invalid) {
      return;
    }

    this.loading = true;
    this.user = this.accountService.userValue;
    this.payout = new Payouts(this.payoutForm.value)
    this.payout.dealerId = this.id;
    this.payout.payoutDate = this.currentDate.getFullYear() + "-" + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.currentDate.getDate()).slice(-2);
    this.payout.userId = this.user.id;
    if (this.dealer.printInvoiceKey){
      this.payout.dealerInvoiceKey = this.dealer.printInvoiceKey;
    }
    this.payout.userName = this.user.username;
    this.dealerService.addDealerPayout(this.payout)
    .pipe(first())
    .subscribe({
        next: () => {
          this.alertService.success("payout added successfully. please refresh browser to see updated balance");
          this.loading = false;
          this.isBalanceUpdated = true;
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;
        }
    });
    this.submitted=false;
  }

  onReset(){
    window.location.reload();
  }
  payoutDetailFormToggle(){
    this.isPayoutDetailShow = !this.isPayoutDetailShow;
    if (this.isPayoutDetailShow) this.isPayoutAddFormShow = false;
  }

  getPayoutDetail(){
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.payoutDetailForm.invalid) {
      return;
    }

    this.loading = true;
    this.dealerService.getDealerPayout({"id": this.id, "recordCount" : this.payoutDetailForm.get('recordCount').value})
    .pipe(first())
    .subscribe({
        next: (x) => {
          this.payoutDetails = x;
          this.loading = false;
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;

        }
    });
    this.submitted=false;

  }

  payoutScreen(){
    this.router.navigate(['/users/dealerPayout/' + this.id], { relativeTo: null });
  }
}