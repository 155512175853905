import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-issue-cert-layout',
  templateUrl: './issue-cert-layout.component.html',
  styleUrls: ['./issue-cert-layout.component.less']
})
export class IssueCertLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
