import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Certificate } from '@app/_models/certificate';
import { Dealer } from '@app/_models/dealer';
import { Package } from '@app/_models/package';
import { PlanDetails } from '@app/_models/planDetails';
import { AlertService } from '@app/_services/alert.service';
import { CertificateService } from '@app/_services/certificate.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { FileService } from '@app/_services/file.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { PackageService } from '../package.service';
import { AccountService } from '@app/_services';
import { User } from '@app/_models';

@Component({
  selector: 'app-process-package',
  templateUrl: './process-package.component.html',
  styleUrls: ['./process-package.component.less']
})
export class ProcessPackageComponent implements OnInit {
  selectPackage:Package;
  packageForm: UntypedFormGroup;
  startDateModel: NgbDateStruct;
  maxAmount:number;
  minAmount:number;
  submitted = false;
  loading = false;
  currentDate: Date = new Date();
  dealer: Dealer;
  plans: PlanDetails[];
  selectedPlan:PlanDetails;
  startDate = new Date();
  endDate = new Date();
  showGstAmount= true;
  showFinance = false;
  financeCompanies: string[];
  planTotalAmount: number;
  planAmount: number;
  planGSTAmount: number;
  gstPercentage = 0.18;
  dobString: any;
  user:User;
  isBalanceLow: boolean;
  runningBalance: number;
  issueDate: Date;
  selectedFile: any;
  policyPdfUrl: any;
  insurancePolicyTotalAmount: number;
  packagePolicyTotalAmount: number;
  packagePolicyAmount: number;
  insurancePolicyAmount: number;
  insuranceCompanies = ["The Oriental Insurance Co. Ltd.","United India Insurance Co. Ltd.","Bajaj Allianz General Insurance Co. Ltd."];
  employeeList: any[];


  constructor(    private formBuilder: UntypedFormBuilder,
    private configDataService:ConfigDataService,
    private accountService:AccountService,
    private dealerService: DealerService,
    private packageService:PackageService, private certificateService:CertificateService,
    private fileServices:FileService,
    private alertService:AlertService,private router: Router,) { }

  ngOnInit(): void {
    this.selectPackage = this.packageService.printPackage;
    this.user = this.accountService.userValue;
    this.insuranceCompanies = this.configDataService.PackageInsuranceCompanies.map(a => a.name);
    this.packageForm = this.formBuilder.group({
       insurancePolicyNumber: ['', Validators.required]
       ,insuranceCompany:['',Validators.required]
       ,insurancePolicyAmount:[{value:0,'disabled':true},[Validators.required,Validators.min(1)]]
       ,insurancePolicyGStAmount:[{value:0,'disabled':true},Validators.required]
       ,insurancePolicyTotalAmount:[{value:(this.selectPackage.dealerModelPrice && this.selectPackage.dealerModelPrice.insuranceAmount && this.selectPackage.dealerModelPrice.insuranceAmount > 0) ?this.selectPackage.dealerModelPrice.insuranceAmount: 0 },Validators.required]
       ,packagePolicyAmount:[{value:0,'disabled':true},Validators.required]
       ,packagePolicyGStAmount:[{value:0,'disabled':true},Validators.required]
       ,packagePolicyTotalAmount:[{value:this.selectPackage.packagePolicyTotalAmount,'disabled':true},Validators.required]
       ,financeCoverAmount:['']
       ,policyPdf:[]
       ,planId:['',Validators.required]
       ,planAmount: [{value:'','disabled':true}]
       , planGSTAmount: [{value:'','disabled':true}]
       , planTotalAmount: [{value:(this.selectPackage.dealerModelPrice && this.selectPackage.dealerModelPrice.allianceAmount && this.selectPackage.dealerModelPrice.allianceAmount > 0) ?this.selectPackage.dealerModelPrice.allianceAmount: 0,'disabled':true}, [Validators.required,
       (control: AbstractControl) => Validators.max(this.maxAmount)(control)
         , (control: AbstractControl) => Validators.min(this.minAmount)(control)]]
         , startDate: ['']
         , endDate: [{value:'','disabled':true}]
         , issueDate: [{value:'','disabled':true}]
         , balance: [{value:'','disabled':true}]
         ,completePackageUrl:['']
         , dealerBalance: ['']
         ,userNameProcessed:[this.user.username]
        });
        this.accountService.EmployeeList$.subscribe(a => this.employeeList = a)

        this.startDateModel =  {"year":2010, "month": 1,"day": 1};
        this.startDateModel.year = this.currentDate.getFullYear();
        this.startDateModel.day = this.currentDate.getDate();
        this.startDateModel.month = this.currentDate.getMonth() + 1;

        this.configDataService.getTodaysDate()
        .subscribe(x => {
          this.currentDate = new Date(x);
          this.startDateModel.year = this.selectPackage.startDate? new Date(this.selectPackage.startDate).getFullYear() : this.currentDate.getFullYear();
          this.startDateModel.day = this.selectPackage.startDate? new Date(this.selectPackage.startDate).getDate() : this.currentDate.getDate();
          this.startDateModel.month = this.selectPackage.startDate? new Date(this.selectPackage.startDate).getMonth() + 1 : this.currentDate.getMonth() + 1;
        this.dealerService.getByDealerId(this.selectPackage.dealerId).subscribe((x) => {
          this.dealer = x;
          this.plans = x.dealerPlan;
          this.packageForm.controls['balance'].setValue(this.dealer.dealerBalance);

          this.packageForm.controls['startDate'].setValue(this.startDateModel);
          this.packageForm.controls['issueDate'].setValue(new Date(this.selectPackage.startDate));
          if(this.selectPackage.type == "Old" && this.selectPackage.cpaCoverPlanName)
          {
          this.packageForm.controls['planId'].setValue(this.plans.filter(a=> a.planId == this.selectPackage.cpaCoverPlanName)[0].planId);
          }
          else
          {
            this.packageForm.controls['planId'].setValue(this.plans.filter(a=> a.planTotalAmount == 0)[0].planId);
            }
  

          // if(this.plans.find(a => a.planId == this.selectPackage.planId))
          // {
          //   this.packageForm.controls['planId'].setValue(this.selectPackage.planId);
          // }
          // else
          // {
          //   this.packageForm.controls['planId'].setValue(this.plans[0].planId);
          // }
        });
      });

        this.packageForm.get('planId').valueChanges
          .subscribe(value => {
            if (value != undefined) {
              this.selectedPlan = this.plans.find((x) => x.planId == value);
              //console.log(this.selectedPlan);
              this.packageForm.controls['planAmount'].setValue(this.selectedPlan.planAmount);
              this.packageForm.controls['planGSTAmount'].setValue(this.selectedPlan.planGstAmount);
              this.packageForm.controls['planTotalAmount'].setValue(this.selectedPlan.planAmount + this.selectedPlan.planGstAmount);
              if (this.selectedPlan.planAmount > 0){
              this.packageForm.controls['insurancePolicyTotalAmount'].setValue(this.selectPackage.packagePolicyTotalAmount - this.selectedPlan.planAmount - this.selectedPlan.planGstAmount);
              }
              this.selectedPlan.numberOfYears = this.selectedPlan.numberOfYears? this.selectedPlan.numberOfYears : 1;
              this.maxAmount = this.selectedPlan.planMaximumAmount;
              this.minAmount = this.selectedPlan.planMinimumAmount? this.selectedPlan.planMinimumAmount : this.selectedPlan.planNetAmount;
              this.packageForm.controls['planAmount'].updateValueAndValidity();
              // this.packageForm.controls['startDate'].setValue(this.selectPackage.startDate);
              this.endDate = new Date(this.startDate);
              this.endDate = new Date(this.endDate.setFullYear(this.endDate.getFullYear() + ((this.selectedPlan.numberOfYears && this.selectedPlan.numberOfYears > 0)?this.selectedPlan.numberOfYears:1)));
              this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() - 1));
              this.endDate = new Date(this.endDate.setHours(0, 0, 0, 0));
              this.packageForm.controls['endDate'].setValue(this.endDate);
              this.packageForm.controls['issueDate'].setValue(this.startDate);
              this.showGstAmount = this.selectedPlan.gstApplicable ? this.selectedPlan.gstApplicable =="N" ? false : true : true;
              this.showGstAmount = this.showGstAmount == false? false : this.selectedPlan.gstShow ? this.selectedPlan.gstShow =="N" ? false : true : true;
              // this.packageForm.controls['uploadFile'].reset();
              if (this.selectedPlan.policyType == "FINANCE")
              {
              this.configDataService.FinanceCompanyList$.subscribe((x) => {
                this.financeCompanies = x;
                this.showFinance = true;
                this.packageForm.get('financeCoverAmount').setValidators(Validators.required);
                this.selectedPlan.planCPACoverAmount = this.selectedPlan.planCPACoverAmount?this.selectedPlan.planCPACoverAmount:500000;
                this.packageForm.get('financeCoverAmount').setValidators(Validators.max(this.selectedPlan.planCPACoverAmount));

              });

              }
              else {
                this.showFinance = false;
                this.packageForm.get('financeCoverAmount').clearValidators();
              }
              this.packageForm.controls['financeCoverAmount'].updateValueAndValidity();
            }
            if (this.selectedPlan.planAmount == 0) {
              this.packageForm.controls['planTotalAmount'].enable();
          }
          else {
            this.packageForm.controls['planTotalAmount'].disable();
          }
          this.packageForm.controls['planTotalAmount'].updateValueAndValidity();

        });
        this.packageForm.get('planTotalAmount').valueChanges
        .subscribe(value => {
          if (value != undefined) {
            // if (this.selectedPlan.planId == "Flexible" || this.selectedPlan.planId == "Plan-CPA-DOC-AMEE" || this.selectedPlan.planId == "Plan-PAF") {
              if (this.selectedPlan.planAmount == 0) {
                this.planTotalAmount = parseInt(value);
              this.planAmount = parseInt((this.planTotalAmount / (1 + this.gstPercentage)).toFixed(2));
              this.planGSTAmount = parseInt((this.planTotalAmount - this.planAmount).toFixed(2));
              this.minAmount = this.selectedPlan.planMinimumAmount? this.selectedPlan.planMinimumAmount :1000;
              // this.planGSTAmount = this.gSTAmount.toFixed(2);
              // this.planTotalAmount = this.planAmount + parseInt(this.planGSTAmount);
              this.packageForm.controls['planGSTAmount'].setValue(this.planGSTAmount);
              this.packageForm.controls['planAmount'].setValue(this.planAmount);
              this.balanceValidator();
            }
          }
        });
        this.packageForm.get('startDate').valueChanges
        .subscribe(value =>{
          if (value != undefined){
            if(value.month != undefined && value.day != undefined && value.year != undefined){
                this.startDate = new Date(this.convertTextDate(value));
                if (parseInt(value.month) == (this.currentDate.getMonth() + 1) && parseInt(value.day) == this.currentDate.getDate() && parseInt(value.year) == this.currentDate.getFullYear())
                this.startDate = new Date(this.currentDate);
                else
                this.startDate = new Date(this.startDate.setHours(0, 0, 0, 0));
                this.endDate = new Date(this.startDate);
                this.endDate = new Date(this.endDate.setFullYear(this.endDate.getFullYear() + ((this.selectedPlan.numberOfYears && this.selectedPlan.numberOfYears > 0)?this.selectedPlan.numberOfYears:1)));
                this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() - 1));
                this.endDate = new Date(this.endDate.setHours(0, 0, 0, 0));
                this.packageForm.controls['endDate'].setValue(this.endDate);
                this.packageForm.controls['issueDate'].setValue(this.startDate);
                this.issueDate = this.startDate;
                          }
          }

        })
        this.packageForm.get('planAmount').valueChanges
        .subscribe(value =>{
          if (value){
            this.packageForm.controls['packagePolicyAmount'].setValue(parseFloat((value + this.packageForm.get('insurancePolicyAmount').value).toFixed(2)))
          }
        });
        this.packageForm.get('planGSTAmount').valueChanges
        .subscribe(value =>{
          if (value){
            this.packageForm.controls['packagePolicyGStAmount'].setValue(parseFloat((value + this.packageForm.get('insurancePolicyGStAmount').value).toFixed(2)))
          }
        });
        this.packageForm.get('insurancePolicyTotalAmount').valueChanges
        .subscribe(value =>{
          if (value){
            this.insurancePolicyTotalAmount = parseInt(value);
            this.insurancePolicyAmount = parseFloat((this.insurancePolicyTotalAmount / (1 + this.gstPercentage)).toFixed(2));
            this.packageForm.controls['insurancePolicyGStAmount'].setValue(parseFloat((this.insurancePolicyTotalAmount - this.insurancePolicyAmount).toFixed(2)))
            this.packageForm.controls['insurancePolicyAmount'].setValue(parseFloat(this.insurancePolicyAmount.toFixed(2)))
            if (this.selectPackage.dealerConfigFound && this.selectPackage.dealerModelPrice && this.selectPackage.dealerModelPrice.totalAmount && this.selectPackage.dealerModelPrice.totalAmount > 0)
            {
              if (this.selectPackage.type == "Old")
              {
                if (this.selectedPlan.planAmount == 0)
                {
                  this.packageForm.controls['planTotalAmount'].setValue(this.selectPackage.packagePolicyTotalAmount - this.insurancePolicyTotalAmount );
                }
              }
              else
              {
                this.packageForm.controls['planTotalAmount'].setValue(this.selectPackage.dealerModelPrice.totalAmount - this.insurancePolicyTotalAmount );
              }
            }
          }
        });
        this.packageForm.get('insurancePolicyAmount').valueChanges
        .subscribe(value =>{
          if (value){
            this.packageForm.controls['packagePolicyAmount'].setValue(value + this.packageForm.get('planAmount').value)
          }
        });
        this.packageForm.get('insurancePolicyGStAmount').valueChanges
        .subscribe(value =>{
          if (value){
            this.packageForm.controls['packagePolicyGStAmount'].setValue(value + this.packageForm.get('planGSTAmount').value)
          }
        });
        this.packageForm.get('packagePolicyAmount').valueChanges
        .subscribe(value =>{
          if (value){
            this.packageForm.controls['packagePolicyTotalAmount'].setValue(value + this.packageForm.get('packagePolicyGStAmount').value)
          }
        });
        this.packageForm.get('packagePolicyGStAmount').valueChanges
        .subscribe(value =>{
          if (value){
            this.packageForm.controls['packagePolicyTotalAmount'].setValue(value + this.packageForm.get('packagePolicyAmount').value)
          }
        });
      }

  get f() { return this.packageForm.controls; }

  onSubmit(){
    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();
    // stop here if form is invalid
    if (this.packageForm.invalid) {
      return;
    }
    this.packageService.printPackage = {
      ...this.packageService.printPackage,
      endDate:this.packageForm.get('endDate').value,
      startDate:this.packageForm.get('issueDate').value,
      issueDate:this.packageForm.get('issueDate').value,
      planId : this.packageForm.get('planId').value,
      planAmount : this.packageForm.get('planAmount').value,
      planGSTAmount : this.packageForm.get('planGSTAmount').value,
      planTotalAmount : this.packageForm.get('planTotalAmount').value,
      insuranceCompany : this.packageForm.get('insuranceCompany').value,
      insurancePolicyNumber : this.packageForm.get('insurancePolicyNumber').value,
      insurancePolicyAmount : this.packageForm.get('insurancePolicyAmount').value,
      insurancePolicyGStAmount : this.packageForm.get('insurancePolicyGStAmount').value,
      insurancePolicyTotalAmount : this.packageForm.get('insurancePolicyTotalAmount').value,
      financeCoverAmount : this.packageForm.get('financeCoverAmount').value,
      policyPdf : this.packageForm.get('policyPdf').value,
      packagePolicyAmount : this.packageForm.get('packagePolicyAmount').value,
      packagePolicyGStAmount : this.packageForm.get('packagePolicyGStAmount').value,
      packagePolicyTotalAmount : this.packageForm.get('packagePolicyTotalAmount').value,
      gstShow: this.selectedPlan.gstShow?this.selectedPlan.gstShow:"N",
      gstApplicable: this.selectedPlan.gstApplicable?this.selectedPlan.gstApplicable:"N",
      policyNumber: this.selectedPlan.masterPolicyNumber?this.selectedPlan.masterPolicyNumber:"",
      planCPACoverAmount : this.selectedPlan.planCPACoverAmount?this.selectedPlan.planCPACoverAmount : 1500000,
      printRsaNumber : this.selectedPlan.printRsaNumber? this.selectedPlan.printRsaNumber: "No",
      policyType: this.selectedPlan.policyType,
      dealerBalance: this.dealer.dealerBalance,
      userNameProcessed: this.packageForm.get('userNameProcessed').value,
      createdFromPackage: true
    }
    if (this.selectedPlan.policyType == "FINANCE")
    {
      this.packageService.printPackage.nomineeCoverAmount = this.packageService.printPackage.planCPACoverAmount - this.packageService.printPackage.financeCoverAmount;
    }
    else
    {
      this.packageService.printPackage.nomineeCoverAmount = this.packageService.printPackage.planCPACoverAmount ;

    }

    // console.log(JSON.stringify(this.packageService.printPackage));

    this.loading = true;
    this.packageService.generateCertificate()
    .pipe(first())
    .subscribe({
      next: (x) => {
        this.packageService.printPackage = new Package(x)
        this.certificateService.PrintCertificate = new Certificate(x);
        this.alertService.success('Certificate added successfully', { keepAfterRouteChange: true });
        this.loading = false;
        this.packageService.updatePackageInPrintCertificateState(this.packageService.printPackage);
        this.router.navigate(['../print'], { relativeTo: null });

      },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });


  }
  balanceValidator() {
    if (this.packageForm.get('planTotalAmount').value != undefined) {
      this.runningBalance = this.dealer.dealerBalance - parseInt(this.packageForm.get('planTotalAmount').value)
      // console.log("Dealer Balance" + this.runningBalance);

      if (this.runningBalance < this.dealer.dealerBalanceLimit) {
        this.isBalanceLow = true;
      }
      else
        this.isBalanceLow = false;
    }
  }

  convertTextDate(value: NgbDateStruct) {
    if (value) {
      this.dobString = value.year.toString();
      if (value.month < 10) {
        this.dobString = this.dobString + "-0" + value.month;
      }
      else {
        this.dobString = this.dobString + "-" + value.month;
      }
      if (value.day < 10) {
        this.dobString = this.dobString + "-0" + value.day;
      }
      else {
        this.dobString = this.dobString + "-" + value.day;
      }
    }
    return this.dobString;
  }
  onPolicyPdfSelected(event){
    this.selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('customFileName', this.packageService.printPackage.packageNumber +"-Inspolicy");
    this.fileServices.fileUpload(formData).subscribe(response => {
      this.packageService.printPackage.insPolicyPdfUrl = response.url;
    });
  }

}
