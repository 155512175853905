import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from '@app/_models';
import { Package } from '@app/_models/package';
import { ReportSearch } from '@app/_models/reportSearch';
import { AlertService, AccountService } from '@app/_services';
import { SearchService } from '@app/_services/search.service';
import { NgbDateStruct, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs';

@Component({
  selector: 'app-endorsement-report',
  standalone: true,
  imports: [CommonModule,AgGridModule,ReactiveFormsModule,NgbModule],
  templateUrl: './endorsement-report.component.html',
  styleUrl: './endorsement-report.component.less',
})
export class EndorsementReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  private gridApi;
  certificateReportForm: UntypedFormGroup;
  columnDefs = [
    // { headerName: 'Serial No', valueGetter: 'node.rowIndex + 1' },
    {
      headerName: 'Package Number',
      field: 'packageNumber',
          },
    {
      headerName: 'Certificate Id',
      field: 'certificateNumber',
          },
    {
      headerName: 'Dealer ID',
      field: 'dealerId',
    },
    {
      headerName: 'Dealer Name',
      field: 'dealerName',
          },
          {
            headerName: 'Status',
            field: 'packageStatus',
          },
          {
      headerName: 'Request Count',
      field: 'numberOfRequests',
    },
    {
      headerName: 'Requested Date',
      field: 'requestedDate',
    },
    {
      headerName: 'Request Type',
      field: 'requestType',
    },
    {
      headerName: 'Comment',
      field: 'comment',
    },
    {
      headerName: 'Requested By',
      field: 'requestedBy',
    },
    // {
    //   headerName: 'Notification Email',
    //   field: 'notificationEmail',
    //   sortable: true,
    //   filter: true,
    // },
  ];
  submitted = false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  gridOptions: GridOptions = {
    columnDefs: null,
    rowData: null,
    groupDisplayType: 'groupRows',
    animateRows: true,
    defaultColDef: {
        flex: 1,
        minWidth: 150,
        sortable: true,
        filter: true
    }
};;
  loading = false;
  gridReady = false;
  reportSearch: ReportSearch;
  issueDateFromSearch: string;
  issueDateToSearch: string;
  reportCertificateData: Package[];
  user: User;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private alertService: AlertService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.user = this.accountService.userValue;
    if (this.user.role == 'admin') {
      this.gridOptions = <GridOptions>{};
      this.certificateReportForm = this.formBuilder.group({
        certificateNumber: [''],
        policyNumber: [''],
        issueDateFrom: [''],
        issueDateTo: [''],
        dealerId: [''],
      });
      this.certificateReportForm.get('issueDateFrom').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.issueDateFromSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
        }
      });
  
      this.certificateReportForm.get('issueDateTo').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.issueDateToSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
        }
      });
    
    } else {
      this.alertService.error('You are not authorized to access this page');
    }
  }

  get f() {
    return this.certificateReportForm.controls;
  }
  onSubmit(){
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    this.reportSearch = new ReportSearch();
    this.reportSearch.userId = this.user.id;
    
    if (this.issueDateFromSearch == undefined)
    {
      this.reportSearch.issueDateFrom = "2001-01-01";
    }
    else
    this.reportSearch.issueDateFrom = this.issueDateFromSearch;

    if (this.issueDateToSearch == undefined)
    {
      this.reportSearch.issueDateTo = "2999-01-01";
    }
    else
    this.reportSearch.issueDateTo = this.issueDateToSearch;
    this.searchService.getEndorsementPackageReport(this.reportSearch)
    .pipe(first())
    .subscribe({
      next: (x) => {
        this.reportCertificateData = x;
        this.gridReady = false;
        // this.gridOptions.rowData = this.transformData(x);
        // console.log(JSON.stringify(this.gridOptions.rowData));
        this.aggrid.api.updateGridOptions({ columnDefs: this.columnDefs,rowData: this.transformData(x)})
        this.aggrid.api.sizeColumnsToFit();
  
        // this.aggrid.api.updateGridOptions({ rowData: this.transformData(x) })
        // this.aggrid.api.setRowData(this.gridOptions.rowData); // Add the missing second argument
        this.gridReady = true;
        this.loading = false;
      },
      error: error => {
        this.alertService.error(error)
        this.loading = false;
      }
    })      
  return;
}
onReset(){

}
exportToExcel(){
  const params = {
    columnGroups: true,
    allColumns: true,
    fileName: 'excelRsaReport',
//     columnSeparator: document.querySelector("#columnSeparator").value
  };
  this.aggrid.api.exportDataAsCsv(params);
  // this.aggrid.api.exportDataAsExcel(params);
}
transformData(data:Package[]) {
  const transformedData = [];
  data.forEach(row => {
    row.requestHistory.forEach(req => {
      transformedData.push({
        packageNumber: row.packageNumber,
        certificateNumber: row.certificateNumber,
        dealerId: row.dealerId,
        dealerName: row.dealerName,
        packageStatus: row.packageStatus,
        numberOfRequests: row.numberOfRequests,
        endorsementCount: row.endorsementCount,
        requestedDate: req.requestedDate,
        requestType: req.requestType,
        comment: req.comment,
        requestedBy: req.requestedBy
});
    });
  });
  return transformedData;
}

}

