import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { SearchService } from '@app/_services/search.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-total-balance-report',
  templateUrl: './total-balance-report.component.html',
  styleUrls: ['./total-balance-report.component.less']
})
export class TotalBalanceReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  certificateReportForm: UntypedFormGroup;
  submitted=false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  gridOptions: GridOptions;
  loading= false;
  isAdjustedBalancePositive = false;
  gridReady = true;
  reportSearch: ReportSearch;
  issueDateFromSearch : string;
  issueDateToSearch : string;
  reportCertificateData : any[];
  finalReportData: any[];
  currentDate = new Date();
  reportCreated = false;
  dealer : Dealer;
  totalCredits = 0;
  totalDebits = 0;
  yOld: any;
  minDobDateModel: NgbDateStruct = {year: 2024, month: 3, day: 4};
  insuranceCompanies = this.configService.PackageInsuranceCompanies;



  user: User;
  totalCreditsCertificate: number;
  totalCreditsPolicy: number;
  totalDebitsCertificate: number;
  totalDebitsPolicy: number;
  reportTransactionRecord: any[];
  reportData: any[];
  insuranceCompanyReport: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService, 
    private alertService: AlertService,
    private configService: ConfigDataService,
    private accountService: AccountService,
    private dealerService: DealerService

  ) { }

  ngOnInit(): void {
    this.user = this.accountService.userValue;
    this.gridOptions = <GridOptions>{}; 
    this.certificateReportForm = this.formBuilder.group({
      issueDateFrom: [''],
      issueDateTo: [''],
      dealerId: [''],
      balance: [''],
      adjustedBalance: [''],
      openingBalance:[''],
      insuranceCompany: [''],
          });
    
    if (this.user.role == "admin") {
            this.certificateReportForm.get('dealerId').setValidators(Validators.required);
          this.certificateReportForm.controls['dealerId'].updateValueAndValidity();
        }
    
    
    this.gridOptions.defaultColDef = {
          resizable: true,      
        };
    if (this.user.specialRoleAccess.includes("Show Total Package Balance"))  
        this.gridOptions.columnDefs = [
                {headerName: "Serial No", valueGetter: "node.rowIndex + 1"}
                ,{headerName: "Transaction Date", field: "transactionDate",sortable: true, filter:true }
                ,{headerName: "Type", field: "transactionType",sortable: true, filter:true }
                ,{headerName: "Description", field: "comments",sortable: true, filter:true }
                // ,{headerName: "Credited Certificate ", field: "creditCertificate",sortable: true, filter:true }
                // ,{headerName: "Debited Certificate ", field: "debitCertificate",sortable: true, filter:true }
                // ,{headerName: "Certificate Balance", field: "dealerBalanceCertificate",sortable: true, filter:true }
                // ,{headerName: "Credited Policy ", field: "creditPolicy",sortable: true, filter:true }
                // ,{headerName: "Debited Policy ", field: "debitPolicy",sortable: true, filter:true }
                // ,{headerName: "Policy Balance", field: "dealerBalancePolicy",sortable: true, filter:true }
                ,{headerName: "Package Credit", valueGetter: (params)=>(parseInt(params.data.creditCertificate) + parseInt(params.data.creditPolicy)).toFixed(2),sortable: true, filter:true }
                ,{headerName: "Package Debit", valueGetter: (params)=>(parseInt(params.data.debitCertificate) + parseInt(params.data.debitPolicy)).toFixed(2),sortable: true, filter:true }
                ,{headerName: "Total Balance", valueGetter: (params)=>(params.data.dealerBalanceCertificate =="NA"? 0 : parseInt(params.data.dealerBalanceCertificate) + parseInt(params.data.dealerBalancePolicy)).toFixed(2),sortable: true, filter:true }
              ]
      else
      this.gridOptions.columnDefs = [
        {headerName: "Serial No", valueGetter: "node.rowIndex + 1"}
        ,{headerName: "Transaction Date", field: "transactionDate",sortable: true, filter:true }
        ,{headerName: "Type", field: "transactionType",sortable: true, filter:true }
        ,{headerName: "Description", field: "comments",sortable: true, filter:true }
        ,{headerName: "Credited Certificate ", field: "creditCertificate",sortable: true, filter:true }
        ,{headerName: "Debited Certificate ", field: "debitCertificate",sortable: true, filter:true }
        ,{headerName: "Certificate Balance", field: "dealerBalanceCertificate",sortable: true, filter:true }
        ,{headerName: "Credited Policy ", field: "creditPolicy",sortable: true, filter:true }
        ,{headerName: "Debited Policy ", field: "debitPolicy",sortable: true, filter:true }
        ,{headerName: "Policy Balance", field: "dealerBalancePolicy",sortable: true, filter:true }
        ,{headerName: "Package Credit", valueGetter: (params)=>(parseInt(params.data.creditCertificate) + parseInt(params.data.creditPolicy)).toFixed(2),sortable: true, filter:true }
        ,{headerName: "Package Debit", valueGetter: (params)=>(parseInt(params.data.debitCertificate) + parseInt(params.data.debitPolicy)).toFixed(2),sortable: true, filter:true }
        ,{headerName: "Total Balance", valueGetter: (params)=>(params.data.dealerBalanceCertificate =="NA"? 0 : parseInt(params.data.dealerBalanceCertificate) + parseInt(params.data.dealerBalancePolicy)).toFixed(2),sortable: true, filter:true }
      ]

          this.gridOptions.rowData = [];
          this.gridReady = true;
      
          this.certificateReportForm.get('issueDateFrom').valueChanges
          .subscribe(value => {
            if (value != undefined) {
              this.issueDateFromSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
            }
          });
      
          this.certificateReportForm.get('issueDateTo').valueChanges
          .subscribe(value => {
            if (value != undefined) {
              this.issueDateToSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
            }
            //console.log("issueDateToSearch: " + this.issueDateToSearch)
          });
      
    
        }
            // convenience getter for easy access to form fields
            get f() { return this.certificateReportForm.controls; }

            onSubmit(){
              this.submitted = true;
              this.loading = true;
              this.reportCreated = false;
              if (this.certificateReportForm.invalid) {
                this.loading = false;
                return;
              }
              
              this.alertService.clear();
              // if (this.user.role !="admin" && this.user.role !="dealerOwner" && !(this.user.specialRoleAccess && this.user.specialRoleAccess.includes('CanViewDealerBalanceReport')))
              // {
              // this.alertService.error("Unauthorized access");
              // this.submitted = false;
              // this.loading = false;
              // return;              
              // }
              this.reportSearch = new ReportSearch();
              if (this.issueDateFromSearch == undefined)
              {
                this.reportSearch.issueDateFrom = "0001-01-01";
                this.issueDateFromSearch = this.currentDate.getFullYear() + "-" + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.currentDate.getDate()).slice(-2);; 
              }
              else
              this.reportSearch.issueDateFrom = this.issueDateFromSearch;
          
              if (this.issueDateToSearch == undefined)
              {
                this.reportSearch.issueDateTo = "9999-99-99";
              }
              else
              this.reportSearch.issueDateTo = this.issueDateToSearch;
              this.reportSearch.dealerId = this.certificateReportForm.get('dealerId').value;
              this.totalCredits = 0;
              this.totalDebits = 0;
              this.totalCreditsCertificate = 0;
              this.totalCreditsPolicy = 0;
              this.totalDebitsCertificate = 0;
              this.totalDebitsPolicy = 0;
              this.searchService.getDealerTotalBalanceReport(this.reportSearch)
              .pipe(first())
              .subscribe({
                next: (reportData) => {
                  this.reportData = reportData;
                  this.reportTransactionRecord =   reportData.filter(x => x.dealerBalanceCertificate != "NA")
                  if (this.reportTransactionRecord.length == 0)
                  {
                    if (this.user.role == "dealerOwner" || this.user.role == "executive" || this.user.role == "manager")
                    {
                      this.reportSearch.dealerId = this.user.dealerId;
                    }
                    this.dealerService.getByDealerId(this.reportSearch.dealerId)
                    .pipe(first())
                    .subscribe((a)=>{
                      this.dealer = a;
                      this.processReportData(reportData)
                    })
                  }
                  else
                  this.processReportData(reportData)
                },
                error: error => {
                  this.alertService.error(error)
                  this.loading = false;
                }
              })  
                  
            }  
            onReset(){
              this.reportCreated = false;
              this.submitted = false;
              this.loading = false;

            }
            exportToExcel(){
              const params = {
                columnGroups: true,
                allColumns: true,
                fileName: 'excelReport',
           //     columnSeparator: document.querySelector("#columnSeparator").value
              };
              this.aggrid.api.exportDataAsCsv(params);
              // this.aggrid.api.exportDataAsExcel(params);
            }
                 
processReportData(reportData: any[]){
  if (this.f.insuranceCompany.value != ""  && this.f.insuranceCompany.value != "Name not Found")
  {
    this.insuranceCompanyReport = true;
        reportData = reportData.map(a => {
          if (![this.f.insuranceCompany.value].includes(a.insuranceCompanyCode))
          {
            a.creditPolicy = 0;
            a.debitPolicy = 0;
            a.dealerPolicyBalance = a.insuranceBalances[this.f.insuranceCompany.value]?a.insuranceBalances[this.f.insuranceCompany.value]:0;
          }
          return a;
        }).filter(x => x.creditPolicy + x.debitPolicy + x.creditCertificate + x.debitCertificate > 0)
  }
  else
  {
    this.insuranceCompanyReport = false;
  }
  this.reportCertificateData = reportData.sort((a,b) => {
    if (a.transactionDate > b.transactionDate)
      return 1;
    if (a.transactionDate < b.transactionDate)
      return -1;
    else
      return 0;                    
  }
    ).map(
      y => {
        console.log(JSON.stringify(y));
        y.insuranceBalances = y.insuranceBalances?y.insuranceBalances:{};
        y.insuranceBalances[this.f.insuranceCompany.value] = y.insuranceBalances[this.f.insuranceCompany.value]?y.insuranceBalances[this.f.insuranceCompany.value]:0;
        this.totalCreditsCertificate = this.totalCreditsCertificate + y.creditCertificate;
        this.totalCreditsPolicy = this.totalCreditsPolicy +  y.creditPolicy;
        this.totalDebitsCertificate = this.totalDebitsCertificate + y.debitCertificate;
        this.totalDebitsPolicy += y.debitPolicy;
        this.totalCredits = this.totalCredits + y.creditCertificate + y.creditPolicy;
        this.totalDebits = this.totalDebits + y.debitCertificate +y.debitPolicy;
        if(this.insuranceCompanyReport)
        {
          y.dealerBalancePolicy = y.insuranceBalances[this.f.insuranceCompany.value] == 0 ? this.yOld && this.yOld.insuranceBalances[this.f.insuranceCompany.value]?this.yOld.insuranceBalances[this.f.insuranceCompany.value]:0: y.insuranceBalances[this.f.insuranceCompany.value];

        }
        else {
        y.dealerBalancePolicy =  y.dealerBalancePolicy == 0 ? this.yOld && this.yOld.dealerBalancePolicy?this.yOld.dealerBalancePolicy:0: y.dealerBalancePolicy;
        }
        y.dealerBalanceCertificate = y.dealerBalanceCertificate == 0 ? this.yOld && this.yOld.dealerBalanceCertificate?this.yOld.dealerBalanceCertificate:0: y.dealerBalanceCertificate;
        this.yOld = y;
        return y;
      }
    );
      console.log(JSON.stringify(this.reportCertificateData));
  var reportTransactionRecord =   this.reportCertificateData.filter(x => x.dealerBalanceCertificate != "NA")
  var totalCreditCertificate = 0;
  var totalCreditPolicy = 0;
  var totalCredit = 0;
  for (let index=0; this.reportCertificateData[index] && this.reportCertificateData[index].dealerBalanceCertificate == "NA"; index++) {
    totalCredit += this.reportCertificateData[index].creditCertificate + this.reportCertificateData[index].creditPolicy - this.reportCertificateData[index].debitCertificate - this.reportCertificateData[index].debitPolicy;
    totalCreditCertificate += this.reportCertificateData[index].creditCertificate  - this.reportCertificateData[index].debitCertificate;    
    totalCreditPolicy += this.reportCertificateData[index].creditPolicy  - this.reportCertificateData[index].debitPolicy;    
  }
  var openingBalance:number = reportTransactionRecord.length > 0?(parseInt(reportTransactionRecord[0].dealerBalancePolicy) + parseInt(reportTransactionRecord[0].dealerBalanceCertificate) - parseInt(reportTransactionRecord[0].creditCertificate) - parseInt(reportTransactionRecord[0].creditPolicy) - totalCredit + parseInt(reportTransactionRecord[0].debitPolicy) + parseInt(reportTransactionRecord[0].debitCertificate)):this.dealer?this.dealer.dealerPolicyBalance?this.dealer.dealerPolicyBalance + this.dealer.dealerBalance:this.dealer.dealerBalance:0;
  var openingBalanceCertificate:number = reportTransactionRecord.length > 0?(parseInt(reportTransactionRecord[0].dealerBalanceCertificate) - parseInt(reportTransactionRecord[0].creditCertificate)  - totalCreditCertificate +  parseInt(reportTransactionRecord[0].debitCertificate)):this.dealer?this.dealer.dealerBalance:0;
  var openingBalancePolicy:number = reportTransactionRecord.length > 0?(parseInt(reportTransactionRecord[0].dealerBalancePolicy) - parseInt(reportTransactionRecord[0].creditPolicy) - totalCreditPolicy + parseInt(reportTransactionRecord[0].debitPolicy)):this.dealer?this.dealer.dealerPolicyBalance?this.dealer.dealerPolicyBalance :0:0;
  
  var adjustedBalance = this.totalCredits - this.totalDebits + openingBalance;
  this.reportCertificateData.push({
    "comments": "Total Credits and Debits :"
    ,"creditCertificate": (this.totalCreditsCertificate).toFixed(2)
    ,"debitCertificate": (this.totalDebitsCertificate).toFixed(2)
    ,"creditPolicy": (this.totalCreditsPolicy).toFixed(2)
    ,"debitPolicy": (this.totalDebitsPolicy).toFixed(2)
    ,"dealerBalanceCertificate":(this.totalCreditsCertificate - this.totalDebitsCertificate + openingBalanceCertificate).toFixed(2)
    ,"dealerBalancePolicy":(this.totalCreditsPolicy - this.totalDebitsPolicy + openingBalancePolicy).toFixed(2)
  });
  // this.reportCertificateData.push({
  //   "comments": "Balance (Credit - Debit) :"
  //   ,"credit": (this.totalCredits - this.totalDebits).toFixed(2)
  // });
  this.finalReportData = [];
  this.finalReportData[0] = {
    "comments": "Opening Balance :"
    ,"creditCertificate": 0
    ,"creditPolicy": 0
    ,"debitCertificate": 0
    ,"debitPolicy": 0
    ,"dealerBalanceCertificate":(openingBalanceCertificate).toFixed(2)
    ,"dealerBalancePolicy":(openingBalancePolicy).toFixed(2)
  };
  this.reportCertificateData.map(a => this.finalReportData.push(a));
  this.aggrid.api.setRowData(this.finalReportData);
  this.certificateReportForm.controls['balance'].setValue((this.totalCredits - this.totalDebits).toFixed(2));
  this.certificateReportForm.controls['adjustedBalance'].setValue((adjustedBalance).toFixed(2));
  this.certificateReportForm.controls['openingBalance'].setValue((openingBalance).toFixed(2));
  if (adjustedBalance >= 0)
      this.isAdjustedBalancePositive = true;
  else 
  this.isAdjustedBalancePositive = false;
  this.gridReady = true;
  this.loading = false;
  this.reportCreated = true;

}
  }
