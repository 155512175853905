<div class="container" style="max-height: 80vh;overflow: auto;">
    <app-view-package></app-view-package>
</div>
<form [formGroup]="packageForm" (ngSubmit)="onSubmit()">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
                <div class="breadcrumb-content">
                    <i class="fa fa-book breadcrumb-icon" ></i>
            <p class="h5  breadcrumb-text"> Insurance Policy Details</p>
        </div></li>
        </ol>
      </nav>
      <div class="form-row">
        <div class="form-group col">
            <label for="insuranceCompany">Insurance Company</label>
            <select class="form-select" formControlName="insuranceCompany" [ngClass]="{ 'is-invalid': submitted && f.insuranceCompany.errors }" >
                <option *ngFor="let insuranceCo of insuranceCompanies " [ngValue]="insuranceCo">
                  {{ insuranceCo }}
                </option>
              </select>
            <div *ngIf="submitted && f.insuranceCompany.errors" class="invalid-feedback">
                <div *ngIf="f.insuranceCompany.errors.required">Insurance Company is required</div>
            </div>
        </div>

        <div class="form-group col">
        <label for="insurancePolicyNumber">Policy Number</label>
        <input type="text" formControlName="insurancePolicyNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.insurancePolicyNumber.errors }" />
        <div *ngIf="submitted && f.insurancePolicyNumber.errors" class="invalid-feedback">
            <div *ngIf="f.insurancePolicyNumber.errors">Policy Number is required</div>
        </div>
   </div>
   <div class="form-group col">
    <label for="policyPdf">Policy PDF file</label>
    <input type="file"  (change)="onPolicyPdfSelected($event)"  formControlName="policyPdf" accept="application/pdf" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.policyPdf.errors }" />
    <div *ngIf="submitted && f.policyPdf.errors" class="invalid-feedback">
        <div *ngIf="f.policyPdf.errors.required">Policy PDF File is required</div>
    </div>
</div>
</div>
<div class="form-row">
    <div class="form-group col">
    <label for="insurancePolicyAmount">Policy Amount</label>
    <input type="number" formControlName="insurancePolicyAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.insurancePolicyAmount.errors }" />
    <div *ngIf="submitted && f.insurancePolicyAmount.errors" class="invalid-feedback">
        <div *ngIf="f.insurancePolicyAmount.errors">Policy Amount is required</div>
    </div>
</div>
<div class="form-group col">
    <label for="insurancePolicyGStAmount">Policy GST Amount</label>
    <input type="number" formControlName="insurancePolicyGStAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.insurancePolicyGStAmount.errors }" />
    <div *ngIf="submitted && f.insurancePolicyGStAmount.errors" class="invalid-feedback">
        <div *ngIf="f.insurancePolicyGStAmount.errors">Policy GST Amount is required</div>
    </div>
</div>
<div class="form-group col">
    <label for="insurancePolicyTotalAmount">Policy Total Amount</label>
    <input type="number" formControlName="insurancePolicyTotalAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.insurancePolicyTotalAmount.errors }" />
    <div *ngIf="submitted && f.insurancePolicyTotalAmount.errors" class="invalid-feedback">
        <div *ngIf="f.insurancePolicyTotalAmount.errors">Policy Total Amount is required</div>
    </div>
</div>
</div>

<nav aria-label="breadcrumb" >
        <ol class="breadcrumb" >
            <li class="breadcrumb-item active" aria-current="page" >
                <div class="breadcrumb-content">
                <i class="fa fa-book breadcrumb-icon" ></i>
            <p class="h5  breadcrumb-text"> Plan Details</p>
        </div>
    </li>
        </ol>
      </nav>
      <div class="form-row">
        <div class="form-group col">
            <label for="planId">Plan</label>
            <select class="form-select" formControlName="planId" [ngClass]="{ 'is-invalid': submitted && f.planId.errors }" >
                <option *ngFor="let plan of plans " [ngValue]="plan.planId">
                  {{ plan.planId }}
                </option>
              </select>
            <div *ngIf="submitted && f.planId.errors" class="invalid-feedback">
                <div *ngIf="f.planId.errors.required">Plan is required</div>
            </div>
        </div>
        <div class="form-group col" *ngIf="showFinance">
            <label for="financeCoverAmount">Finance Cover Amount</label>
            <input type="number" formControlName="financeCoverAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.financeCoverAmount.errors }" />
            <div *ngIf="submitted && f.financeCoverAmount.errors" class="invalid-feedback">
                <div *ngIf="f.financeCoverAmount.errors">Finance Cover amount is required and cant be more than cover limit of {{selectedPlan.planCPACoverAmount}}</div>
            </div>
       </div>
        <div class="form-group col" *ngIf="showGstAmount">
            <label for="planAmount">Plan Amount</label>
            <input type="number" formControlName="planAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planAmount.errors }" />
        </div>
        <div class="form-group col" *ngIf="showGstAmount">
            <label for="planGSTAmount">GST Amount</label>
            <input type="number" formControlName="planGSTAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planGSTAmount.errors }" />
        </div>
        <div class="form-group col">
            <label for="planTotalAmount">Total Amount</label>
            <input type="number" formControlName="planTotalAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planTotalAmount.errors }" />
            <div *ngIf="submitted && f.planTotalAmount.errors" class="invalid-feedback">
                <div *ngIf="f.planTotalAmount.errors">Approved amount range is from {{minAmount}} to {{maxAmount}}</div>
            </div>
        </div>
    </div>

    <div class="form-row">

        <div class="form-group col">
            <label for="startDate">Start Date</label>
            <div class="input-group">
                <input class="form-control" formControlName="startDate"  placeholder="DD/MM/YYYY" [minDate]="startDateModel" ngbDatepicker #d1="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                <div *ngIf="f.startDate.errors.required">Start Date is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="endDate">End Date</label>
            <input type="text" formControlName="endDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }" />
        </div>
        <div class="form-group col">
            <label for="issueDate">Issue Date</label>
            <input type="text" formControlName="issueDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.issueDate.errors }" />
        </div>
        <div class="form-group col" >
            <label for="balance">Balance</label>
            <input type="text" formControlName="balance" class="form-control" [ngClass]="{ 'is-invalid': isBalanceLow }" />
            <div *ngIf="isBalanceLow">Balance is running low. Please add money before proceeding</div>
        </div>
    </div>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page" >
                <div class="breadcrumb-content">
                <i class="fa fa-book breadcrumb-icon" ></i>
            <p class="h5  breadcrumb-text"> Package Financial Summary</p>
        </div></li>
        </ol>
      </nav>
      <div class="form-row">

      <div class="form-group col" >
        <label for="packagePolicyAmount">Package Amount</label>
        <input type="number" formControlName="packagePolicyAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.packagePolicyAmount.errors }" />
    </div>
    <div class="form-group col" >
        <label for="packagePolicyGStAmount">Package GST Amount</label>
        <input type="number" formControlName="packagePolicyGStAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.packagePolicyGStAmount.errors }" />
    </div>
    <div class="form-group col">
        <label for="packagePolicyTotalAmount">Package Total Amount</label>
        <input type="number" formControlName="packagePolicyTotalAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.packagePolicyTotalAmount.errors }" />
    </div>
</div>
<div class="row">
    <div class="col">
        <h5> Ideal Package Amount: </h5>
    </div>
    <div class="col">

    </div>
    <div class="col" style="text-align: start; ">
        <h5> {{selectPackage.packagePolicyTotalAmount}}</h5>
    </div>
</div>
<nav aria-label="breadcrumb" >
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page" >
            <div class="breadcrumb-content">
            <i class="fa fa-book breadcrumb-icon" ></i>
        <p class="h5  breadcrumb-text"> Processing User Details</p>
    </div></li>
    </ol>
  </nav>
  <div class="form-row">

  <div class="form-group col" >
    <label for="packagePolicyAmount">Entered By</label>
    <select class="form-select" formControlName="userNameProcessed" [ngClass]="{ 'is-invalid': submitted && f.userNameProcessed.errors }" >
        <option *ngFor="let employee of employeeList " [ngValue]="employee.username">
          {{ employee.firstName + " " + employee.lastName }}
        </option>
      </select></div>
<div class="form-group col" >
</div>
<div class="form-group col">
</div>
</div>

<div class="form-group">
    <button [disabled]="loading || isBalanceLow" class="btn btn-primary" >
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Generate Certificate
    </button><a>&nbsp;&nbsp;</a>
</div>

</form>