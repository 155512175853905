export class Payments {
    id:string;
    userId:string;
    dealerId:string;
    userName: string;
    paymentAmount:number;
    comments:string;
    paymentDate:string;
    dealerBalanceAfter:number;
    dealerName?:string;
    dealerPolicyBalanceAfter?:number = 0;
    insuranceCompany?:string;
    insuranceBalances?:Map<String,Number>;
    public constructor(init?: Partial<Payments>) {
        Object.assign(this, init);
}
}