import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IssueCertLayoutComponent } from './issue-cert-layout/issue-cert-layout.component';
import { AddCertificateComponent } from './add-certificate/add-certificate.component';
import { ProcessPackageComponent } from './process-package/process-package.component';
import { ConfirmPackageComponent } from './confirm-package/confirm-package.component';
import { ViewPackageComponent } from './view-package/view-package.component';

const issueCertRoutes: Routes = [
    {
        path: '', component: IssueCertLayoutComponent,
        children: [
            { path: '', component: AddCertificateComponent },
            { path: 'renewCertificate', component: AddCertificateComponent },
            { path: 'addCertificate', component: AddCertificateComponent },
            { path: 'select-package', component: AddCertificateComponent },
            { path: 'processPackage', component: ProcessPackageComponent },
            { path: 'viewPackage', component: ViewPackageComponent },
            { path: 'confirmPackage', component: ConfirmPackageComponent },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(issueCertRoutes)],
    exports: [RouterModule]
})
export class issueCertRouting { }