<nav aria-label="breadcrumb" (click)="userShowToggle()" *ngIf="user.role != 'manager'">
    <ol class="breadcrumb" >
      <li class="breadcrumb-item active" aria-current="page">
        <div class="breadcrumb-content">
        <i class="fa fa-plus breadcrumb-icon"  *ngIf="!isUserShow"></i>
        <i class="fa fa-minus breadcrumb-icon"  *ngIf="isUserShow"></i>
        <p class="h5 breadcrumb-text"> User Details</p>
    </div></li>
    </ol>
  </nav>
 
<div *ngIf = "isUserShow">
<a routerLink="add" class="btn btn-sm btn-success mb-2">Add User</a> <a>&nbsp;&nbsp;</a>
<table class="table table-striped">
    <thead>
        <tr>
            <th style="width: 30%">First Name</th>
            <th style="width: 30%">Last Name</th>
            <th style="width: 30%">Username</th>
            <th style="width: 30%">Role</th>
            <th style="width: 10%"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of users">
            <td>{{user.firstName}}</td>
            <td>{{user.lastName}}</td>
            <td>{{user.username}}</td>
            <td>{{user.role}}</td>
            <td style="white-space: nowrap">
                <a routerLink="edit/{{user.id}}" class="btn btn-sm btn-primary mr-1">Edit</a>
                <button (click)="deleteUser(user.id)" class="btn btn-sm btn-danger btn-delete-user" [disabled]="user.isDeleting">
                    <span *ngIf="user.isDeleting" class="spinner-border spinner-border-sm"></span>
                    <span *ngIf="!user.isDeleting">Delete</span>
                </button>
            </td>
        </tr>
        <tr *ngIf="!users">
            <td colspan="4" class="text-center">
                <span class="spinner-border spinner-border-lg align-center"></span>
            </td>
        </tr>
    </tbody>
</table>
</div>
