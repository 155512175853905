<div class="p-4">
    <iframe *ngIf="!getloading()" id="myFrame" [src]="pdfSrc"  style="width: 80%; height: 80vh;">
    </iframe>
    <div *ngIf="getloading()" class="spinner-border text-info" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    <form [formGroup]="packageForm">
        <div class = "row">
        <div class="form-check form-switch">
            <input class="form-check-input" formControlName="acknowledge" type="checkbox" id="flexSwitchCheckDefault">
            <label class="form-check-label" for="flexSwitchCheckDefault">I Acknowledge that pdf is ready to be shared with client and it contains both insurance and certificates PDF's</label>
          </div>  
        </div>
        <div class = "row" style="margin-top: 10px;" *ngIf="!acknowledgeInd">
            <div class="form-group col">
                <label for="mergePdf">Upload Revised Merged PDF File</label>
                <input type="file"  (change)="onPolicyPdfSelected($event)"  formControlName="mergePdf" accept="application/pdf" class="form-control" />
            </div>
            <div class="form-group col">
                </div>
            </div>
        </form>
    <button [disabled]="getloading()" *ngIf="!acknowledgeInd" (click)="downloadCertificate()" class="btn btn-sm btn-success mb-2">Download Certificate
        <span *ngIf="getloading()" class="spinner-border spinner-border-sm mr-1"></span>
    </button ><a>&nbsp;&nbsp;</a>
    <button [disabled]="getloading() || !acknowledgeInd"  (click)="sharePackage()" class="btn btn-sm btn-success mb-2">Share Package
        <span *ngIf="getloading()" class="spinner-border spinner-border-sm mr-1"></span>
    </button ><a>&nbsp;&nbsp;</a>        
    <div class="spinner-grow text-info" role="status" *ngIf="isLoading">
        <span class="sr-only">Loading...</span>
      </div>
</div>
