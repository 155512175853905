export class Invoice {
    invoiceNumber: String;
    invoiceStatus: String;
    invoiceYear: number;
    invoiceMonth: String;
    invoiceGeneratedDate: string;
    invoicePaidDate: String;
    dealerLastBalance: string;
    dealerTotalPayment: string;
    dealerTotalGstPayment: string;
    dealerTotalGstRefund: string;
    dealerTotalRefund:string;
    dealerGstPendingRefund:string;
    dealerPendingRefund:string;
    invoiceType?: String;

    public constructor(init?: Partial<Invoice>) {
        Object.assign(this, init);
}
}