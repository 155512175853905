import { Component } from '@angular/core';

import { AccountService } from './_services';
import { User } from './_models';
import { Dealer } from './_models/dealer';
import { DealerService } from './_services/dealer-service';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    user: User;
    dealer:Dealer;
    showReceivableTab = false;
    // showTermsCondition = false;
    // showPrivatePolicy = false;
    showCancellationPolicy = false;
    // showContactUs = false;

    constructor(private accountService: AccountService,private dealerService: DealerService) {
        this.accountService.getLoggedInUserChange.subscribe((x) => { this.ngOnInit()});
    }
    ngOnInit(){
         
        this.user = this.accountService.userValue;  
        if (this.user)  {
        console.log('role: ' + this.user.role)
            if (this.user.role != "admin" && this.user.role != "employee"){
            this.dealerService.getByDealerId(this.user.dealerId).subscribe((y) => 
            {
                this.dealer = y; 
                console.log(this.dealer.dealerInvoiceShow);
            this.showReceivableTab = this.dealer.dealerInvoiceShow ? this.dealer.dealerInvoiceShow == "No"? false: true : true})
        }
        else
        {
         this.showReceivableTab = false;
    };}
    }
    // termsConditionToggle(){
    //     this.showTermsCondition = !this.showTermsCondition;
    //     this.showPrivatePolicy = false;
    //     this.showCancellationPolicy = false;
    //     this.showContactUs = false;
    // }
    // privatePolicyToggle(){
    //     this.showTermsCondition = false;
    //     this.showPrivatePolicy = !this.showPrivatePolicy;
    //     this.showCancellationPolicy = false;
    //     this.showContactUs = false;
    // }
    cancellationPolicyToggle(){
        // this.showTermsCondition = false;
        // this.showPrivatePolicy = false;
        this.showCancellationPolicy = !this.showCancellationPolicy;
        // this.showContactUs = false;
    }
    contactUsToggle(){
        // this.showTermsCondition = false;
        // this.showPrivatePolicy = false;
        this.showCancellationPolicy = false;
        // this.showContactUs = !this.showContactUs;

    }
    logout() {
        this.accountService.logout();
    }
}