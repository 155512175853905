import { Invoice } from "./invoice";

export class Payouts {
    id:string;
    userId:string;
    dealerId:string;
    userName: string;
    payoutAmount:number;
    actualPayoutAmount:number;
    payoutGSTAmount:number;
    actualPayoutGSTAmount:number;
    invoiceNumber: String;
    receiverPanNumber: String;
    receiverGstNumber: String;
    receiverAccountNumber: String;
    totalPayoutAmountBeforeTx: number;
    totalGSTPayoutAmountBeforeTx: number;
    totalPendingAmountBeforeTx: number;
    totalGSTPendingAmountBeforeTx: number;
    comments:string;
    payoutDate:string;
    dealerInvoiceKey:string;
    invoiceDetails: Invoice;
    public constructor(init?: Partial<Payouts>) {
        Object.assign(this, init);
}
}