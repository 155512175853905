import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { PolicyNumberMaintainComponent } from './home/policy-number-maintain/policy-number-maintain.component';
import { AuthGuard } from './_helpers';
import { DealerPriceListMaintainComponent } from './home/dealer-price-list-maintain/dealer-price-list-maintain.component';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const usersModule = () => import('./users/users.module').then(x => x.UsersModule);
const reportsModule = () => import('./reports/reports.module').then(x => x.ReportsModule);
const issueCertificateModule = () => import('./issue-certificate/IssueCertificateModule').then(x => x.IssueCertificateModule); 
const printModule = () => import('./print/print.module').then(x => x.PrintModule); 

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'users', loadChildren: usersModule, canActivate: [AuthGuard] },
    { path: 'issueCertificate', loadChildren: issueCertificateModule, canActivate: [AuthGuard] },
    { path: 'account', loadChildren: accountModule },
    { path: 'reports', loadChildren: reportsModule },
    { path: 'print', loadChildren: printModule },
    {path: 'configUpdate/:id', component: PolicyNumberMaintainComponent},
    {path: 'DealerConfig/DealerPriceList', component: DealerPriceListMaintainComponent},

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];
@NgModule({
        imports: [RouterModule.forRoot(routes, {
                onSameUrlNavigation: 'reload'
            })],
        exports: [RouterModule]
})
export class AppRoutingModule { }