import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReportSearch } from '../_models/reportSearch';
import { Certificate } from '../_models/certificate';
import { environment } from '@environments/environment';
import { CertificateAdmin } from '../_models/certificateAdmin';
import { Payouts } from '@app/_models/payout';
import { Package } from '@app/_models/package';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private router: Router,
    private http: HttpClient) { }
  
  getEndorsementReport(reportSearch: ReportSearch){
      return this.http.post<Certificate[]>(`${environment.apiUrl}/search/getEndorsementReport`, reportSearch);
  }
  getEndorsementPackageReport(reportSearch: ReportSearch){
    return this.http.post<Package[]>(`${environment.apiUrl}/search/getEndorsementPackageReport`, reportSearch);
  }
  getCertificateReport(reportSearch: ReportSearch){
   return this.http.post<Certificate[]>(`${environment.apiUrl}/search/getCertificateReport`, reportSearch);
  }
  getPackageReport(reportSearch: ReportSearch){
    return this.http.post<Package[]>(`${environment.apiUrl}/search/getPackageReport`, reportSearch);
   }
   getAdminCertificateReport(reportSearch: ReportSearch){
    return this.http.post<CertificateAdmin[]>(`${environment.apiUrl}/search/getCertificateReport`, reportSearch);
  } 
  getDealerPackageAggregatedReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getDealerPackageAggregatedReport`, reportSearch);
  } 
  getDealerAggregatedReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getDealerAggregatedReport`, reportSearch);
  } 
  getManagerAggregation(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getManagerAggregation`, reportSearch);
  } 
  getRsaCertificateReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getRsaCertificateReport`, reportSearch);
  } 
  getPayoutDetails(reportSearch: ReportSearch){
    return this.http.post<CertificateAdmin[]>(`${environment.apiUrl}/search/getPayoutDetails`, reportSearch);
  } 
  getDealerBalanceReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getDealerBalancePackageReport`, reportSearch);
  } 
  getPaymentReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getPaymentReport`, reportSearch);
  } 
  getPayoutReportDetails(reportSearch: ReportSearch){
    return this.http.post<Payouts[]>(`${environment.apiUrl}/search/getPayoutReportDetails`, reportSearch);
  } 
  getRenewCertificateList(reportSearch: ReportSearch){
    return this.http.post<Certificate[]>(`${environment.apiUrl}/search/getRenewCertificateList`, reportSearch);
  } 
  getDealerTotalBalanceReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getDealerTotalBalancePackageReport`, reportSearch);
  } 

}
