<div class="container" style="margin-top: 20px;">
<h4 >{{configInput.heading}}</h4>
<form [formGroup]="configMaintainForm" (ngSubmit)="onSubmit()">
    <div class="form-row">
        <div class="form-group col">
            <!-- <label for="ResultValue">{{configInput.key}}</label> -->
            <input type="text"  *ngIf="configInput.configType.toUpperCase() != 'ARRAY'" formControlName="ResultValue" class="form-control"  />
            <textarea *ngIf="configInput.configType.toUpperCase() == 'ARRAY'"  formControlName="ResultValue" class="form-control" cols=50 rows=40></textarea>
        </div>
</div>
<div class="form-group">
    <button [disabled]="loading " class="btn btn-primary" >
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Save
    </button>
</div>
</form>
</div>