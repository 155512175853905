import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Certificate } from '@app/_models/certificate';
import { CertificateResponse } from '@app/_models/certificateResponse';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  constructor(private router: Router,
    private http: HttpClient) { }

    public PrintCertificate: Certificate;

    issueBulkCertificate(certificates: Certificate[]){
      return this.http.post<CertificateResponse>(`${environment.apiUrl}/certificate/issueBulkCertificate`, certificates);
    }
    issueCertificate(certificate: Certificate){
    console.log("certificate: " + certificate);
    return this.http.post<CertificateResponse>(`${environment.apiUrl}/certificate/issueCertificate`, certificate);
  }
  editCertificate(certificate: Certificate){
    // console.log("certificate: " + certificate);
    return this.http.post<Certificate>(`${environment.apiUrl}/certificate/editCertificate`, certificate);
  }
  rsaProvideCertificate(certificate: Certificate){
    return this.http.post<Certificate>(`${environment.apiUrl}/certificate/rsaProvideCertificate`, certificate);
  }
  cancelCertificate(certificate: Certificate){
    console.log("certificate: " + certificate);
    return this.http.get(`${environment.apiUrl}/certificate/cancelCertificate/${certificate.id}`)

  }

}
