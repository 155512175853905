import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { RsaProvided } from "./rsaProvided";

export class Certificate{
    id: string;
    vehicleMake: string;
    model: string;
    type: string;
    variant:string;
    registrationNumber: string;
    chasisNumber: string;
    engineNumber: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    contactNumber: string;
    employeeContactNumber ?:string;
    clientType ?:string;
    gender: string;
    dob: string; 
    gstin: string;
    branchNumber?: string;
    address: string;
    state: string;
    city: string;
    nomineeName: string;
    nomineeRelationship: string;
    nomineeGender: string;
    nomineeAge: string; 
    planId: string;
    planAmount: number;
    planGSTAmount: number ;
    planTotalAmount: number;
    NetPlanAmount?: number;
    NetPlanGSTAmount?: number;
    NetPlanTotalAmount?: number;
    startDate: string;
    endDate: string;
    issueDate: string;
    vehicleType?: string;
    userId: string;
    policyNumber: string;
    certificateNumber:string;
    dealerName:string;
    vehicleYear:string;
    issueDateString:string;
    dealerId:string;
    policyType:string;
    certificateStatus:string;
    isDeleting?:boolean;
    SgstAmount: Number;
    CgstAmount: Number;
    IgstAmount: Number;
    createdDate:Date;
    createdDateString?: String;
    gstApplicable?: string;
    gstShow?:string;
    endorsementReason?:string;
    endorsementCount?:Number;
    lastEndorsementTime?:Date;
    printCpaPolicy?:string;
    planCPACoverAmount?:number;
    nomineeCoverAmount?:number;
    financeCompanyName? :string;
    financeCoverAmount?:number;
    printRsaNumber?:string;
    ageOrDob?:string;
    age?:number;
    rsaNumberOfServices?:number;
    rsaProvidedInfo?:RsaProvided[];
    rsaProvidedCount?:Number;
    createdFromPackage ?= false;
    personalAccidentCover?:number;
    updatedDate?:Date;
    userName?:string;
    dealerEmail?:string;
    cancellationReason?:string;
    dealerBalanceCancel?:number=0;






    public constructor(init?: Partial<Certificate>) {

        Object.assign(this, init);
    }   

}