<app-view-package></app-view-package>
<div class="form-group">
    <button class="btn btn-primary" (click)="onConfirm()" [disabled]="confirmed" >
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Confirm
    </button><a>&nbsp;&nbsp;</a>
    <button  class="btn btn-info mr-1" type="Edit" (click)="onEdit()">
        Edit
    </button>
    <button  class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
        New Package
    </button>
    <a>&nbsp;&nbsp;</a>
</div>
