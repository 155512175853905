import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }
  options = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) };

  fileUpload(inputData: FormData){
    return this.http.post<any>(`${environment.apiUrl}/file/fileUpload`, inputData);
  }
  getImageData(inputData: string){
    return this.http.get(`${environment.apiUrl}/file/${inputData}`, { responseType: 'blob' });
  }
  getPdfData(inputData: string){
    return this.http.get(`${environment.apiUrl}/file/pdf/${inputData}`, { responseType: 'blob' });
  }
}
