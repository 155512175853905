import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RequestHistory } from '@app/_models/RequestHistory';
import { CommonModule, DatePipe } from '@angular/common';
import { PackageService } from '@app/issue-certificate/package.service';
import { Package } from '@app/_models/package';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-show-request-history',
  standalone: true,
  imports: [DatePipe,CommonModule],
  templateUrl: './show-request-history.component.html',
  styleUrl: './show-request-history.component.less'
})
export class ShowRequestHistoryComponent implements OnInit{
  private _requestHistory = new Subject<RequestHistory[]>();
  requestHistory$ = this._requestHistory.asObservable();
  requestHistory: RequestHistory[] = [];
  package:Package

  constructor(private datePipe: DatePipe, private packageService:PackageService) { }

  ngOnInit(): void {
    this.packageService.currentPackage.subscribe((data) => {
      if (data) {
        this.requestHistory =[]
      this.requestHistory = data.requestHistory;
      console.log(JSON.stringify(this.requestHistory));
      this.updateRequestHistory(this.requestHistory);
      this.package = data;
      }
  })
  }
  updateRequestHistory(newRequestHistory: RequestHistory[]) {
    this._requestHistory.next(newRequestHistory);
  }
}
