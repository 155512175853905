<div class="container-fluid" [ngClass]="{ 'bg-light': user }" style="overflow:scroll">
    <!-- nav -->
    <nav class="navbar navbar-expand navbar-dark linkedin-navbar" *ngIf="user">
        <div class="container-fluid">
            <div class="collapse navbar-collapse align-nav" id="navbarSupportedContent" *ngIf="user.role != 'rsaOperation'">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item" style="margin-left: 10px;">
                        <a class="nav-item " href="#">
                            <img class="logo" src="../assets/AllianceInsuranceWebsite.png" height="50px">
                        </a>
                    </li>
        
                <li class="nav-item" >
                    <a class="nav-item nav-link active" aria-current="page" routerLink="/" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-item nav-link" routerLink="/users" routerLinkActive="active"
                        *ngIf="user.role == 'admin'">Dealer</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/users/viewDealer" routerLinkActive="active"
                        *ngIf="user.role == 'dealerOwner'">Dealer</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-item nav-link" routerLink="/users/dealerPayout/" routerLinkActive="active"
                        *ngIf="(user.role == 'dealerOwner' && showReceivableTab)">Receivable</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-item nav-link" routerLink="/issueCertificate" routerLinkActive="active">Create 
                        Package</a>
                </li>
                <!-- <li class="nav-item dropdown" *ngIf="user.role == 'admin' || (user.specialRoleAccess && user.specialRoleAccess.includes('ExcelUpload'))">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Bulk Upload
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" routerLink="/issueCertificate/bulkIndividual"
                                routerLinkActive="active">Individual Policy</a></li>
                                </ul>
                </li> -->
                <li class="nav-item">
                    <a class="nav-item nav-link" routerLink="/reports/printCertificate" routerLinkActive="active">Package List</a>
                </li>
                <li class="nav-item">
                    <a class="nav-item nav-link" routerLink="/reports/requestCertificate" routerLinkActive="active" *ngIf="user.role == 'admin'">Active Request</a>
                </li>
                <!-- <li class="nav-item" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanRenewAllPolicies')">
                    <a class="nav-item nav-link" routerLink="/reports/renewCertificate" routerLinkActive="active">Renew
                        Certificate</a>
                </li> -->
                <li class="nav-item dropdown" *ngIf="user.role == 'admin' || user.role == 'dealerOwner' || (user.specialRoleAccess && user.specialRoleAccess.includes('CanViewDealerBalanceReport'))">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Reports
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" routerLink="/reports/certificateReport"
                                routerLinkActive="active">Package Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/dealerBalanceReport" routerLinkActive="active"
                                    *ngIf="user.role == 'admin' || user.role == 'dealerOwner' || (user.specialRoleAccess && user.specialRoleAccess.includes('CanViewDealerBalanceReport'))">Dealer
                                    Balance Details</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/dealerAggregateReport"
                                    routerLinkActive="active" *ngIf="user.role == 'admin' || user.role == 'employee' || user.role == 'dealerOwner' || user.role == 'manager'">Dealer
                                    Aggregate Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/totalBalanceReport" routerLinkActive="active"
                                    *ngIf="user.role == 'admin' ||  (user.specialRoleAccess && user.specialRoleAccess.includes('Can View Package Balance Detail Report') && dealer.dealerCreatePackage)">Package
                                    Balance Details</a></li>
                                    <li><a class="dropdown-item" routerLink="/reports/endorsementReport" routerLinkActive="active"
                                        *ngIf="user.role == 'admin'">Modification Report</a></li>
                    
                                    <!-- <li><a class="dropdown-item" routerLink="/reports/dealerReport" routerLinkActive="active"
                                *ngIf="user.role == 'admin' || user.role == 'employee'">Dealer Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/dealerAggregateRepor
                                routerLinkActive="active" *ngIf="user.role == 'admin' || user.role == 'employee'">Dealer
                                Aggregate Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/managerAggregateReport"
                                    routerLinkActive="active" *ngIf="user.role == 'manager' || user.role == 'dealerOwner'">
                                    Aggregate Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/rsaCertificate" routerLinkActive="active"
                                *ngIf="user.role == 'admin'">Rsa Certificate</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/transactionReport" routerLinkActive="active"
                                *ngIf="user.role == 'admin'">Payment Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/invoiceReport" routerLinkActive="active"
                                *ngIf="user.role == 'admin'">Invoice Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/payoutReport" routerLinkActive="active"
                                *ngIf="user.role == 'admin'">Payout Report</a></li>
                        <li><a class="dropdown-item" routerLink="/reports/certificateReport/NewIndiaPolicy"
                                routerLinkActive="active" *ngIf="user.role == 'admin'">New India Report</a></li> -->
                    </ul>
                </li> 
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownAnalytics" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Analytics
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownAnalytics">
                        <li>
                            <a class="dropdown-item" routerLink="/analytics/numberOfPolicies" routerLinkActive="active"
                                *ngIf="user.role == 'admin' || user.role == 'dealerOwner'">Number of Policies</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/analytics/receivable" routerLinkActive="active"
                                *ngIf="user.role == 'admin' || user.role == 'dealerOwner'">Receivable</a>
                        </li>
                    </ul>
                </li> -->
                <li class="nav-item dropdown" *ngIf="user.role == 'admin'">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownConfigUpdate" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Configuration Update
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownConfigUpdate">
                        <li>
                            <a class="dropdown-item" routerLink="/configUpdate/rtoList"
                                routerLinkActive="active">RTO List</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/configUpdate/Finance"
                                routerLinkActive="active">Finance Company</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/configUpdate/ModelVersion"
                                routerLinkActive="active">Models</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/configUpdate/Plan"
                                routerLinkActive="active">Plans</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/configUpdate/PackageId"
                                routerLinkActive="active">Package Id</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/configUpdate/PrintInstruction"
                                routerLinkActive="active">PrintInstruction</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/configUpdate/State"
                                routerLinkActive="active">States</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/configUpdate/Package"
                                routerLinkActive="active">Package</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/configUpdate/InsuranceCompany"
                                routerLinkActive="active">InsuranceCompany</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/DealerConfig/DealerPriceList"
                                routerLinkActive="active">Dealer Price List</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/configUpdate/PremiumCalculation"
                                routerLinkActive="active">Premium Calculation</a>
                                </li>
                    </ul>
                </li>
                <li class="nav-item" *ngIf="user.role == 'manager'">
                    <a class="nav-item nav-link" routerLink="/users/userList" routerLinkActive="active">User Maintainence</a>
                </li>
                <li class="nav-item">
                    <a class="nav-item nav-link" (click)="logout()">Logout</a>
                </li>
            </ul>
        </div>

        <div class="collapse navbar-collapse align-nav" id="navbarSupportedContent" *ngIf="user.role == 'rsaOperation'">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-item nav-link active" aria-current="page" routerLink="/" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Home</a>
                </li>
                <li class="nav-item" >
                    <a class="nav-item nav-link" routerLink="/issueCertificate/rsa" routerLinkActive="active">RSA</a>
                </li>
                <li class="nav-item" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanRenewAllPolicies')">
                    <a class="nav-item nav-link" routerLink="/reports/renewCertificate" routerLinkActive="active">Renew
                        Certificate</a>
                </li>
                <li class="nav-item">
                    <a class="nav-item nav-link" (click)="logout()">Logout</a>
                </li>
            </ul>
        </div>

        <div class="d-flex navbar-dark user-info">
            <p>
                <i class="fa fa-user user-icon"></i>
                <a class="nav-item navbar-link" (click)="logout()">&nbsp;{{" " + user.firstName + " " + user.lastName}}</a>
            </p>
        </div>
    </div>
</nav>

<!-- main app container -->
    <alert></alert>
    <router-outlet></router-outlet>
    <alert></alert>

<!-- credits -->
<div class="text-center mt-4">
    <p>
        For any support reach out at <a href="">support&#64;allianceassure.in</a>
    </p>
    <p> <img src="../assets/AllianceLogo.png" height="80px">
        <a href="https://AllianceAssure.com" target="_top">AllianceAssure.com</a>
    </p> <br>
    <small><a href="https://allianceassure.com/terms-conditions/">TERMS AND CONDITIONS </a>&nbsp;<a href="https://allianceassure.com/privacy-policy/">| PRIVACY
            POLICY |</a>&nbsp;<a style="color: #0d6efd;text-decoration-line:underline" (click)="cancellationPolicyToggle()"> CANCELLATION/REFUND POLICY |</a>&nbsp; <a
            href="https://allianceassure.com/contact/"> CONTACT US |</a>&nbsp;<a
            href="https://allianceassure.com/"> ABOUT US |</a> </small>
    <br> 
    <!-- <small *ngIf="showTermsCondition">
        DETAILED INSTRUCTIONS ARE GIVEN IN THE LOGIN PAGE OF THE ONLINE PAYMENT AS TO THE PROCEDURE TO BE FOLLOWED IN
        CASE OF COMPLETED TRANSACTION AND PAYMENT CONFIRMATION
        SLIP RECEIVED AND ALSO FAILED TRANSACTION. PLS FOLLOW THE INSTRUCTION CAREFULLY. ONCE “PAY” - OPTION IS SELECTED
        YOU WILL BE DIRECTED FOR PAYMENT THROUGH NET BANKING OR DEBIT /
        CREDIT CARD. YOU CAN CHOOSE THE DESIRED PAYMENT OPTION AND PROCEED. ONCE THE PAYMENT IS SUCCESSFUL, YOU WILL GET
        A PAYMENT CONFIRMATION. IN CASE THE PAYMENT IS NOT SUCCESSFUL
        DUE TO ANY REASON YOU WILL GET A DISPLAY ON THE STATUS OF FAILURE IN PAYMENT. IN CASE NONE OF THE ABOVE TWO
        HAPPENS, AND THERE IS HEAVY DELAY IN ANY RESPONSE FROM THE SYSTEM - IF
        YOU HAVE NOT PROCEEDED WITH PAYMENT AND NOT GIVEN CARD DETAIL PARTICULARS, YOU MAY PROCEED FROM THE BEGINNING
        AGAIN AND START THE PAYMENT PROCESS AGAIN IN CASE YOU HAVE
        GIVEN ALL THE DEBIT / CREDIT CARD DETAILS OR NET BAKING AUTHORIZATION FOR PAYMENT, AND HAVE NOT GOT ANY
        RESPONSE, PLEASE CHECK WITH YOUR BANKERS OR CREDIT CARD COMPANY AND
        SEE IF YOUR ACCOUNT IS DEBITED. IF YOUR BANK ACCOUNT/CC IS DEBITED, PLEASE DONT MAKE ANY ATTEMPT TO PAY AGAIN.
        HOWEVER, IF YOUR ACCOUNT IS NOT DEBITED IN THE BANK, YOU HAVE TO
        MAKE THE PAYMENT AND GET PAYMENT SUCCESSFUL CONFIRMATION. IN ANY CASE, MAKE A NOTE OF REFERENCE/TRANSACTION
        DETAILS IN CASE OF NET BANKING OR CARD PAYMENT.
    </small>
    <small *ngIf="showPrivatePolicy">
        THE DETAILS PROVIDED BY YOU SHALL BE UTILIZED ONLY FOR THE PURPOSE OF RECEIVING THE PAYMENTS TO BE MADE BY YOU .
        ALL DATA SHALL BE KEPT SECURE, AND SHALL NOT BE DIVULGED TO
        ANYONE OR UTILIZED FOR ANY OTHER PURPOSE
    </small> -->
    <small *ngIf="showCancellationPolicy">
        THERE IS NO CANCELLATION OPTION FOR THE END USERS AFTER PAYMENT IS MADE. IN CASE OF DUPLICATE PAYMENT, KINDLY
        APPROACH US AT
        SUPPORT&#64;ALLIANCEASSURE.IN .FOR REFUND WITH PROOF OF THE TRANSACTION REFERENCE/ YOUR
        BANK STATEMENT. REFUND WILL BE PROCESSED WITHIN 10-15 WORKING DAYS, RESPECTIVE PAYMENT
        GATEWAY WILL SEND BACK TO THE ISSUING BANK [USER’S CARD BANKER] IN BATCHES FOR
        PROCESSING, WHICH SHOULD APPROXIXMATELY TAKE 10-15 WORKING DAYS, DEPENDING ON ISSUING
        BANKS POLICIES. HOWEVER, ALLIANCE ASSURE PVT LTD. IS NOT RESPONSIBLE FOR ANY DELAY ON THE
        BANK SIDE.
    </small>
    <!-- <small *ngIf="showContactUs">
        <b>Corporate Address:</b> Alliance Assure Private Limited<br>
        AltF Global,First Floor, Global Foyer Mall, Sector 43 ,Golf Course Road, Gurugram ( Haryana ) -122009<br>
        PH: 1800 891 3776, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC<br>
        For Assistance, Please contact us at: Email ID: support@allianceassure.in
    </small> -->
</div>
</div>
