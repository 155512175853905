import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Dealer } from '@app/_models/dealer';
import { PlanDetails } from '@app/_models/planDetails';
import { AlertService } from '@app/_services';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-plan-details',
  templateUrl: './add-edit-plan-details.component.html',
  styleUrls: ['./add-edit-plan-details.component.less']
})
export class AddEditPlanDetailsComponent implements OnInit {
  planForm: UntypedFormGroup;
  isPlanShow = false;
  isRsaNumberOfServiceShow = false;
  isPlanFormShow = false;
  selectedPlan: PlanDetails = new PlanDetails();
  plansList: PlanDetails[];
  selectedPlanList:PlanDetails[];
  booleanArray = ["Yes","No"];
  submitted = false;
  loading = false;
  id:string;
  dealer: Dealer;
  dealerPlans: PlanDetails[];
  gstPercentage: number;
  totalAmount:number;
  applicableOnlyOnPolicyTypes = ["Old","New","Both","One year Od Renewal","One year OD Plus TP Renewal"]
  editPlanAction: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private dealerService: DealerService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private configDataService: ConfigDataService

  ) { }

  ngOnInit(): void {
    this.gstPercentage = this.configDataService.getGST();
    this.id = this.route.snapshot.params['id'];
    this.configDataService.PlanDetails$.subscribe((x) => this.plansList = x);

    this.planForm = this.formBuilder.group({
      planId: ['', Validators.required],
      planAmount: ['', Validators.required],
      planGstAmount: ['', Validators.required],
      planNetAmount: ['', Validators.required],
      planTotalAmount: ['', Validators.required],
      planMaximumAmount: ['', Validators.required],
      policyType: [''],
      gstApplicable:['',Validators.required],
      gstShow:['',Validators.required],
      planMinimumAmount:[0],
      rsaNumberOfServices:[0],
      printCpaPolicy: [''],
      planCPACoverAmount: [''],
      printRsaNumber: [''],
      counterId: [''],
      masterPolicyNumber: [''],
      certificatePrefix: [''],
      numberOfYears:[1],
      applicableOnlyOnPolicyType:["Both"]
          });   
    
    this.dealerService.getByDealerId(this.id)
    .pipe(first())
    .subscribe((x)=>{
      this.dealer = x;
      if (x.dealerPlan.length > 0){
      this.dealerPlans = x.dealerPlan;
      }
    })
    this.planForm.get('planId').valueChanges.subscribe((value) => {
      this.selectedPlan = this.plansList.filter((x) => x.planId == value)[0];
      if (this.editPlanAction)
      {
        this.selectedPlan = this.dealer.dealerPlan.filter((x) => x.planId == value)[0];
        this.editPlanAction = false;
      }
      //this.planForm.patchValue(this.selectedPlan);
      this.planForm.controls['planAmount'].setValue(this.selectedPlan.planAmount);
      this.planForm.controls['planGstAmount'].setValue(this.selectedPlan.planGstAmount);
      this.planForm.controls['planNetAmount'].setValue(this.selectedPlan.planNetAmount);
      this.planForm.controls['planTotalAmount'].setValue(this.selectedPlan.planTotalAmount);
      this.planForm.controls['planMaximumAmount'].setValue(this.selectedPlan.planMaximumAmount);
      this.planForm.controls['planMinimumAmount'].setValue(this.selectedPlan.planMinimumAmount);
      this.planForm.controls['policyType'].setValue(this.selectedPlan.policyType);
      this.planForm.controls['gstApplicable'].setValue(this.selectedPlan.gstApplicable);
      this.planForm.controls['gstShow'].setValue(this.selectedPlan.gstShow);
      this.planForm.controls['printCpaPolicy'].setValue(this.selectedPlan.printCpaPolicy?this.selectedPlan.printCpaPolicy: this.booleanArray[0]);
      this.planForm.controls['planCPACoverAmount'].setValue(this.selectedPlan.planCPACoverAmount?this.selectedPlan.planCPACoverAmount: 1500000);
      this.planForm.controls['printRsaNumber'].setValue(this.selectedPlan.printRsaNumber?this.selectedPlan.printRsaNumber: this.booleanArray[1]);
      this.planForm.controls['numberOfYears'].setValue(this.selectedPlan.numberOfYears?this.selectedPlan.numberOfYears: 1);
      this.planForm.controls['applicableOnlyOnPolicyType'].setValue(this.selectedPlan.applicableOnlyOnPolicyType?this.selectedPlan.applicableOnlyOnPolicyType: this.applicableOnlyOnPolicyTypes[2]);
      this.planForm.controls['counterId'].setValue(this.selectedPlan.counterId?this.selectedPlan.counterId: "CertificateId");
      this.planForm.controls['masterPolicyNumber'].setValue(this.selectedPlan.masterPolicyNumber?this.selectedPlan.masterPolicyNumber: "MasterPolicyNumber");
      this.planForm.controls['certificatePrefix'].setValue(this.selectedPlan.certificatePrefix?this.selectedPlan.certificatePrefix: "AANIA");
      if (this.selectedPlan.policyType.includes("RSA"))
      {
        this.isRsaNumberOfServiceShow = true;
      }
      else      {
        this.isRsaNumberOfServiceShow = false;
      }
      this.planForm.controls['rsaNumberOfServices'].setValue(0);

    })

    this.planForm.get('planTotalAmount').valueChanges.subscribe((value) => {   
      if (value){  
        if (value != this.selectedPlan.planTotalAmount) {
      this.planForm.controls['planGstAmount'].setValue(parseFloat((value - parseFloat((value / (1 + this.gstPercentage)).toFixed(2))).toFixed(2)));
      this.planForm.controls['planAmount'].setValue(parseFloat((value / (1 + this.gstPercentage)).toFixed(2)));
      this.planForm.controls['planMaximumAmount'].setValue(parseFloat((value / (1 + this.gstPercentage)).toFixed(2)));
      this.planForm.controls['planMinimumAmount'].setValue(parseFloat((value / (1 + this.gstPercentage)).toFixed(2)));
    }
    else{
      this.planForm.controls['planAmount'].setValue(this.selectedPlan.planAmount);
      this.planForm.controls['planGstAmount'].setValue(this.selectedPlan.planGstAmount);
      this.planForm.controls['planMaximumAmount'].setValue(this.selectedPlan.planMaximumAmount);
      this.planForm.controls['planMinimumAmount'].setValue(this.selectedPlan.planMinimumAmount);

    }
  }
    })
    this.planForm.get('gstApplicable').valueChanges.subscribe((value) => {   
      if (value){
        this.totalAmount = this.planForm.get('planTotalAmount').value;

        if (value =="N"){
          this.planForm.controls['planAmount'].setValue(this.totalAmount);
          this.planForm.controls['planGstAmount'].setValue(0.00);   
              }
          else
          {
            this.planForm.controls['planGstAmount'].setValue(parseFloat((this.totalAmount - parseFloat((this.totalAmount / (1 + this.gstPercentage)).toFixed(2))).toFixed(2)));
            this.planForm.controls['planAmount'].setValue(parseFloat((this.totalAmount / (1 + this.gstPercentage)).toFixed(2)));
            this.planForm.controls['planAmount'].setValue(this.selectedPlan.planAmount);
            this.planForm.controls['planGstAmount'].setValue(this.selectedPlan.planGstAmount);
                      }
                    }
              })
  }

  get f() { return this.planForm.controls; }

  userPlanToggle(){
    this.isPlanShow = !this.isPlanShow;
  }
  userPlanFormToggle(){
    this.isPlanFormShow = !this.isPlanFormShow;
  }
  editPlan(planId:string)
  {
    this.editPlanAction = true;
    this.planForm.controls['planId'].setValue(planId);
    this.isPlanFormShow = true;
  }
  deletePlan(planId: string){
    this.dealer.dealerPlan = this.dealerPlans.filter(x => x.planId != planId);
    this.dealerService.dealerPlanUpdate(this.dealer.id,this.dealer)
    .pipe(first())
    .subscribe({
        next: () => {
          this.dealerPlans = this.dealerPlans.filter(x => x.planId != planId);
        },
        error: error => {
            this.alertService.error(error);

        }
    });  

  }
  onSubmit(){
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.planForm.invalid) {
      return;
    }

    this.loading = true;
    this.selectedPlan = new PlanDetails(this.planForm.value);
    if(this.dealer.dealerPlan.filter(x => x.planId == this.selectedPlan.planId).length > 0)
      {
        this.dealer.dealerPlan =    this.dealer.dealerPlan.map(x => {
          if (x.planId == this.selectedPlan.planId)
          {
            return this.selectedPlan
          }
          return x;
        })
      // this.alertService.error("Plan already present, please delete existing plan and then add new!!!");
      // this.loading = false;      
      // return;
    }
    else {
    this.dealer.dealerPlan.push(this.selectedPlan);
  }
    this.dealerService.dealerPlanUpdate(this.dealer.id,this.dealer)
    .pipe(first())
    .subscribe({
        next: () => {
            this.dealerPlans = this.dealer.dealerPlan
          this.loading = false;

        },
        error: error => {
            this.alertService.error(error);

        }
    });
    this.userPlanFormToggle();
  }
}
