import { MakeModel } from "./makeModel";
import { PlanDetails } from "./planDetails";

export class User {
    id: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    dealerId: string;
    portal?:string;
    employeeDealerList: string[];
    specialRoleAccess:string[];
    token: string;
    public constructor(init?: Partial<User>) {
        Object.assign(this, init);
    }
}