<div class="container">
  <div class="row">
    <div class="col">
      <h4>Create Package - {{ packageNumber }}</h4>
    </div>
    <div class="col"></div>

    <div
      class="col"
      *ngIf="
        !(
          user.specialRoleAccess &&
          user.specialRoleAccess.includes('CannotViewDealerBalance')
        )
      "
    >
      <div class="row">
        <div class="col">
          <h4 style="position: relative">Balance: &nbsp;</h4>
        </div>
        <div class="col">
          <h4 style="position: relative; background-color: rgb(121, 201, 190)">
            {{ dealer.dealerBalance }}
          </h4>
        </div>
        <div class="col">
          <button
            class="btn btn-secondary mr-1"
            type="reset"
            (click)="paymentGatewayShowToggle()"
            *ngIf="showPaymentButton"
          >
            Add Money
          </button>
        </div>
        <div class="container" *ngIf="showPaymentGatewayForm">
          <app-dealer-payment-gateway></app-dealer-payment-gateway>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
<nav aria-label="breadcrumb">
  <ol class="breadcrumb" >
    <li
      class="breadcrumb-item active"
      aria-current="page"
      style="padding: 10px"
    >
    <div class="breadcrumb-content">
      <i class="fa fa-car breadcrumb-icon"></i>
      <p class="h5  breadcrumb-text">Vehicle Details</p>
    </div>
    </li>
  </ol>
</nav>
<form [formGroup]="certficateForm" (ngSubmit)="onSubmit()">
  <div class="form-row">
    <div class="form-group col">
      <label for="type">Type</label>
      <select
        class="form-select"
        formControlName="type"
        [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
      >
        <option *ngFor="let type of types" [ngValue]="type">
          {{ type }}
        </option>
      </select>
      <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
        <div *ngIf="f.type.errors.required">Type is required</div>
      </div>
    </div>

    <div class="form-group col">
      <label for="vehicleMake">Make</label>
      <select
        class="form-select"
        formControlName="vehicleMake"
        [ngClass]="{ 'is-invalid': submitted && f.vehicleMake.errors }"
      >
        <option
          *ngFor="let vehicleMake of makeModel"
          [ngValue]="vehicleMake.make"
        >
          {{ vehicleMake.make }}
        </option>
      </select>
      <div
        *ngIf="f.vehicleMake.dirty && f.vehicleMake.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.vehicleMake.errors.required">
          Make needs to be selected
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="model">Model</label>
      <select
        class="form-select"
        formControlName="model"
        [ngClass]="{ 'is-invalid': submitted && f.model.errors }"
      >
        <option *ngFor="let model of models" [ngValue]="model">
          {{ model }}
        </option>
      </select>
      <div *ngIf="submitted && f.model.errors" class="invalid-feedback">
        <div *ngIf="f.model.errors.required">Model is required</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="variant">Variant</label>
      <input
        type="text"
        formControlName="variant"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.variant.errors }"
      />
      <div *ngIf="f.variant.dirty && f.variant.errors" class="invalid-feedback">
        <div *ngIf="f.variant.errors.required">Variant is required</div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="vehicleYear">Manufacturing Year</label>
      <select
        class="form-select"
        formControlName="vehicleYear"
        [ngClass]="{ 'is-invalid': submitted && f.vehicleYear.errors }"
      >
        <option *ngFor="let vehicleYear of years" [ngValue]="vehicleYear">
          {{ vehicleYear }}
        </option>
      </select>
      <div *ngIf="submitted && f.vehicleYear.errors" class="invalid-feedback">
        <div *ngIf="f.vehicleYear.errors.required">
          Manufacturing year is required
        </div>
      </div>
    </div>
    <div class="form-group col" *ngIf="f.type.value =='Old'">
      <label for="vehicleRegistrationDate">Vehicle Registration Date</label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="vehicleRegistrationDate"
          placeholder="DD/MM/YYYY"
          [minDate]="minRegistrationDateModel"
          [maxDate]="maxRegistrationDateModel"
          ngbDatepicker
          #d4="ngbDatepicker"
          [ngClass]="{ 'is-invalid': submitted && f.vehicleRegistrationDate.errors }"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary fa fa-calendar"
            (click)="d4.toggle()"
            type="button"
          ></button>
        </div>
      </div>
      <div
        *ngIf="submitted && f.vehicleRegistrationDate.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.vehicleRegistrationDate.errors.required">
          Vehicle Registration Date is required
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="registrationNumber">Registration Number</label>
      <input
        type="text"
        formControlName="registrationNumber"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.registrationNumber.errors }"
      />
      <div
        *ngIf="submitted && f.registrationNumber.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.registrationNumber.errors.required">
          Registration Number is required
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="chasisNumber">Chasis Number</label>
      <input
        type="text"
        formControlName="chasisNumber"
        class="form-control"
        [ngClass]="{
          'is-invalid':
            (f.chasisNumber.touched || submitted) && f.chasisNumber.errors
        }"
      />
      <div *ngIf="f.chasisNumber.errors" class="invalid-feedback">
        <div *ngIf="f.chasisNumber.errors.required">
          Chasis Number is required
        </div>
        <div *ngIf="f.chasisNumber.errors.minlength">
          Invalid Chasis Number!!! Chasis Number should be minimum of 17
          characters. If shorter please add zeroes ('0's) before it.
        </div>
        <div *ngIf="f.chasisNumber.errors.maxlength">
          Invalid Chasis Number!!! Chasis Number should be maximum of 17
          characters. If shorter please remove zeroes ('0's) before it.
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="engineNumber">Engine Number</label>
      <input
        type="text"
        formControlName="engineNumber"
        class="form-control"
        [ngClass]="{
          'is-invalid':
            (f.engineNumber.touched || submitted) && f.engineNumber.errors
        }"
      />
      <div *ngIf="f.engineNumber.errors" class="invalid-feedback">
        <div *ngIf="f.engineNumber.errors.required">
          Engine Number is required
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="rto">RTO code</label>
      <select
        class="form-select"
        formControlName="rto"
        [ngClass]="{ 'is-invalid': submitted && f.rto.errors }"
      >
        <option *ngFor="let rtoVal of rtoList" [ngValue]="rtoVal">
          {{ rtoVal }}
        </option>
      </select>
      <div *ngIf="submitted && f.rto.errors" class="invalid-feedback">
        <div *ngIf="f.rto.errors.required">RTO is required</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="hypothecated">Hypothecated Vehicle</label>
      <div class="row">
        <div class="col">
          <input
            type="radio"
            formControlName="hypothecated"
            [value]="'Yes'"
            [ngClass]="{ 'is-invalid': submitted && f.hypothecated.errors }"
          />
          &nbsp;&nbsp;<span>Yes</span>
        </div>
        <div class="col">
          <input
            type="radio"
            formControlName="hypothecated"
            [value]="'No'"
            [ngClass]="{ 'is-invalid': submitted && f.hypothecated.errors }"
          />
          &nbsp;&nbsp;<span>No</span>
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="clientType">Client Type</label>
      <select
        class="form-select"
        formControlName="clientType"
        [ngClass]="{ 'is-invalid': submitted && f.clientType.errors }"
      >
        <option *ngFor="let clientType of clientTypes" [ngValue]="clientType">
          {{ clientType }}
        </option>
      </select>
      <div *ngIf="submitted && f.clientType.errors" class="invalid-feedback">
        <div *ngIf="f.clientType.errors.required">
          Client Type needs to be selected
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="vehicleColor">Vehicle Color</label>
      <input
        type="text"
        formControlName="vehicleColor"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.vehicleColor.errors }"
      />
    </div>

  </div>
  <div class="form-row" *ngIf="f.hypothecated.value != 'No'">
    <div class="form-group col">
      <label for="financeCompanyName">Finance Company Name</label>
      <select
        class="form-select"
        formControlName="financeCompanyName"
        [ngClass]="{ 'is-invalid': submitted && f.financeCompanyName.errors }"
      >
        <option
          *ngFor="let finCompany of financeCompanies"
          [ngValue]="finCompany"
        >
          {{ finCompany }}
        </option>
      </select>

      <div
        *ngIf="submitted && f.financeCompanyName.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.financeCompanyName.errors.required">
          Finance Company Name is required
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="financeCompanyAddress">Finance Company Address</label>
      <input
        type="text"
        formControlName="financeCompanyAddress"
        class="form-control"
        [ngClass]="{
          'is-invalid': submitted && f.financeCompanyAddress.errors
        }"
      />
      <div
        *ngIf="submitted && f.financeCompanyAddress.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.financeCompanyAddress.errors.required">
          Finance Company Address is required
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="financeCompanyState">Finance State</label>
      <select
        class="form-select"
        formControlName="financeCompanyState"
        [ngClass]="{ 'is-invalid': submitted && f.financeCompanyState.errors }"
      >
        <option *ngFor="let finstate of stateCity" [ngValue]="finstate.state">
          {{ finstate.state }}
        </option>
      </select>
      <div
        *ngIf="submitted && f.financeCompanyState.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.financeCompanyState.errors.required">
          Finance State needs to be selected
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="financeCompanyCity">City</label>
      <select
        class="form-select"
        formControlName="financeCompanyCity"
        [ngClass]="{ 'is-invalid': submitted && f.financeCompanyCity.errors }"
      >
        <option *ngFor="let fincity of finCities" [ngValue]="fincity">
          {{ fincity }}
        </option>
      </select>
      <div
        *ngIf="submitted && f.financeCompanyCity.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.financeCompanyCity.errors.required">City is required</div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <!-- <div class="form-group col" *ngIf="f.type.value == 'Old'">
      <label for="oldValueType">Premium Calculation Based On</label>
      <div class="row">
        <div class="col">
          <input
            type="radio"
            formControlName="oldValueType"
            [value]="'Invoice'"
            [ngClass]="{ 'is-invalid': submitted && f.oldValueType.errors }"
          />
          &nbsp;&nbsp;<span>Invoice Value(Ex-showroom)</span>
        </div>
        <div class="col">
          <input
            type="radio"
            formControlName="oldValueType"
            [value]="'PrevIdv'"
            [ngClass]="{ 'is-invalid': submitted && f.oldValueType.errors }"
          />
          &nbsp;&nbsp;<span>Previous IDV</span>
        </div>
      </div>
    </div> -->

    <div class="form-group col">
      <label for="invoiceFile" *ngIf="f.oldValueType.value == 'Invoice'">Invoice Upload</label>
      <label for="invoiceFile" *ngIf="f.oldValueType.value != 'Invoice'">Previous Policy</label>
      <input
        type="file"
        (change)="onInvoiceFileSelected($event)"
        formControlName="invoiceFile"
        accept="image/*,application/pdf"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.invoiceFile.errors }"
      />
      <div *ngIf="submitted && f.invoiceFile.errors" class="invalid-feedback">
        <div *ngIf="f.invoiceFile.errors.required">
          Invoice File is required
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="invoiceValue" *ngIf="f.oldValueType.value == 'Invoice'">Invoice Value</label>
      <label for="invoiceValue" *ngIf="f.oldValueType.value != 'Invoice'">Previous Policy IDV</label>
      <input
        type="number"
        formControlName="invoiceValue"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.invoiceValue.errors }"
      />
      <div *ngIf="submitted && f.invoiceValue.errors" class="invalid-feedback">
        <div *ngIf="f.invoiceValue.errors.required">
          Invoice Value is required
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="vehicleColor">Cubic Capacity</label>
      <input
        type="number"
        formControlName="cubicCapacity"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.cubicCapacity.errors }"
      />
      <div *ngIf="submitted && f.cubicCapacity.errors" class="invalid-feedback">
        <div *ngIf="f.cubicCapacity.errors">
          Cubic Capacity is required and greater than zero
        </div>
      </div>
    </div>
  </div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb" >
      <li
        class="breadcrumb-item active"
        aria-current="page"
      >
      <div class="breadcrumb-content">
        <i class="fa fa-book breadcrumb-icon" style="margin: 5px"></i>
        <p class="h5 breadcrumb-text">Package Details</p>
        </div>
      </li>
    </ol>
  </nav>
  <div class="form-row">
    <!-- <div class="form-group col">
            <label for="insuranceCompany">Insurance Company</label>
            <select class="form-select" formControlName="insuranceCompany" [ngClass]="{ 'is-invalid': submitted && f.insuranceCompany.errors }" >
                <option *ngFor="let company of packageInsuranceCompany " [ngValue]="company">
                  {{ company }}
                </option>
              </select>
            <div *ngIf="submitted && f.insuranceCompany.errors" class="invalid-feedback">
                <div *ngIf="f.insuranceCompany.errors.required">Insurance Company is required</div>
            </div>
        </div> -->
    <div class="form-group col">
      <label for="packageId">Coverage</label>
      <select
        class="form-select"
        formControlName="packageId"
        [ngClass]="{ 'is-invalid': submitted && f.packageId.errors }"
      >
        <option
          *ngFor="let packag of selectedPackages"
          [ngValue]="packag.packageId"
        >
          {{ packag.packageName }}
        </option>
      </select>
      <div *ngIf="submitted && f.packageId.errors" class="invalid-feedback">
        <div *ngIf="f.packageId.errors.required">Coverage is required</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="idv">Insured Declared Value (IDV)</label>
      <input
        type="number"
        formControlName="idv"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.idv.errors }"
      />
      <div *ngIf="submitted && f.idv.errors" class="invalid-feedback">
        <div *ngIf="f.idv.errors.required">IDV is required</div>
      </div>
    </div>
    <div class="form-group col" *ngIf="f.type.value == 'Old'">
      <label for="prevPolicyExpire">Previous Policy Expired</label>
      <select
        class="form-select"
        formControlName="prevPolicyExpire"
        [ngClass]="{ 'is-invalid': submitted && f.prevPolicyExpire.errors }"
      >
        <option
          *ngFor="let prevPolExpire of policyExpireArray"
          [ngValue]="prevPolExpire"
        >
          {{ prevPolExpire }}
        </option>
      </select>
      <div
        *ngIf="submitted && f.prevPolicyExpire.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.prevPolicyExpire.errors.required">
          Previous Policy Expired is required
        </div>
      </div>
    </div>
    <div class="form-group col" *ngIf="dealarConfigFound || f.type.value == 'Old'">
      <label for="packagePolicyTotalAmount">Estimated Insurance Amount</label>
      <input
        type="number"
        formControlName="packagePolicyTotalAmount"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.idv.errors }"
      />
    </div>
  </div>
  <div class="form-row" *ngIf="f.prevPolicyExpire.value == policyExpireArray[2] || f.prevPolicyExpire.value == policyExpireArray[3]">
    <div class="form-group col">
      <label for="breakInspectionReportNumber">Inspection Report#</label>
      <input
        type="text"
        formControlName="breakInspectionReportNumber"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.breakInspectionReportNumber.errors }"
      />
      <div *ngIf="submitted && f.breakInspectionReportNumber.errors" class="invalid-feedback">
        <div *ngIf="f.breakInspectionReportNumber.errors.required">Inspection Report Number is required</div>
      </div>
    </div>

    <div class="form-group col" >
      <label for="breakInspectionReportDate">Inspection Date</label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="breakInspectionReportDate"
          placeholder="DD/MM/YYYY"
          ngbDatepicker
          #break="ngbDatepicker"
          [ngClass]="{ 'is-invalid': submitted && f.breakInspectionReportDate.errors }"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary fa fa-calendar"
            (click)="break.toggle()"
            type="button"
          ></button>
        </div>
        <div *ngIf="submitted && f.breakInspectionReportDate.errors" class="invalid-feedback">
          <div>
            Break Inspection Report Date is required 
          </div>
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="breakInspectionReportTimeHH">Inspection Time</label>
      <div class="time-input">
        <div class="time-select">
          <select
            class="form-select"
            formControlName="breakInspectionReportTimeHH"
            [ngClass]="{ 'is-invalid': submitted && f.breakInspectionReportTimeHH.errors }"
          >
            <option value="" disabled selected>HH</option>
            <option *ngFor="let hour of hours" [ngValue]="hour">
              {{ hour }}
            </option>
          </select>
          <div *ngIf="submitted && f.breakInspectionReportTimeHH.errors" class="invalid-feedback">
            <div>Break Inspection Report Time is required</div>
          </div>
        </div>
        <span class="time-separator">:</span>
        <div class="time-select">
          <select
            class="form-select"
            formControlName="breakInspectionReportTimeMM"
            [ngClass]="{ 'is-invalid': submitted && f.breakInspectionReportTimeMM.errors }"
          >
            <option value="" disabled selected>MM</option>
            <option *ngFor="let minute of minutes" [ngValue]="minute">
              {{ minute }}
            </option>
          </select>
          <div *ngIf="submitted && f.breakInspectionReportTimeMM.errors" class="invalid-feedback">
            <div>Break Inspection Report Time is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="breakInspectionReportFile">Break Inspection File Upload</label>
      <input
        type="file"
        (change)="onBreakInspectionReportFileSelected($event)"
        formControlName="breakInspectionReportFile"
        accept="image/*,application/pdf"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.breakInspectionReportFile.errors }"
      />
      <div *ngIf="submitted && f.breakInspectionReportFile.errors" class="invalid-feedback">
        <div *ngIf="f.breakInspectionReportFile.errors.required">
          Break Inspection File is required
        </div>
      </div>
    </div>

  </div>

  <div class="form-row">
    <div class="form-group col" *ngIf="vehicleAge <= 5">
      <label for="nilDep">Nil Depreciation</label>
      <div class="row">
        <div class="col">
          <input
            type="radio"
            formControlName="nilDep"
            id="nilDep"
            [value]="'Yes'"
            [ngClass]="{ 'is-invalid': submitted && f.nilDep.errors }" 
          />
          &nbsp;&nbsp;<span>Yes</span>
        </div>
        <div class="col">
          <input
            type="radio"
            formControlName="nilDep"
            id="nilDep"
            [value]="'No'"
            [ngClass]="{ 'is-invalid': submitted && f.nilDep.errors }"
          />
          &nbsp;&nbsp;<span>No</span>
        </div>
      </div>
      <div *ngIf="submitted && f.nilDep.errors" class="invalid-feedback">
        <div *ngIf="f.nilDep.errors.required">Nil Depreciation is required</div>
      </div>
    </div>
        <div class="form-group col" *ngIf="vehicleAge <= 5">
            <label for="rtiCover">Return To Invoice Cover</label>
            <div class="row">
              <div class="col">
                <input
                  type="radio"
                  formControlName="rtiCover"
                  id="rtiCover"
                  [value]="'Yes'"
                  [ngClass]="{ 'is-invalid': submitted && f.rtiCover.errors }" 
                />
                &nbsp;&nbsp;<span>Yes</span>
              </div>
              <div class="col">
                <input
                  type="radio"
                  formControlName="rtiCover"
                  id="rtiCover"
                  [value]="'No'"
                  [ngClass]="{ 'is-invalid': submitted && f.rtiCover.errors }"
                />
                &nbsp;&nbsp;<span>No</span>
              </div>
            </div>
            <div *ngIf="submitted && f.rtiCover.errors" class="invalid-feedback">
              <div *ngIf="f.rtiCover.errors.required">RTI Cover is required</div>
            </div>
      
    </div>
    <div class="form-group col" *ngIf="selectedPackage && selectedPackage.tpApplicable">
        <!-- <label for="tpPdCover">Third Party Property Damage Cover(TPPD cover)</label>
        <div class="row">
          <div class="col">
            <input
              type="radio"
              formControlName="tpPdCover"
              id="tpPdCover"
              [value]="'Yes'"
              [ngClass]="{ 'is-invalid': submitted && f.tpPdCover.errors }" 
            />
            &nbsp;&nbsp;<span>Yes</span>
          </div>
          <div class="col">
            <input
              type="radio"
              formControlName="tpPdCover"
              id="tpPdCover"
              [value]="'No'"
              [ngClass]="{ 'is-invalid': submitted && f.tpPdCover.errors }"
            />
            &nbsp;&nbsp;<span>No</span>
          </div>
        </div>
        <div *ngIf="submitted && f.tpPdCover.errors" class="invalid-feedback">
          <div *ngIf="f.tpPdCover.errors.required">Tppd Cover is required</div>
        </div> -->
  
</div>
<div class="form-group col"></div>
</div>

  <div class="form-row">
    <div class="form-group col">
      <label for="startDate">Risk Start Date</label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="startDate"
          placeholder="DD/MM/YYYY"
          [minDate]="startDateModel"
          ngbDatepicker
          #d1="ngbDatepicker"
          [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary fa fa-calendar"
            (click)="d1.toggle()"
            type="button"
          ></button>
        </div>
      </div>
      <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
        <div *ngIf="f.startDate.errors.required">Start Date is required</div>
      </div>
    </div>
    <div class="form-group col" *ngIf="f.type.value == 'Old'">
      <label for="transferCase">Transfer Case?</label>
      <div class="row">
        <div class="col">
          <input
            type="radio"
            formControlName="transferCase"
            [value]="'Yes'"
            [ngClass]="{ 'is-invalid': submitted && f.transferCase.errors }"
          />
          &nbsp;&nbsp;<span>Yes</span>
        </div>
        <div class="col">
          <input
            type="radio"
            formControlName="transferCase"
            [value]="'No'"
            [ngClass]="{ 'is-invalid': submitted && f.transferCase.errors }"
          />
          &nbsp;&nbsp;<span>No</span>
        </div>
      </div>
      <div *ngIf="submitted && f.transferCase.errors" class="invalid-feedback">
        <div *ngIf="f.transferCase.errors.required">
          Transfer Case is required
        </div>
      </div>
    </div>

    <div class="form-group col" *ngIf="f.type.value != 'Old'"></div>
    <div class="form-group col" *ngIf="f.type.value != 'Old'"></div>
    <div
      class="form-group col"
      *ngIf="
        f.type.value == 'Old' &&
        selectPolicyExpiryClaim.includes(f.prevPolicyExpire.value)
      "
    >
      <label for="prePolClaim">Previous Policy Claim</label>
      <div class="row">
        <div class="col">
          <input
            type="radio"
            formControlName="prePolClaim"
            [value]="'Yes'"
            [ngClass]="{ 'is-invalid': submitted && f.prePolClaim.errors }"
          />
          &nbsp;&nbsp;<span>Yes</span>
        </div>
        <div class="col">
          <input
            type="radio"
            formControlName="prePolClaim"
            [value]="'No'"
            [ngClass]="{ 'is-invalid': submitted && f.prePolClaim.errors }"
          />
          &nbsp;&nbsp;<span>No</span>
        </div>
      </div>
      <div *ngIf="submitted && f.prePolClaim.errors" class="invalid-feedback">
        <div *ngIf="f.prePolClaim.errors.required">
          Previous Policy Claim is required
        </div>
      </div>
    </div>
    <div class="form-group col" *ngIf="f.prePolClaim.value == 'Yes'">
      <label for="ncbPercentValue">Previous Claim Year</label>
      <select
        class="form-select"
        formControlName="ncbPercentValue"
        [ngClass]="{ 'is-invalid': submitted && f.ncbPercentValue.errors }"
      >
        <option
          *ngFor="let claimBonusYear of selectedNoClaimBonusYear"
          [ngValue]="claimBonusYear.ncbPercentage"
        >
          {{ claimBonusYear.ncbYear }}
        </option>
      </select>
    </div>

    <div class="form-group col" *ngIf="f.type.value != 'Old'"></div>
  </div>

  <div
    class="form-row"
    *ngIf="
      selectedPackage &&
      selectedPackage.prevInsuranceDetailShow &&
      selectedPackage.prevInsuranceDetailShow.includes('GetODDetails')
    "
  >
    <div class="form-group col">
      <label for="prevOdInsurer">Previous OD Insurer</label>
      <select
        class="form-select"
        formControlName="prevOdInsurer"
        [ngClass]="{ 'is-invalid': submitted && f.prevOdInsurer.errors }"
      >
        <option *ngFor="let company of insuranceCompany" [ngValue]="company">
          {{ company }}
        </option>
      </select>
      <div *ngIf="submitted && f.prevOdInsurer.errors" class="invalid-feedback">
        <div *ngIf="f.prevOdInsurer.errors.required">
          Previous OD Insurer is required
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="prevOdPolicyNo">Previous OD Policy Number</label>
      <input
        type="text"
        formControlName="prevOdPolicyNo"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.prevOdPolicyNo.errors }"
      />
      <div
        *ngIf="submitted && f.prevOdPolicyNo.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.prevOdPolicyNo.errors.required">
          Previous OD policy number is required
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="prevOdPolicyExpiry">Previous OD Policy Expiry Date</label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="prevOdPolicyExpiry"
          placeholder="DD/MM/YYYY"
          [minDate]="minOdDateModel"
          [maxDate]="maxOdDateModel"
          ngbDatepicker
          #d2="ngbDatepicker"
          [ngClass]="{ 'is-invalid': submitted && f.prevOdPolicyExpiry.errors }"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary fa fa-calendar"
            (click)="d2.toggle()"
            type="button"
          ></button>
        </div>
      </div>
      <div
        *ngIf="submitted && f.prevOdPolicyExpiry.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.prevOdPolicyExpiry.errors.required">
          Previous OD Policy Expiry Date is required
        </div>
      </div>
    </div>
  </div>
  <div
    class="form-row"
    *ngIf="
      selectedPackage &&
      selectedPackage.prevInsuranceDetailShow &&
      selectedPackage.prevInsuranceDetailShow.includes('GetTPDetails')
    "
  >
    <div class="form-group col">
      <label for="prevTpInsurer">Previous TP Insurer</label>
      <select
        class="form-select"
        formControlName="prevTpInsurer"
        [ngClass]="{ 'is-invalid': submitted && f.prevTpInsurer.errors }"
      >
        <option *ngFor="let company of insuranceCompany" [ngValue]="company">
          {{ company }}
        </option>
      </select>
      <div *ngIf="submitted && f.prevTpInsurer.errors" class="invalid-feedback">
        <div *ngIf="f.prevTpInsurer.errors.required">
          Previous TP Insurer is required
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="prevTpPolicyNo">Previous TP Policy Number</label>
      <input
        type="text"
        formControlName="prevTpPolicyNo"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.prevTpPolicyNo.errors }"
      />
      <div
        *ngIf="submitted && f.prevTpPolicyNo.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.prevTpPolicyNo.errors.required">
          Previous TP policy number is required
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label for="prevTpPolicyExpiry">Previous TP Policy Expiry Date</label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="prevTpPolicyExpiry"
          placeholder="DD/MM/YYYY"
          [minDate]="startDateModel"
          ngbDatepicker
          #d3="ngbDatepicker"
          [ngClass]="{ 'is-invalid': submitted && f.prevTpPolicyExpiry.errors }"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary fa fa-calendar"
            (click)="d3.toggle()"
            type="button"
          ></button>
        </div>
      </div>
      <div
        *ngIf="submitted && f.prevTpPolicyExpiry.errors"
        class="invalid-feedback"
      >
        <div *ngIf="f.prevTpPolicyExpiry.errors.required">
          Previous TP Policy Expiry Date is required
        </div>
      </div>
    </div>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb" >
      <li
        class="breadcrumb-item active"
        aria-current="page"
      >
      <div class="breadcrumb-content">
        <i class="fa fa-user breadcrumb-icon"></i>
        <p class="h5 breadcrumb-text">KYC Details</p>
        </div>
      </li>
    </ol>
  </nav>
  <div class="form-row">
    <div class="form-group col">
      <label for="aadhaarNumber" *ngIf="!isCorporate">Aadhaar Number</label>
      <label for="aadhaarNumber" *ngIf="isCorporate">GST Number</label>
      <input
        type="text"
        formControlName="aadhaarNumber"
        class="form-control"
        [ngClass]="{
          'is-invalid':
            (f.aadhaarNumber.touched || submitted) && f.aadhaarNumber.errors
        }"
      />
      <div
        *ngIf="f.aadhaarNumber.errors && !isCorporate"
        class="invalid-feedback"
      >
        <div *ngIf="f.aadhaarNumber.errors.required">
          Aadhaar Number is required
        </div>
        <div *ngIf="f.aadhaarNumber.errors.pattern">
          Aadhaar Number is invalid
        </div>
      </div>
      <div
        *ngIf="f.aadhaarNumber.errors && isCorporate"
        class="invalid-feedback"
      >
        <div *ngIf="f.aadhaarNumber.errors.required">
          GST Number is required
        </div>
        <div *ngIf="f.aadhaarNumber.errors.pattern">GST Number is invalid</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="aadhaarFile" *ngIf="!isCorporate">Aadhaar Upload</label>
      <label for="aadhaarFile" *ngIf="isCorporate">GST Upload</label>
      <input
        type="file"
        (change)="onAadhaarFileSelected($event)"
        formControlName="aadhaarFile"
        accept="image/*,application/pdf"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.aadhaarFile.errors }"
      />
      <div *ngIf="submitted && f.aadhaarFile.errors" class="invalid-feedback">
        <div *ngIf="f.aadhaarFile.errors.required && !isCorporate">
          Aadhaar File is required
        </div>
        <div *ngIf="f.aadhaarFile.errors.required && isCorporate">
          GST File is required
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="panNumber">PAN Number</label>
      <input
        type="text"
        formControlName="panNumber"
        class="form-control"
        [ngClass]="{
          'is-invalid': (f.panNumber.touched || submitted) && f.panNumber.errors
        }"
      />
      <div *ngIf="f.panNumber.errors" class="invalid-feedback">
        <div *ngIf="f.panNumber.errors.required">PAN Number is required</div>
        <div *ngIf="f.panNumber.errors.pattern">PAN Number is invalid</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="panFile">PAN Upload</label>
      <input
        type="file"
        (change)="onPanFileSelected($event)"
        formControlName="panFile"
        accept="image/*"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.panFile.errors }"
      />
      <div *ngIf="submitted && f.panFile.errors" class="invalid-feedback">
        <div *ngIf="f.panFile.errors.required">PAN File is required</div>
      </div>
    </div>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb" >
      <li
        class="breadcrumb-item active"
        aria-current="page"
      >
      <div class="breadcrumb-content">
        <i class="fa fa-user breadcrumb-icon"></i>
        <p class="h5  breadcrumb-text" *ngIf="!isCorporate">
          Customer Details
        </p>
        <p class="h5  breadcrumb-text" *ngIf="isCorporate">
          Corporate Details
        </p>
        </div>
      </li>
    </ol>
  </nav>
  <div class="form-row">
    <div class="form-group col" *ngIf="!isCorporate">
      <label for="title">Title</label>
      <select
        class="form-select"
        formControlName="title"
        [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
      >
        <option *ngFor="let title of titles" [ngValue]="title">
          {{ title }}
        </option>
      </select>
      <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
        <div *ngIf="f.title.errors.required">Title needs to be selected</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="firstName" *ngIf="!isCorporate">First Name</label>
      <label for="firstName" *ngIf="isCorporate">Organisation Name</label>
      <input
        type="text"
        formControlName="firstName"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
      />
      <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
        <div *ngIf="f.firstName.errors.required">Name is required</div>
      </div>
    </div>
    <!-- <div class="form-group col">
            <label for="middleName">Middle Name</label>
            <input type="text" formControlName="middleName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.middleName.errors }" />
            <div *ngIf="submitted && f.middleName.errors" class="invalid-feedback">
                <div *ngIf="f.middleName.errors.required">Middle Name is required</div>
            </div>
        </div> -->
    <div class="form-group col" *ngIf="!isCorporate">
      <label for="lastName">Last Name</label>
      <input
        type="text"
        formControlName="lastName"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
      />
      <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
      </div>
    </div>
    <div class="form-group col" *ngIf="isCorporate"></div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="email" *ngIf="!isCorporate">Email</label>
      <label for="email" *ngIf="isCorporate">Organisation Email</label>
      <input
        type="text"
        formControlName="email"
        class="form-control"
        [ngClass]="{
          'is-invalid': (f.email.touched || submitted) && f.email.errors
        }"
      />
      <div *ngIf="f.email.errors" class="invalid-feedback">
        <div *ngIf="f.email.errors.required">Email is required</div>
        <div *ngIf="f.email.errors.email">Email id is invalid</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="contactNumber"> Contact Number </label>
      <input
        type="number"
        formControlName="contactNumber"
        class="form-control"
        maxlength="10"
        [ngClass]="{
          'is-invalid':
            (f.contactNumber.touched || submitted) && f.contactNumber.errors
        }"
      />
      <div *ngIf="f.contactNumber.errors" class="invalid-feedback">
        <div *ngIf="f.contactNumber.errors.required">
          Contact Number is required
        </div>
        <div *ngIf="f.contactNumber.errors.pattern">
          Invalid contact number. Please enter 10 digit proper mobile number
        </div>
      </div>
    </div>
  </div>
  <div class="form-row" *ngIf="!isCorporate">
    <div class="form-group col">
      <label for="gender">Gender</label>
      <select
        class="form-select"
        formControlName="gender"
        [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"
      >
        <option *ngFor="let gender of genders" [ngValue]="gender">
          {{ gender }}
        </option>
      </select>
      <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
        <div *ngIf="f.gender.errors.required">Gender is required</div>
      </div>
    </div>
    <!-- <div class="form-group col" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('canSeeAge')">
            <label for="ageOrDob">Age or Date of Birth</label>
            <div class="row">
                <div class="col">
                    <input type="radio" formControlName="ageOrDob" [value]="ageOrDobValue[1]"  [ngClass]="{ 'is-invalid': submitted && f.ageOrDob.errors }" />
                    &nbsp;&nbsp;<span>Age</span>
                </div>
                <div class="col">
                    <input type="radio" formControlName="ageOrDob"  [value]="ageOrDobValue[0]"  [ngClass]="{ 'is-invalid': submitted && f.ageOrDob.errors }" />
                    &nbsp;&nbsp;<span>Date of Birth</span>
            </div>
        </div>             -->
    <!-- </div> -->

    <div class="form-group col" *ngIf="f.ageOrDob.value == ageOrDobValue[1]">
      <label for="age">Age</label>
      <div class="input-group">
        <input
          type="number"
          formControlName="age"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.age.errors }"
        />
        <div *ngIf="submitted && f.age.errors" class="invalid-feedback">
          <div *ngIf="f.age.errors.required">Age is required</div>
          <div *ngIf="f.age.errors.max">Maximum age allowed is 80</div>
          <div *ngIf="f.age.errors.min">Minimum age allowed is 15</div>
        </div>
      </div>
    </div>
    <div class="form-group col" *ngIf="f.ageOrDob.value == ageOrDobValue[0]">
      <label for="dob">Date of Birth</label>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="dob"
          [minDate]="minDobDateModel"
          [maxDate]="maxDobDateModel"
          placeholder="DD/MM/YYYY"
          ngbDatepicker
          #d="ngbDatepicker"
          [ngClass]="{ 'is-invalid': submitted && f.dob.errors }"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary fa fa-calendar"
            (click)="d.toggle()"
            type="button"
          ></button>
        </div>
        <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
          <div>
            Date of birth is required and age should be between 15 to 80 years
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="form-group col">
            <label for="gstin">
                GSTIN
            </label>
            <input type="text" formControlName="gstin" maxlength="15" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.gstin.errors }" />
            <div *ngIf="submitted && f.gstin.errors" class="invalid-feedback">
                <div *ngIf="f.gstin.errors.required">Gstin is required</div>
            </div>
        </div> -->
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="address" *ngIf="!isCorporate">Address</label>
      <label for="address" *ngIf="isCorporate">Organisation Address</label>
      <input
        type="text"
        formControlName="address"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
      />
      <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
        <div *ngIf="f.address.errors.required">Address is required</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="state">State</label>
      <select
        class="form-select"
        formControlName="state"
        [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
      >
        <option *ngFor="let state of stateCity" [ngValue]="state.state">
          {{ state.state }}
        </option>
      </select>
      <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
        <div *ngIf="f.state.errors.required">State needs to be selected</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="city">City</label>
      <select
        class="form-select"
        formControlName="city"
        [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
      >
        <option *ngFor="let city of cities" [ngValue]="city">
          {{ city }}
        </option>
      </select>
      <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
        <div *ngIf="f.city.errors.required">City is required</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="pincode">Pincode</label>
      <input
        type="number"
        formControlName="pincode"
        class="form-control"
        [ngClass]="{
          'is-invalid': (f.pincode.touched || submitted) && f.pincode.errors
        }"
      />
      <div *ngIf="f.pincode.errors" class="invalid-feedback">
        <div *ngIf="f.pincode.errors.required">Pincode is required</div>
        <div *ngIf="f.pincode.errors.pattern">Pincode is invalid</div>
      </div>
    </div>
  </div>
  <div>
    <nav aria-label="breadcrumb" *ngIf="!isCorporate">
      <ol class="breadcrumb" >
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
        <div class="breadcrumb-content">
          <i class="fa fa-user breadcrumb-icon" style="margin: 5px"></i>
          <p class="h5  breadcrumb-text" style="margin-left: 5px" *ngIf="!isCorporate">
            Nominee Details
          </p>
          <p class="h5  breadcrumb-text" style="margin-left: 5px" *ngIf="isCorporate">
            Contact Person Details
          </p>
          </div>
        </li>
      </ol>
    </nav>
    <div class="form-row">
      <div class="form-group col">
        <label for="nomineeName" *ngIf="!isCorporate">Nominee Name</label>
        <label for="nomineeName" *ngIf="isCorporate">Name</label>
        <input
          type="text"
          formControlName="nomineeName"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.nomineeName.errors }"
        />
        <div *ngIf="submitted && f.nomineeName.errors" class="invalid-feedback">
          <div *ngIf="f.nomineeName.errors.required">Name is required</div>
        </div>
      </div>
      <div class="form-group col">
        <label for="nomineeGender" *ngIf="!isCorporate">Nominee Gender</label>
        <label for="nomineeGender" *ngIf="isCorporate">Gender</label>
        <select
          class="form-select"
          formControlName="nomineeGender"
          [ngClass]="{ 'is-invalid': submitted && f.nomineeGender.errors }"
        >
          <option
            *ngFor="let nomineeGender of genders"
            [ngValue]="nomineeGender"
          >
            {{ nomineeGender }}
          </option>
        </select>
        <div
          *ngIf="submitted && f.nomineeGender.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.nomineeGender.errors.required">Gender is required</div>
        </div>
      </div>
      <div class="form-group col" *ngIf="!isCorporate">
        <label for="nomineeRelationship">Nominee Relationship</label>
        <select
          class="form-select"
          formControlName="nomineeRelationship"
          [ngClass]="{
            'is-invalid': submitted && f.nomineeRelationship.errors
          }"
        >
          <option
            *ngFor="let nomineeRelationship of relationships"
            [ngValue]="nomineeRelationship"
          >
            {{ nomineeRelationship }}
          </option>
        </select>
        <div
          *ngIf="submitted && f.nomineeRelationship.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.nomineeRelationship.errors.required">
            Nominee Relationship is required
          </div>
        </div>
      </div>
      <div class="form-group col">
        <label for="nomineeAge" *ngIf="!isCorporate">Nominee Age</label>
        <label for="nomineeAge" *ngIf="isCorporate"> Age</label>
        <input
          type="number"
          formControlName="nomineeAge"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.nomineeAge.errors }"
        />
        <div *ngIf="submitted && f.nomineeAge.errors" class="invalid-feedback">
          <div *ngIf="f.nomineeAge.errors.required">Age is required</div>
          <div *ngIf="f.nomineeAge.errors.max">Maximum age allowed is 100</div>
          <div *ngIf="f.nomineeAge.errors.min">Minimum age allowed is 1</div>
        </div>
      </div>
      <div class="form-group col" *ngIf="isCorporate">
        <label for="employeeContactNumber">Contact Number</label>
        <input
          type="number"
          formControlName="employeeContactNumber"
          class="form-control"
          maxlength="10"
          [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }"
        />
        <div
          *ngIf="submitted && f.employeeContactNumber.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.employeeContactNumber.errors.required">
            Contact Number is required
          </div>
          <div *ngIf="f.employeeContactNumber.errors.minlength">
            Contact Number must be at least 6 characters
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="certificateCreated">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb" style="background-color: rgb(217, 154, 236)">
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
        <div class="breadcrumb-content">
          <i class="fa fa-book breadcrumb-icon" ></i>
          <p class="h5  breadcrumb-text">Policy Details</p>
          </div>
        </li>
      </ol>
    </nav>
    <div class="form-row" *ngIf="certificateCreated">
      <div class="form-group col">
        <label for="certificateNumber">Certificate</label>
        <input
          type="text"
          formControlName="certificateNumber"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.certificateNumber.errors }"
          disabled
        />
      </div>
      <div class="form-group col">
        <label for="policyNumber">Policy Number</label>
        <input
          type="text"
          formControlName="policyNumber"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.policyNumber.errors }"
          disabled
        />
      </div>
    </div>
  </div>
  <div class="form-group">
    <button [disabled]="loading || isBalanceLow" class="btn btn-primary">
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      Save</button
    ><a>&nbsp;&nbsp;</a>
    <button class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
      New Form
    </button>
    <a>&nbsp;&nbsp;</a>
    <a routerLink="/issueCertificate" class="btn btn-link">Cancel</a>
  </div>
</form>
