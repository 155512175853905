import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { AccountService } from '@app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/_models';

@Component({ selector: 'app-user-list',
templateUrl: 'list.component.html' })
export class ListComponent implements OnInit {
    users = null;
    id:string;
    user:User;
    isUserShow = false;

    constructor(private accountService: AccountService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        this.user = this.accountService.userValue;
        if (this.user.role == 'admin')
            this.accountService.getByDealerId(this.id)
                .pipe(first())
                .subscribe(users => this.users = users);
        else
        this.accountService.getByDealerId(this.user.dealerId)
        .pipe(first())
        .subscribe(users => this.users = users.filter(x => (x.role == "executive") || (x.role == "manager")));

        if (this.user.role=="manager")
        {
            this.isUserShow = true;
        }
    
    }

    deleteUser(id: string) {
        const user = this.users.find(x => x.id === id);
        user.isDeleting = true;
        this.accountService.delete(id)
            .pipe(first())
            .subscribe(() => this.users = this.users.filter(x => x.id !== id));
    }
    userShowToggle(){
        this.isUserShow = !this.isUserShow;
    }
}