import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/_models';
import { Certificate } from '@app/_models/certificate';
import { Dealer } from '@app/_models/dealer';
import { AccountService, AlertService } from '@app/_services';
import { DealerService } from '@app/_services/dealer-service';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/internal/operators/first';
import {ReportSearch} from '../../_models/reportSearch'
import {SearchService} from '../../_services/search.service'


@Component({
  selector: 'app-certificate-report',
  templateUrl: './certificate-report.component.html',
  styleUrls: ['./certificate-report.component.less']
})
export class CertificateReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  private gridApi;
  certificateReportForm: UntypedFormGroup;
  id: string;
  submitted=false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  gridOptions: GridOptions;
  loading= false;
  gridReady = true;
  reportSearch: ReportSearch;
  issueDateFromSearch : string;
  issueDateToSearch : string;
  reportCertificateData : any[];
  user: User;
  dealer:Dealer;

  

  constructor(  
    private route: ActivatedRoute,      
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService, 
    private alertService: AlertService,
    private accountService: AccountService,
    private dealerService: DealerService
    ) { }

  ngOnInit()  {
    this.user = this.accountService.userValue;
    this.id = this.route.snapshot.params['id'];
    this.gridOptions = <GridOptions>{}; 
    this.certificateReportForm = this.formBuilder.group({
      packageNumber: [''],
      firstName: [''],
      lastName: [''],
      userName: [''],
      issueDateFrom: [''],
      issueDateTo: [''],
      dealerId: [''],
      dealerGroupName:[''],
          });
      
    this.gridOptions.defaultColDef = {
      resizable: true,
      
    
    }

    if(this.id == "NewIndiaPolicy") {
      // this.gridOptions.columnDefs = [
      //   {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
      //   ,{headerName: "VehicleMake", field: "vehicleMake",sortable: true, filter:true }
      //   ,{headerName: "Model", field: "model",sortable: true, filter:true }
      //   ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
      //   ,{headerName: "Vehicle Year", field: "vehicleYear",sortable: true, filter:true }
      //   ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
      //   ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
      //   ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
      //   ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
      //   ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
      //   ,{headerName: "Customer DOB", field: "dob",sortable: true, filter:true }
      //   ,{headerName: "Customer Age", field: "age",sortable: true, filter:true }
      //   ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
      //   ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
      //   ,{headerName: "Nominee Age", field: "nomineeAge",sortable: true, filter:true }        
      //   ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
      //   ,{headerName: "End Date", field: "endDate", sortable: true, filter:true }
      //   ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
      //   ,{headerName: "Created Date", field: "createdDateString",sortable: true, filter:true }
      //   ,{headerName: "Policy Type", field: "policyType",sortable: true, filter:true }
      //   ,{headerName: "Master Policy Number", field: "policyNumber",sortable: true, filter:true }
      //   ,{headerName: "Policy Cover Amount", field: "planCPACoverAmount",sortable: true, filter:true }
      // ]
  } else {

    if ((this.user.role == "executive") || (this.user.role =="manager"))
      this.gridOptions.columnDefs = [
        {headerName: "Package Number", field: "packageNumber",sortable: true, filter:true }
        ,{headerName: "Policy Number", field: "insurancePolicyNumber",sortable: true, filter:true }
        ,{headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
        ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
        ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
        ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
        ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
        ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
        ,{headerName: "Model", field: "model",sortable: true, filter:true }
        ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
        ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
        ,{headerName: "Total Amount", field: "packagePolicyTotalAmount",sortable: true, filter:true }
        ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
        ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
        ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
        ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
        ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
        ,{headerName: "Executive Username", field: "userName",sortable: true, filter:true }
        ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
        ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
        ,{headerName: "Created Date and Time", field: "createdDateString",sortable: true, filter:true }
      ]
    else {
      if (this.user.role == "dealerOwner")
      {
        this.gridOptions.columnDefs = [
          {headerName: "Package Number", field: "packageNumber",sortable: true, filter:true }
          ,{headerName: "Policy Number", field: "insurancePolicyNumber",sortable: true, filter:true }
          ,{headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
            ,{headerName: "Executive Name", field: "userName",sortable: true, filter:true }
          ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
          ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
          ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
          ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
          ,{headerName: "Customer DOB", field: "dob",sortable: true, filter:true }
          ,{headerName: "Customer Age", field: "age",sortable: true, filter:true }
          ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
          ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
          ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
          ,{headerName: "Nominee Age", field: "nomineeAge",sortable: true, filter:true }        
          ,{headerName: "VehicleMake", field: "vehicleMake",sortable: true, filter:true }
          ,{headerName: "Model", field: "model",sortable: true, filter:true }
          ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
          ,{headerName: "Vehicle Year", field: "vehicleYear",sortable: true, filter:true }
          ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
          ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
          ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
          ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
          ,{headerName: "Dealer Plan Type", field: "dealerPlanType",sortable: true, filter:true }
          ,{headerName: "Total Paid Amount", field: "packagePolicyTotalAmount",sortable: true, filter:true }
          ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
          ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
          ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
          ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
          ,{headerName: "Dealer Balance", field: "dealerBalance",sortable: true, filter:true }
          ,{headerName: "Created Timestamp", field: "createdDateString",sortable: true, filter:true }
          ,{headerName: "Email", field: "email",sortable: true, filter:true }
        ]; 
      }
    else
    {
      if (this.user.role == "employee")
      this.gridOptions.columnDefs = [
        {headerName: "Package Number", field: "packageNumber",sortable: true, filter:true }
        ,{headerName: "Policy Number", field: "insurancePolicyNumber",sortable: true, filter:true }
        ,{headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
        // ,{headerName: "Policy Number", field: "policyNumber",sortable: true, filter:true }
        ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
        ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
        ,{headerName: "Dealer Balance", field: "dealerBalance",sortable: true, filter:true }
        ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
        ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
        ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
        ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
        ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
        ,{headerName: "Model", field: "model",sortable: true, filter:true }
        ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
        ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
        ,{headerName: "Total Amount", field: "packagePolicyTotalAmount",sortable: true, filter:true }
        ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
        ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
        ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
        ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
        ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
        ,{headerName: "Executive Username", field: "userName",sortable: true, filter:true }
        ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
        ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
        ,{headerName: "Created Date and Time", field: "createdDateString",sortable: true, filter:true }
      ]
      else
      if (this.user.specialRoleAccess.includes("ViewNetAmountGST"))
      {
        this.gridOptions.columnDefs = [
          {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
          ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
          ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
          ,{headerName: "Dealer Balance", field: "dealerBalance",sortable: true, filter:true }
          ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
          ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
          ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
          ,{headerName: "Customer DOB", field: "dob",sortable: true, filter:true }
          ,{headerName: "Customer Age", field: "age",sortable: true, filter:true }
          ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
          ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
          ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
          ,{headerName: "Nominee Age", field: "nomineeAge",sortable: true, filter:true }        
          ,{headerName: "VehicleMake", field: "vehicleMake",sortable: true, filter:true }
          ,{headerName: "Model", field: "model",sortable: true, filter:true }
          ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
          ,{headerName: "Vehicle Year", field: "vehicleYear",sortable: true, filter:true }
          ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
          ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
          ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
          ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
          ,{headerName: "Policy Type", field: "policyType",sortable: true, filter:true }
          ,{headerName: "Dealer Plan Type", field: "dealerPlanType",sortable: true, filter:true }
          ,{headerName: "Total Paid Amount", field: "packagePolicyTotalAmount",sortable: true, filter:true }
          ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
          ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
          ,{headerName: "Executive Name", field: "userName",sortable: true, filter:true }
          ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
          ,{headerName: "Created Date", field: "createdDateString",sortable: true, filter:true }
          ,{headerName: "Email", field: "email",sortable: true, filter:true }
        ];
  
      }
      else
      {
      this.gridOptions.columnDefs = [
        {headerName: "Package Number", field: "packageNumber",sortable: true, filter:true }
        ,{headerName: "Policy Number", field: "insurancePolicyNumber",sortable: true, filter:true }
        ,{headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
        ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
        ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
        ,{headerName: "Dealer Balance", field: "dealerBalance",sortable: true, filter:true }
        ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
        ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
        ,{headerName: "Aadhar Number", valueGetter: (params) =>{ return  params.data.aadhaarNumber.toString().substring(0,4) + " " + params.data.aadhaarNumber.toString().substring(4,8) + " " + params.data.aadhaarNumber.toString().substring(8,12)  },sortable: true, filter:true }
        ,{headerName: "Pan Number", field: "panNumber",sortable: true, filter:true }
        ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
        ,{headerName: "Address", field: "address",sortable: true, filter:true }
        ,{headerName: "Customer DOB", field: "dob",sortable: true, filter:true }
        ,{headerName: "Customer Age", field: "age",sortable: true, filter:true }
        ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
        ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
        ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
        ,{headerName: "Nominee Age", field: "nomineeAge",sortable: true, filter:true }        
        ,{headerName: "RTO", field: "rto",sortable: true, filter:true }
        ,{headerName: "VehicleMake", field: "vehicleMake",sortable: true, filter:true }
        ,{headerName: "Model", field: "model",sortable: true, filter:true }
        ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
        ,{headerName: "Vehicle Year", field: "vehicleYear",sortable: true, filter:true }
        ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
        ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
        ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
        ,{headerName: "Package Name", field: "package.packageName",sortable: true, filter:true }
        ,{headerName: "Insurance Company", field: "insuranceCompany",sortable: true, filter:true }
        ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
        ,{headerName: "Plan Type", field: "policyType",sortable: true, filter:true }
        ,{headerName: "Dealer Plan Type", field: "dealerPlanType",sortable: true, filter:true }
        ,{headerName: "Invoice Amount & Accessories", field: "idv",sortable: true, filter:true }
        ,{headerName: "Total Package Amount", field: "packagePolicyTotalAmount",sortable: true, filter:true }
        ,{headerName: "Package Amount", field: "packagePolicyAmount",sortable: true, filter:true }
        ,{headerName: "Package GST Amount", field: "packagePolicyGStAmount",sortable: true, filter:true }
        ,{headerName: "Total Policy Amount", field: "insurancePolicyTotalAmount",sortable: true, filter:true }
        ,{headerName: "Policy Amount", field: "insurancePolicyAmount",sortable: true, filter:true }
        ,{headerName: "Policy GST Amount", field: "insurancePolicyGStAmount",sortable: true, filter:true }
        ,{headerName: "Total Certificate Amount", field: "planTotalAmount",sortable: true, filter:true }
        ,{headerName: "Plan Amount", field: "planAmount",sortable: true, filter:true }
        ,{headerName: "plan GST Amount", field: "planGSTAmount",sortable: true, filter:true }
        // ,{headerName: "Eligible Amount", valueGetter: (params)=>{return (params.data.planAmount - params.data.NetPlanAmount).toFixed(2)}, sortable: true, filter:true }
        // ,{headerName: "Eligible GST Amount", valueGetter: (params)=>{return (params.data.planGSTAmount - params.data.NetPlanGSTAmount).toFixed(2)}, sortable: true, filter:true }
        // ,{headerName: "Total Eligible Amount", valueGetter: (params)=>{return (params.data.planAmount - params.data.NetPlanAmount + params.data.planGSTAmount - params.data.NetPlanGSTAmount).toFixed(2)}, sortable: true, filter:true }
        ,{headerName: "Product Cost", field: "NetPlanAmount",sortable: true, filter:true }
        ,{headerName: "Product Net GST amount", field: "NetPlanGSTAmount",sortable: true, filter:true }
        ,{headerName: "Product Total Amount", field: "NetPlanTotalAmount",sortable: true, filter:true }
        ,{headerName: "State GST", field: "SgstAmount",sortable: true, filter:true }
        ,{headerName: "Center GST", field: "CgstAmount",sortable: true, filter:true }
        ,{headerName: "Integrated GST", field: "IgstAmount",sortable: true, filter:true }
        ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
        ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
        ,{headerName: "Hypothecated", field: "hypothecated",sortable: true, filter:true }
        ,{headerName: "Fianance Company Name", field: "financeCompanyName",sortable: true, filter:true }
        ,{headerName: "Finance Company Address", field: "financeCompanyAddress",sortable: true, filter:true }
        ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
        ,{headerName: "Executive Name", field: "userName",sortable: true, filter:true }
        ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
        ,{headerName: "Created Date", field: "createdDateString",sortable: true, filter:true }
        ,{headerName: "Email", field: "email",sortable: true, filter:true }
        ,{headerName: "prePolClaim", field: "prePolClaim",sortable: true, filter:true }
        ,{headerName: "transferCase", field: "transferCase",sortable: true, filter:true }
        ,{headerName: "Previous Policy Expiry", field: "prevPolicyExpire",sortable: true, filter:true }
        ,{headerName: "nilDep", field: "nilDep",sortable: true, filter:true }
        ,{headerName: "Previous Od Insurer", field: "prevOdInsurer",sortable: true, filter:true }
        ,{headerName: "Previous Od PolicyNo", field: "prevOdPolicyNo",sortable: true, filter:true }
        ,{headerName: "Previous Od PolicyExpiry", field: "prevOdPolicyExpiry.year + '-' + prevOdPolicyExpiry.month + '-' + prevOdPolicyExpiry.day",sortable: true, filter:true }
        ,{headerName: "Previous Tp Insurer", field: "prevTpInsurer",sortable: true, filter:true }
        ,{headerName: "Previous Tp PolicyNo", field: "prevTpPolicyNo",sortable: true, filter:true }
        ,{headerName: "Previous Tp PolicyExpiry", field: "prevTpPolicyExpiry.year + '-' + prevTpPolicyExpiry.month + '-' + prevTpPolicyExpiry.day",sortable: true, filter:true }
        ,{headerName: "Pincode", field: "pincode",sortable: true, filter:true }
        ,{headerName: "Processed By", field: "userNameProcessed",sortable: true, filter:true }
      ];
    }
    }
    }
    if (this.user.specialRoleAccess && this.user.specialRoleAccess.includes('CanSeeSplitAmountInPackageReport'))
    {
      this.gridOptions.columnDefs.push({headerName: "Insurance Amount", field: "insurancePolicyTotalAmount",sortable: true, filter:true });
      this.gridOptions.columnDefs.push({headerName: "AAPL Amount", field: "planTotalAmount",sortable: true, filter:true });
      this.gridOptions.columnDefs.filter(x => x["field"] != "packagePolicyTotalAmount");
      this.gridOptions.columnDefs.push({headerName: "Total Amount", field: "packagePolicyTotalAmount",sortable: true, filter:true });
    }
  }
    // this.gridOptions.enableCharts = true;
    // this.gridOptions.enableRangeSelection = true;
    this.gridOptions.rowData = [];

    if (this.user.specialRoleAccess && this.user.specialRoleAccess.includes['CannotViewDealerBalance'])
    {
      this.gridApi.setColumnVisible('dealerBalance',false);
    };
      this.gridReady = true;
    
    this.certificateReportForm.get('issueDateFrom').valueChanges
    .subscribe(value => {
      if (value != undefined) {
        this.issueDateFromSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
      }
    });

    this.certificateReportForm.get('issueDateTo').valueChanges
    .subscribe(value => {
      if (value != undefined) {
        this.issueDateToSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
      }
      console.log("issueDateToSearch: " + this.issueDateToSearch)
    });

  }
      // convenience getter for easy access to form fields
      get f() { return this.certificateReportForm.controls; }

  dealerOwnerGridColumn(){
    this.dealerService.getByDealerId(this.user.dealerId).subscribe((y) => 
    {
        this.dealer = y;
        if (this.dealer.dealerInvoiceShow && this.dealer.dealerInvoiceShow == "No"){
          this.aggrid.api.setColumnDefs(
            [
              {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
              ,{headerName: "Executive Name", field: "userName",sortable: true, filter:true }
              ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
              ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
              ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
              ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
              ,{headerName: "Customer DOB", field: "dob",sortable: true, filter:true }
              ,{headerName: "Customer Age", field: "age",sortable: true, filter:true }
              ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
              ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
              ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
              ,{headerName: "Nominee Age", field: "nomineeAge",sortable: true, filter:true }        
              ,{headerName: "VehicleMake", field: "vehicleMake",sortable: true, filter:true }
              ,{headerName: "Model", field: "model",sortable: true, filter:true }
              ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
              ,{headerName: "Vehicle Year", field: "vehicleYear",sortable: true, filter:true }
              ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
              ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
              ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
              ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
              ,{headerName: "Dealer Plan Type", field: "dealerPlanType",sortable: true, filter:true }
              ,{headerName: "Plan Amount", field: "planAmount",sortable: true, filter:true }
              ,{headerName: "Eligible Amount", valueGetter: (params)=>{return (params.data.planAmount - params.data.NetPlanAmount).toFixed(2)}, sortable: true, filter:true }
              ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
              ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
              ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
              ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
              ,{headerName: "Dealer Balance", field: "dealerBalance",sortable: true, filter:true }
              ,{headerName: "Created Timestamp", field: "createdDateString",sortable: true, filter:true }
              ,{headerName: "Email", field: "email",sortable: true, filter:true }
            ]            
          )
        }
    })  

      }
  onSubmit(){
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    console.log("issueDateTo: " + this.issueDateFromSearch)

  //   if (this.certificateReportForm.invalid) {
  //     return;
  // }

    if (this.user.id == undefined)
    {
      this.alertService.error("User Credential Missing, Please login again and try");
      return
    }

    this.reportSearch = new ReportSearch();
    this.reportSearch.userId = this.user.id;
    
    if (this.issueDateFromSearch == undefined)
    {
      this.reportSearch.issueDateFrom = "0001-01-01";
    }
    else
    this.reportSearch.issueDateFrom = this.issueDateFromSearch;

    if (this.issueDateToSearch == undefined)
    {
      this.reportSearch.issueDateTo = "9999-99-99";
    }
    else
    this.reportSearch.issueDateTo = this.issueDateToSearch;


    this.reportSearch.certificateNumber = this.certificateReportForm.get('packageNumber').value;
    this.reportSearch.firstName = this.certificateReportForm.get('firstName').value;
    this.reportSearch.lastName = this.certificateReportForm.get('lastName').value;
    this.reportSearch.userName = this.certificateReportForm.get('userName').value;
    this.reportSearch.dealerId = this.certificateReportForm.get('dealerId').value;
    this.reportSearch.dealerGroupName = this.certificateReportForm.get('dealerGroupName').value;

    
    if ((this.user.role == "admin") || (this.user.role == "dealerOwner"))
    {
      this.searchService.getPackageReport(this.reportSearch)
      .pipe(first())
      .subscribe({
        next: (x) => {
          this.reportCertificateData = x.map(a => {
            a.createdDateString = new Date(a.createdDate).toString();
            if (this.id == "NewIndiaPolicy") {
              a.endDate = new Date(a.endDate).getFullYear() + "-" + ('0' + (new Date(a.endDate).getMonth() + 1)).slice(-2)  
              + "-" + ('0' + (new Date(a.endDate).getDate())).slice(-2) + " MIDNIGHT";
            }
            return a;
          });

          if (this.id == "NewIndiaPolicy")
          {
            this.reportCertificateData = this.reportCertificateData.filter(x => x.policyNumber != "NA" );
          }

          // this.gridOptions.rowData = x;
          this.aggrid.api.setRowData(this.reportCertificateData);
          if (this.user.specialRoleAccess && this.user.specialRoleAccess.includes('CannotViewDealerBalance')){
          this.gridApi.setColumnVisible('dealerBalance',false);
        }
            // console.log("x: " + JSON.stringify(x))
          this.gridReady = true;
          this.loading = false;
        },
        error: error => {
          this.alertService.error(error)
          this.loading = false;
        }
      })  
    }
    else {
    this.searchService.getPackageReport(this.reportSearch)
    .pipe(first())
    .subscribe({
      next: (x) => {
        //this.reportCertificateData = x;
        this.reportCertificateData = x.map(a => {
          a.createdDateString = new Date(a.createdDate).toString();
          return a;
        });
        // this.gridOptions.rowData = x;
        this.aggrid.api.setRowData(this.reportCertificateData);
        if (this.user.specialRoleAccess && this.user.specialRoleAccess.includes('CannotViewDealerBalance')){
          this.gridApi.columnApi.setColumnVisible('dealerBalance',false);
        }
        this.gridReady = true;
        this.loading = false;
      },
      error: error => {
        this.alertService.error(error)
        this.loading = false;
      }
    });
  }
    return;
  }
  onReset(){

  }
  exportToExcel(){
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'excelReport',
 //     columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.aggrid.api.exportDataAsCsv(params);
    // this.aggrid.api.exportDataAsExcel(params);
  }
}
