import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { PrintInstruction } from '@app/_models/printInstruction';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { SearchService } from '@app/_services/search.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dealer-aggregated-report',
  templateUrl: './dealer-aggregated-report.component.html',
  styleUrls: ['./dealer-aggregated-report.component.less']
})
export class DealerAggregatedReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  private gridApi;
  certificateReportForm: UntypedFormGroup;
  submitted = false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  gridOptions: GridOptions;
  loading = false;
  gridReady = true;
  booleanArray = ["","Yes","No"];
  frequencyArray = ["","Monthly","Daily"];
  reportSearch: ReportSearch;
  selectedPrintInstruction:String[];
  issueDateFromSearch: string;
  issueDateToSearch: string;
  reportCertificateData: any[];
  OutputArray = [];
  currentDate = new Date();
  stateRegexp : RegExp;
  idRegexp: RegExp;
  user: User;
  types: string[] = ["New", "Renewal", "All"];
  OutputArrayNew: {}[];
  OutputArrayOld: {}[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private alertService: AlertService,
    private accountService: AccountService,
    private dealerService: DealerService,
    private configDataService: ConfigDataService

  ) { }

  ngOnInit(): void {
    this.user = this.accountService.userValue;
    this.gridOptions = <GridOptions>{};
    this.certificateReportForm = this.formBuilder.group({
      issueDateFrom: [''],
      issueDateTo: [''],
      aggregationType: [''],
      dealerGroupName: [''],
      dealerState: [''],
      type: [''],
      dealerId: [''],
      hasPolicy: [''],
      frequency: [''],
      cpaPolicyCompany:['']
    });
    //filter aggrid data on type change
    this.certificateReportForm.get('type').valueChanges.subscribe(value => {
      if (this.gridReady == false) return;
      if (value != undefined) {
        if (value !='' && value != 'All')
          {
            value = value == 'Renewal' ? 'Old' : 'New';
          //check grid is ready
          console.log("OutputArray: " + JSON.stringify(this.OutputArray));
          this.aggrid.api.setRowData(this.OutputArray.filter(a => a.type == value));
        }
        else
        {
          this.aggrid.api.setRowData(this.OutputArray);
        }
      }
    });
    this.configDataService.PrintInstruction$.subscribe((x) => {
      this.selectedPrintInstruction = x.filter(a => a.printOnlyFirstPage == true).map(x => x.policyType);
    });

    this.gridOptions.columnDefs = [
      { headerName: "Dealer Id", field: "dealerId", sortable: true, filter: true }
      , { headerName: "Dealer Name", field: "dealerName", sortable: true, filter: true }
      , { headerName: "Dealer City", field: "dealerCity", sortable: true, filter: true }
      , { headerName: "Plan Type", field: "dealerPlanType", sortable: true, filter: true }
      , { headerName: "Issue Date", field: "issueDate", sortable: true, filter: true }
      , { headerName: "Type", field: "type", sortable: true, filter: true, valueFormatter: function (params) { return params.value == "New" ? "New" : params.value == "Old" ?"Renewal":params.value }}
      , { headerName: "Total", field: "totalPolicyNumber", sortable: true, filter: true }
    ];
    this.configDataService.PackageInsuranceCompanies.map(a => {
      this.gridOptions.columnDefs.push({ headerName: a.name, field: a.key, sortable: true, filter: true })
    });
    this.gridOptions.rowData = [];
    this.gridReady = true;

    this.certificateReportForm.get('issueDateFrom').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.issueDateFromSearch = value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
        }
      });

      this.certificateReportForm.get('hasPolicy').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          if ((this.certificateReportForm.get('aggregationType').value == 'IssueDateHorizontal') && (this.OutputArray.length > 0))
          {
            if (value !='')
            {
              this.aggrid.api.setRowData(this.OutputArray.filter(a => a.hasPolicy == value));
            }
            else
            {
              this.aggrid.api.setRowData(this.OutputArray);

            }
          } 
        }
      });
      this.certificateReportForm.get('aggregationType').valueChanges.subscribe(value => {
        if(value == 'IssueDate' || value == '')
        {
          this.gridOptions.columnDefs = [
            { headerName: "Dealer Id", field: "dealerId", sortable: true, filter: true }
            , { headerName: "Dealer Name", field: "dealerName", sortable: true, filter: true }
            , { headerName: "Dealer City", field: "dealerCity", sortable: true, filter: true }
            , { headerName: "Plan Type", field: "dealerPlanType", sortable: true, filter: true }
            , { headerName: "Issue Date", field: "issueDate", sortable: true, filter: true }
            , { headerName: "Type", field: "type", sortable: true, filter: true, filterParams:{defaultFilter:"Total"}, valueFormatter: function (params) { return params.value == "New" ? "New" : params.value == "Old" ?"Renewal":params.value }}
            , { headerName: "Total", field: "totalPolicyNumber", sortable: true, filter: true }
          ];
          this.configDataService.PackageInsuranceCompanies.map(a => {
            this.gridOptions.columnDefs.push({ headerName: a.name, field: a.key, sortable: true, filter: true })
          });
          this.aggrid.api.setColumnDefs(this.gridOptions.columnDefs);
        }
          this.onSubmit();
      }); 

    this.certificateReportForm.get('issueDateTo').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.issueDateToSearch = value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
        }
        //console.log("issueDateToSearch: " + this.issueDateToSearch)
      });


  }
  // convenience getter for easy access to form fields
  get f() { return this.certificateReportForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    if (["employee","admin","manager","dealerOwner"].indexOf(this.user.role) == -1){
      this.alertService.error("Unauthorized access");
      this.submitted = false;
      this.loading = false;
      return;
    }
    this.reportSearch = new ReportSearch();
    this.reportSearch.dealerGroupName = this.certificateReportForm.get('dealerGroupName').value;
    this.reportSearch.dealerState = this.certificateReportForm.get('dealerState').value;
    this.reportSearch.dealerId = this.certificateReportForm.get('dealerId').value;
    this.reportSearch.cpaPolicyCompany = this.certificateReportForm.get('cpaPolicyCompany').value;
    if (this.issueDateFromSearch == undefined) {
      this.reportSearch.issueDateFrom = this.currentDate.getFullYear() + "-" + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.currentDate.getDate()).slice(-2);;
    }
    else
      this.reportSearch.issueDateFrom = this.issueDateFromSearch;

    if (this.issueDateToSearch == undefined) {
      this.reportSearch.issueDateTo = "9999-99-99";
    }
    else
      this.reportSearch.issueDateTo = this.issueDateToSearch;
    if (this.certificateReportForm.get("aggregationType").value.length > 0)
    this.reportSearch.aggregationType = this.certificateReportForm.get("aggregationType").value;
    else this.reportSearch.aggregationType = "";

    this.reportSearch.aggregationType = this.reportSearch.aggregationType.includes("IssueDate") ? "IssueDate" : this.reportSearch.aggregationType;

    this.searchService.getDealerPackageAggregatedReport(this.reportSearch)
      .pipe(first())
      .subscribe({
        next: (x) => {
            // console.log("searchg: " + JSON.stringify(x))
          // if (this.reportSearch.cpaPolicyCompany == "IFCO Tokio")
          // {
          //   x = x.filter(a => a.policyType.includes("Ifco"))
          // }
          // if (this.reportSearch.cpaPolicyCompany == "IFCO Tokio")
          // {
          //   x = x.filter(a => a.policyType.includes("Ifco"))
          // }
          // if (this.reportSearch.cpaPolicyCompany == "None")
          // {
          //   x = x.filter(a => this.selectedPrintInstruction.includes(a.policyType))
          // }
          // if (this.reportSearch.cpaPolicyCompany == "New India")
          // {
          //   x = x.filter(a => !(this.selectedPrintInstruction.includes(a.policyType) || a.policyType.includes("Ifco")) )
          // }

          this.massageData(x,this.reportSearch);
          // if (this.reportSearch.aggregationType == "IssueDate") { this.massageData(x); }
          // else { this.populateDealerData(x, []); }
          this.gridReady = true;
          this.loading = false;
        },
        error: error => {
          this.alertService.error(error)
          this.loading = false;
        }
      })

  }
  onReset() {

  }
  exportToExcel() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'excelReport',
      //     columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.aggrid.api.exportDataAsCsv(params);
    // this.aggrid.api.exportDataAsExcel(params);
  }
  massageData(abc, reportSearch) {
    if ((this.reportSearch.aggregationType) && (this.reportSearch.aggregationType == "IssueDate")) {

      var maxIssueDate = (abc.length > 1) ? abc.reduce((a, b) => a.issueDateString > b.issueDateString ? a.issueDateString : b.issueDateString): abc.issueDateString;
      var minIssueDate = this.issueDateFromSearch;
    }
    else
    {
      if (!(this.reportSearch.aggregationType)) abc = abc.map(a => {a.issueDateString = ""; return a;});
    }
    this.dealerService.getAll()
      .pipe(first())
      .subscribe({
        next: (x) => {
          if (this.reportSearch.dealerGroupName != "" || this.reportSearch.dealerState != "" || this.reportSearch.dealerId != "")
          {
          var regexp =  new RegExp(this.reportSearch.dealerGroupName,'i');
          this.stateRegexp = new RegExp(this.reportSearch.dealerState,'i');
          this.idRegexp = new RegExp(this.reportSearch.dealerId,'i');
          x = x.filter(a => a.dealerCreatePackage);
          x = x.filter(a => {
            return regexp.test(a.dealerGroupName) && this.stateRegexp.test(a.dealerState) && this.idRegexp.test(a.dealerId);
          })
        }
        if (this.certificateReportForm.get("aggregationType").value == "IssueDateHorizontal")
        {
          this.createHorizontalReport(x,abc,maxIssueDate,minIssueDate)
        }
        else
        {
         console.log("dealerArray: " + JSON.stringify(x) );
          var abctest = [];
          var PackageInsuranceCompanies = this.configDataService.PackageInsuranceCompanies;
          abctest = abctest.concat(x.filter(x => x.dealerCreatePackage)
            .map(function (x) {
              // console.log(JSON.stringify(reportSearch));
              var abcInternal = [];
              if (reportSearch.aggregationType == "IssueDate"){
              for (var d = new Date(minIssueDate); d <= new Date(maxIssueDate); d.setDate(d.getDate() + 1)) {
                //create json with elements for insurancecompanies 
                var ab= {"dealerId": x.dealerId, "dealerName": x.dealerName,"type":"New", "dealerPlanType": x.dealerPlanType, "dealerCity":x.dealerCity
              ,"issueDate": d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2)};
                PackageInsuranceCompanies.map(a => {
                  ab[a.key] = 0;
                });
                abcInternal.push(ab);
                var ab= {"dealerId": x.dealerId, "dealerName": x.dealerName,"type":"Old", "dealerPlanType": x.dealerPlanType, "dealerCity":x.dealerCity
              ,"issueDate": d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2)};
                PackageInsuranceCompanies.map(a => {
                  ab[a.key] = 0;
                });
                abcInternal.push(ab);
                var ab= {"dealerId": x.dealerId, "dealerName": x.dealerName,"type":"Total", "dealerPlanType": x.dealerPlanType, "dealerCity":x.dealerCity
              ,"issueDate": d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2)};
                PackageInsuranceCompanies.map(a => {
                  ab[a.key] = 0;
                });
                abcInternal.push(ab);
                // abcInternal.push(
                //   {
                //     "dealerId": x.dealerId, "dealerName": x.dealerName, "dealerPlanType": x.dealerPlanType, "dealerCity":x.dealerCity,
                //     "issueDate": d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2)
                //     , "cpaPolicyNumber": 0, "rsaPolicyNumber": 0, "docPolicyNumber": 0,"financePolicyNumber":0, "totalPolicyNumber": 0
                //   }
                // )
              }

                //console.log("abcInternal: " + JSON.stringify(abcInternal));
              }
              else
              {
              var ab= {"dealerId": x.dealerId, "dealerName": x.dealerName,"type":"New", "dealerPlanType": x.dealerPlanType, "dealerCity":x.dealerCity,"issueDate": ""}
              PackageInsuranceCompanies.map(a => {
                ab[a.key] = 0;
              });
              abcInternal.push(ab);
              var ab= {"dealerId": x.dealerId, "dealerName": x.dealerName,"type":"Old", "dealerPlanType": x.dealerPlanType, "dealerCity":x.dealerCity,"issueDate": ""}
              PackageInsuranceCompanies.map(a => {
                ab[a.key] = 0;
              });
              abcInternal.push(ab);
              var ab= {"dealerId": x.dealerId, "dealerName": x.dealerName,"type":"Total", "dealerPlanType": x.dealerPlanType, "dealerCity":x.dealerCity,"issueDate": ""}
              PackageInsuranceCompanies.map(a => {
                ab[a.key] = 0;
              });
              abcInternal.push(ab);
            }
            console.log("abcInternal: " + JSON.stringify(abcInternal));

              // abcInternal.push(
              //   {
              //     "dealerId": x.dealerId, "dealerName": x.dealerName, "dealerCity":x.dealerCity, "dealerPlanType": x.dealerPlanType,
              //     "issueDate": ""
              //     , "cpaPolicyNumber": 0, "rsaPolicyNumber": 0, "docPolicyNumber": 0, "financePolicyNumber":0, "totalPolicyNumber": 0
              //   });

              return abcInternal;
              //return {"dealerId": x.dealerId,"dealerName":x.dealerName,"dealerPlanType": x.dealerPlanType, "issueDate": x.issueDateString,"cpaPolicyNumber":0,"cpaPolicyAmount":0,"rsaPolicyNumber":0,"rsaPolicyAmount":0,"docPolicyNumber":0,"docPolicyAmount":0} ;}))
              //console.log("x: " + JSON.stringify(x))
            }))
          
          this.populateDealerData(abc, abctest)
          //console.log("abctest: " + JSON.stringify(abctest));
        }},
        error: error => {
          this.alertService.error(error)
          this.loading = false;
        }
      })
  }
  populateDealerData(policyData, DealerData) {
    console.log("poldata:" + JSON.stringify(policyData))
    var insCompany = this.configDataService.PackageInsuranceCompanies;
    policyData = policyData.sort((x, y) => (x.dealerId + x.IssueDate > y.dealerId + y.IssueDate) ? 1 : ((x.dealerId + x.IssueDate < y.dealerId + y.IssueDate) ? -1 : 0));
    var xyz = {};
    this.configDataService.PackageInsuranceCompanies.map(a => {
      xyz[a.key] = policyData.filter(x => x.insuranceCompany == a.name).map(function (x) { return { "dealerId": x.dealerId, "dealerName": x.dealerName,"type":x.type, "insuranceCompany": x.insuranceCompany, "issueDate": x.issueDateString?x.issueDateString:"", "policyNumber": x.numberOfPolicySold }; });
    });
    console.log("xyz: " + JSON.stringify(xyz));
    // var cpa = policyData.filter(x => (x.policyType != "CPA + RSA" && x.policyType != "RSA" && x.policyType != "CPA + RSA + DOC" && x.policyType != "CPA + DOC" && !x.policyType.includes('FINANCE'))).map(function (x) { return { "dealerId": x.dealerId, "dealerName": x.dealerName, "planType": x.planType, "policyType": "CPA", "issueDate": x.issueDateString?x.issueDateString:"", "policyNumber": x.numberOfPolicySold }; })
    // var rsa = policyData.filter(x => (x.policyType == "CPA + RSA" || x.policyType == "RSA")).map(function (x) { return { "dealerId": x.dealerId, "dealerName": x.dealerName, "planType": x.planType, "policyType": "RSA", "issueDate": x.issueDateString?x.issueDateString:"", "policyNumber": x.numberOfPolicySold }; });
    // var finance = policyData.filter(x => (x.policyType.includes("FINANCE"))).map(function (x) { return { "dealerId": x.dealerId, "dealerName": x.dealerName, "planType": x.planType, "policyType": "RSA", "issueDate": x.issueDateString?x.issueDateString:"", "policyNumber": x.numberOfPolicySold }; });
    // var doc = policyData.filter(x => (x.policyType == "CPA + RSA + DOC" || x.policyType == "CPA + DOC")).map(function (x) { return { "dealerId": x.dealerId, "dealerName": x.dealerName, "planType": x.planType, "policyType": "DOC", "issueDate": x.issueDateString?x.issueDateString:"", "policyNumber": x.numberOfPolicySold }; });
    policyData = policyData.map(function (x) { 
      var abc= { "dealerId": x.dealerId, "dealerName": x.dealerName,"type":x.type, "dealerPlanType": x.dealerPlanType, "issueDate": x.issueDateString};
      insCompany.map(a => {
        abc[a.key] = 0;
      }); 
      abc["totalPolicyNumber"] = 0;
      return abc; 
      // return { "dealerId": x.dealerId, "dealerName": x.dealerName, "dealerPlanType": x.dealerPlanType, "issueDate": x.issueDateString, "cpaPolicyNumber": 0, "rsaPolicyNumber": 0, "docPolicyNumber": 0,"financePolicyNumber":0, "totalPolicyNumber": 0 }; 
    });
    console.log("policyData: " + JSON.stringify(policyData));
    var abcDistinct = [];
    if (DealerData.length > 0) {
      DealerData.forEach(e => {
        e.forEach(x => {
          abcDistinct.push(x)

        })
      });
      // var abcDistinct = DealerData;
    }
    else {
      abcDistinct = policyData.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.dealerId === thing.dealerId && t.issueDate === thing.issueDate && t.type === thing.type
        ))
      );
    }
    // console.log("abc2: " + JSON.stringify(abcDistinct));
    var total = { Type: "",issueDate: "Total",totalPolicyNumber:0}
    // , "cpaPolicyNumber": 0, "rsaPolicyNumber": 0, "docPolicyNumber": 0, "financePolicyNumber": 0, "totalPolicyNumber": 0 };
    this.configDataService.PackageInsuranceCompanies.map(a => {
      total[a.key] = 0;
    });

    abcDistinct = abcDistinct.map(x => {
      x.totalPolicyNumber = 0;
      this.configDataService.PackageInsuranceCompanies.map(a => {
        x[a.key] = xyz[a.key].filter(y => (y.dealerId == x.dealerId && y.issueDate == x.issueDate && y.type == x.type)).reduce((a, b) => a + (parseInt(b.policyNumber) || 0), 0);
        total[a.key] = total[a.key] + x[a.key];
        if(x[a.key] > 0) {x.totalPolicyNumber = x.totalPolicyNumber + x[a.key];
        total.totalPolicyNumber = total.totalPolicyNumber + x[a.key];
        }
      });
      return x;
    }
    )
abcDistinct = abcDistinct.map(x => {
  if (x.type == "Total") {
    this.configDataService.PackageInsuranceCompanies.map(a => {
      x[a.key] = abcDistinct.find(y => y.dealerId == x.dealerId && y.issueDate == x.issueDate && y.type == "New")[a.key] + abcDistinct.find(y => y.dealerId == x.dealerId && y.issueDate == x.issueDate && y.type == "Old")[a.key];
    // console.log("x[a.key]: " + x[a.key]);
    });
    x.totalPolicyNumber = abcDistinct.find(y => y.dealerId == x.dealerId && y.issueDate == x.issueDate && y.type == "New").totalPolicyNumber + abcDistinct.find(y => y.dealerId == x.dealerId && y.issueDate == x.issueDate && y.type == "Old").totalPolicyNumber;
    return x;
  }
  else {
    return x;
  }
})

let totalLines = abcDistinct.reduce((totals, item) => {
  if (!totals[item.type]) {
    totals[item.type] = {oriental: 0, united: 0, bajaj: 0, "Name not Found": 0, totalPolicyNumber: 0, type: item.type,issueDate: "Total"};
  }
  totals[item.type].oriental += item.oriental;
  totals[item.type].united += item.united;
  totals[item.type].bajaj += item.bajaj;
  totals[item.type]["Name not Found"] += item["Name not Found"];
  totals[item.type].totalPolicyNumber += item.totalPolicyNumber;
  return totals;
}, {});

let totalLinesArray = Object.values(totalLines);

abcDistinct.push(...totalLinesArray);
    
    abcDistinct.push(total);
    // this.aggrid.api.setRowData(abcDistinct);
    this.OutputArray = abcDistinct;
    if (this.f.type.value != 'All' && this.f.type.value != '')
    {
      let val = this.f.type.value == 'Renewal' ? 'Old' : 'New';
      this.aggrid.api.setRowData(abcDistinct.filter(a => a.type == val));
    }
    else
    {
      this.aggrid.api.setRowData(abcDistinct);
    }

    // console.log("abc: " + JSON.stringify(abcDistinct));
  }
  createHorizontalReport(eligibleDealer: Dealer[],abc:any[],maxIssueDate,minIssueDate){
    console.log("max issue date: " + JSON.stringify(maxIssueDate));
    let selectedDate = "";
    this.OutputArrayNew = eligibleDealer.map(
      x => {
        let outputObject = {};
        outputObject['dealerId'] = x.dealerId;
        outputObject['dealerName'] = x.dealerName;
        outputObject['type'] = 'New';
        outputObject['dealerCity'] = x.dealerCity;
        outputObject['lastIssueDate'] = "No Policy Issued";
        outputObject['totalPolicy'] = 0;
        outputObject['hasPolicy'] = this.booleanArray[2];
        for (var d = new Date(minIssueDate); d <= new Date(maxIssueDate); d.setDate(d.getDate() + 1)) {
          selectedDate = d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2);

          outputObject[selectedDate] = abc.filter(s => s.dealerId == x.dealerId && s.issueDateString == selectedDate && s.type == "New").reduce((a,b) => {
            return a + (b.numberOfPolicySold? b.numberOfPolicySold : 0)
          },0);
          if (outputObject[selectedDate] > 0)
          {
            outputObject['hasPolicy'] = this.booleanArray[1];
            outputObject['lastIssueDate'] = selectedDate;
            outputObject['totalPolicy'] += outputObject[selectedDate];
          }
        }
        return outputObject;
      }
    )
    this.OutputArrayOld = eligibleDealer.map(
      x => {
        let outputObject = {};
        outputObject['dealerId'] = x.dealerId;
        outputObject['dealerName'] = x.dealerName;
        outputObject['type'] = 'Old';
        outputObject['dealerCity'] = x.dealerCity;
        outputObject['lastIssueDate'] = "No Policy Issued";
        outputObject['totalPolicy'] = 0;
        outputObject['hasPolicy'] = this.booleanArray[2];
        for (var d = new Date(minIssueDate); d <= new Date(maxIssueDate); d.setDate(d.getDate() + 1)) {
          selectedDate = d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2);

          outputObject[selectedDate] = abc.filter(s => s.dealerId == x.dealerId && s.issueDateString == selectedDate && s.type == "Old").reduce((a,b) => {
            return a + (b.numberOfPolicySold? b.numberOfPolicySold : 0)
          },0);
          if (outputObject[selectedDate] > 0)
          {
            outputObject['hasPolicy'] = this.booleanArray[1];
            outputObject['lastIssueDate'] = selectedDate;
            outputObject['totalPolicy'] += outputObject[selectedDate];
          }
        }
        return outputObject;
      }
    )
      this.OutputArray = this.OutputArrayNew.concat(this.OutputArrayOld);
      this.OutputArray = this.OutputArray.sort((a,b) => a.dealerId > b.dealerId ? 1 : -1);

    let headerArray = [{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
  ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
  ,{headerName: "Dealer City", field: "dealerCity",sortable: true, filter:true }
  ,{headerName: "Type", field: "type",sortable: true, filter:true }
  ,{headerName: "Last Policy Issue Date", field: "lastIssueDate",sortable: true, filter:true }
  ,{headerName: "Total", field: "totalPolicy",sortable: true, filter:true }
  ];
  console.log("min issue date: " + minIssueDate);
  console.log("max issue date: " + JSON.stringify(maxIssueDate));

  for (var d = new Date(minIssueDate); d <= new Date(maxIssueDate); d.setDate(d.getDate() + 1)) {
      
      let headerColumn = {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true 
      ,cellStyle: function(params) {
        let date = new Date(params.column.colId);
        let background = "white"
        if (params.value > params.data[new Date(date.getTime() - (24 * 60 * 60 * 1000)).toISOString().split("T")[0]])
        {
          background = "green"
        }
        if (params.value < params.data[new Date(date.getTime() - (24 * 60 * 60 * 1000)).toISOString().split("T")[0]])
        {
          background = "orange"
        }
        if (params.value == 0)
        {
          background = "red"
        }
        return {
          background: background
        };
      }
    };
      selectedDate = d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2);
      headerColumn.field =selectedDate;
      headerColumn.headerName = selectedDate;
      console.log("headerColumn: " + JSON.stringify(headerColumn));
      headerArray.push(headerColumn);
    }
    // console.log('Output Array2: ' + JSON.stringify(this.OutputArray) )

    this.aggrid.api.setColumnDefs(headerArray);
    if (this.certificateReportForm.get("hasPolicy").value != '')
        
    {
      this.aggrid.api.setRowData(this.OutputArray.filter(a => a.hasPolicy == this.certificateReportForm.get("hasPolicy").value));
    }
    else
    {
      this.aggrid.api.setRowData(this.OutputArray);
    }
  }

}
