import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Certificate } from "./certificate";
import { PackageDetails } from "./packageDetails";
import { RsaProvided } from "./rsaProvided";
import { DealerModelPrice } from "./dealerModelPrice";
import { RequestHistory } from "./RequestHistory";

export class Package extends Certificate{
    title?:string;
    prePolClaim:string;
    transferCase:string;
    idv: number;
    prevPolicyExpire:string;
    rto:string;
    nilDep:string;
    hypothecated:string;
    aadhaarNumber:string;
    aadhaarUrl:string;
    panNumber:string;
    panUrl:string;
    invoiceUrl:string;
    financeCompanyAddress:string;
    financeCompanyState?:string;
    financeCompanyCity?:string;
    prevOdInsurer:string;
    prevOdPolicyNo:string;
    prevOdPolicyExpiry:NgbDateStruct;
    prevTpInsurer:string;
    prevTpPolicyNo:string;
    prevTpPolicyExpiry:NgbDateStruct;
    insuranceCompany:string;
    packageId:string;
    packageNumber:string;
    package: PackageDetails;
    packageStatus:string;
    policyStatus:string;
    pincode:number;
    insPolicyPdfUrl:string;
    insurancePolicyNumber:string;
    insurancePolicyAmount:number;
    insurancePolicyGStAmount:number;
    insurancePolicyTotalAmount:number;
    packagePolicyAmount:number;
    packagePolicyGStAmount:number;
    packagePolicyTotalAmount:number;
    policyPdf?:string;
    dealerBalance?:number;
    invoiceValue?:number =0;
    dealerModelPrice?:DealerModelPrice = new DealerModelPrice();
    dealerConfigFound?:boolean = false;
    vehicleColor?:string;
    cubicCapacity?:number = 0;
    rtiCover?:string;
    tpPdCover?:string;
    ncbPercentValue?:number = 0;
    oldValueType?:string ='Invoice';
    odPremiumAmount?:number = 0;
    tpPremiumAmount?:number = 0;
    rtiCoverAmount?:number = 0;
    tpPdCoverAmount?:number = 0;
    nilDepAmount?:number = 0;
    odDiscountAmount?:number = 0;
    odNCBAmount?:number = 0;
    CpaCoverAmount?:number = 0;
    totalGstAmount?:number = 0;
    odPremiumRate?:number = 0;
    odDiscountPercentage?:number = 0;
    odNCBPercentage?:number = 0;
    nilDepPercentage?:number = 0;
    rtiCoverPercentage?:number = 0;
    cpaCoverPlanName?:string = "";
    vehicleRegistrationDate?:NgbDateStruct;
    userNameProcessed?:string = "";
    requestHistory?:RequestHistory[] = [];
    comment?:string = "";
    estimatedPolicyTotalAmount?:number = 0;
    numberOfRequests?:number = (this.requestHistory && this.requestHistory.length > 0)?(Math.round(this.requestHistory.length/2)):0;
    breakInspectionReportUrl?: String= "";
    breakInspectionReportDate?:  String= "";
    breakInspectionReportTime?:  String = "";
    breakInspectionReportNumber?: String = "";


    public constructor(init?: Partial<Package>) {
        super();
        Object.assign(this, init);
    }   

}