import { Component,  OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageService } from '@app/issue-certificate/package.service';
import { User } from '@app/_models';
import { Certificate } from '@app/_models/certificate';
import { Package } from '@app/_models/package';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService, AlertService } from '@app/_services';
import { ConfigDataService } from '@app/_services/config-data.service';
import { SearchService } from '@app/_services/search.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { first } from 'rxjs/operators';
import { FileService } from '@app/_services/file.service';
import { PrintCertificateState } from '@app/_models/printCertificateState';

@Component({
  selector: 'app-print-certificate',
  templateUrl: './print-certificate.component.html',
  styleUrls: ['./print-certificate.component.less'],
})
export class PrintCertificateComponent implements OnInit {

  certificateReportForm: UntypedFormGroup;
  cancelPackageRequestForm: UntypedFormGroup;
  submitted = false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  loading = false;
  gridReady = true;
  printCertificateState: PrintCertificateState;
  reportSearch = new ReportSearch();
  issueDateFromSearch: string;
  issueDateToSearch: string;
  user: User;
  modelLoading = false;
  startDate: string;
  packages: Package[];
  currentDate = new Date();
  canceledPackageSelect: Package;
  emptyCertificate = new Certificate();
  selectedFile: any;
  uploadSuccess = false;
  isDeleting: boolean;
  requestCertificate: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private fileService: FileService,
    private packageService:PackageService,
    private configDataService: ConfigDataService,
    private router: Router

  ) { }
 
  ngOnInit(): void {
    this.requestCertificate = this.router.url.includes('requestCertificate');
    this.issueDateFrom = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
    this.issueDateTo = this.issueDateFrom;
    this.user = this.accountService.userValue;
    this.cancelPackageRequestForm = this.formBuilder.group({
      cancelReason: [''],
      packageAmountReversed: [false]
    });
    this.certificateReportForm = this.formBuilder.group({
      packageNumber: [''],
      chasisNumber: [''],
      firstName: [''],
      lastName: [''],
      userName: [''],
      contactNumber: [''],
      issueDateFrom: [this.issueDateFrom],
      issueDateTo: [this.issueDateFrom],
      dealerId: [''],
    });
    this.printCertificateState = this.packageService.getPrintCertificateState();
    if (this.printCertificateState != null) {
      this.reportSearch = this.printCertificateState.reportSearch;
      this.packages = this.printCertificateState.packageList;
      this.currentDate = new Date(this.reportSearch.issueDateFrom);
      this.issueDateFrom = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
      this.currentDate = new Date(this.reportSearch.issueDateTo);
      this.issueDateTo = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
      this.certificateReportForm.controls['packageNumber'].setValue(this.reportSearch.packageNumber);
      this.certificateReportForm.controls['chasisNumber'].setValue(this.reportSearch.chasisNumber);
      this.certificateReportForm.controls['firstName'].setValue(this.reportSearch.firstName);
      this.certificateReportForm.controls['lastName'].setValue(this.reportSearch.lastName);
      this.certificateReportForm.controls['userName'].setValue(this.reportSearch.userName);
      this.certificateReportForm.controls['contactNumber'].setValue(this.reportSearch.contactNumber);
      this.certificateReportForm.controls['issueDateFrom'].setValue(this.issueDateFrom);
      this.certificateReportForm.controls['issueDateTo'].setValue(this.issueDateTo);
      this.certificateReportForm.controls['dealerId'].setValue(this.reportSearch.dealerId);
      this.gridReady = true;
      this.loading = false;
      this.packageService.setPrintCertificateState(null);
    }
    else {
    this.configDataService.getTodaysDate()
      .subscribe(x => {
        this.startDate = new Date(new Date(x).setHours(0, 0, 0, 0)).toISOString();
        this.currentDate = new Date(x);
        if (this.requestCertificate)
          {
            this.issueDateFrom = { year: this.currentDate.getFullYear() - 1, month: 1, day: 1 };
            this.reportSearch.statuses = ["Cancelation Requested", "Endorsement Requested"];
          }
        else
        {
          this.issueDateFrom = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
          this.reportSearch.statuses = [];
        }
        this.certificateReportForm.controls['issueDateFrom'].setValue(this.issueDateFrom);
        this.reportSearch.packageNumber = "";
        this.reportSearch.userId = this.user.id;
        this.issueDateTo = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
        this.certificateReportForm.controls['issueDateTo'].setValue(this.issueDateTo);

        this.reportSearch.issueDateTo = this.currentDate.getFullYear() + "-" + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.currentDate.getDate()).slice(-2);
        this.reportSearch.policyNumber = "";
        if ((this.user.role != "admin") && (this.user.role != "employee"))  {
          this.onSubmit();
        }
        else {
          this.loading = false;
        }
      })
    }
  }
  get f() { return this.certificateReportForm.controls; }
  get cancelRequestF() { return this.cancelPackageRequestForm.controls; }
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();

    this.reportSearch = new ReportSearch();
    this.reportSearch.userId = this.user.id;
    if (this.requestCertificate)
      {
        this.reportSearch.statuses = ["Cancelation Requested", "Endorsement Requested"];
      }
    else
    {
      this.reportSearch.statuses = [];
    }

    this.reportSearch.packageNumber = this.certificateReportForm.get('packageNumber').value;
    this.reportSearch.chasisNumber = this.certificateReportForm.get('chasisNumber').value;

      this.reportSearch.firstName = this.certificateReportForm.get('firstName').value;
      this.reportSearch.lastName = this.certificateReportForm.get('lastName').value;
      this.reportSearch.userName = this.certificateReportForm.get('userName').value;
      this.issueDateFrom = this.certificateReportForm.get('issueDateFrom').value;
      this.reportSearch.issueDateFrom = this.issueDateFrom.year + '-' + ('0' + this.issueDateFrom.month).slice(-2) + '-' + ('0' + this.issueDateFrom.day).slice(-2);
      this.issueDateFrom = this.certificateReportForm.get('issueDateTo').value;
      this.reportSearch.issueDateTo = this.issueDateFrom.year + '-' + ('0' + this.issueDateFrom.month).slice(-2) + '-' + ('0' + this.issueDateFrom.day).slice(-2);

      this.searchService.getPackageReport(this.reportSearch)
        .pipe(first())
        .subscribe({
          next: (x) => {
            this.packages = x;
            // this.gridOptions.rowData = x;
            this.gridReady = true;
            this.loading = false;
          },
          error: error => {
            this.alertService.error(error)
            this.loading = false;
          }
        })
  }

  onReset() {
    this.alertService.clear();
    window.location.reload();

  }
  downloadPackage(packageInput: Package) {
    this.packageService.printPackage = packageInput;
    this.fileService.getPdfData(this.packageService.printPackage.packageNumber + "-Package.pdf" ).pipe(first())
    .subscribe({ next:data =>  {
      const downloadFile = (data, fileName) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(data);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 60000);
      }
        downloadFile(data, this.packageService.printPackage.packageNumber + ".pdf");
     },
     error: error => {
       this.alertService.error(error);
     }
 
      });

  }
  selectCancelPackage(certificate:Package)
  {
    this.canceledPackageSelect = certificate;
    this.packageService.printPackage = certificate;
    if (certificate.certificateNumber == null || certificate.certificateNumber == "") {
      this.cancelRequestF.packageAmountReversed.setValue(true);
    }
    else {
      this.cancelRequestF.packageAmountReversed.setValue(false);
    }
    this.packageService.setCurrentPackage(certificate);
    // this.packageService.printPackage = certificate;
  }
  cancelPackage() {
    this.isDeleting = true;
    if (this.cancelPackageRequestForm.invalid) {
      this.isDeleting = false;
      return;
    }
    this.canceledPackageSelect.comment = this.cancelRequestF.cancelReason.value;
    this.packageService.cancelPackage(this.canceledPackageSelect).pipe(first())
      .subscribe({
        next: (y) => {
               this.packages = this.packages.map(x => 
                { if (x.packageNumber == this.canceledPackageSelect.packageNumber){ 
                  x.packageStatus = "Cancelled"; 
                  x.requestHistory = y.requestHistory;
                  this.packageService.setCurrentPackage(x);}
                  this.isDeleting = false; 
                return x });
                this.canceledPackageSelect.packageStatus = "Cancelled";
                //close model cancelModel

        },
        error: error => {
          this.alertService.error(error);
          this.isDeleting = false;
        }
      })
      
  }
  endorsePackage() {
    this.isDeleting = true;
    if (this.cancelPackageRequestForm.invalid) {
      this.isDeleting = false;
      return;
    }
    this.canceledPackageSelect.comment = this.cancelRequestF.cancelReason.value;
    this.packageService.endorsePackage(this.canceledPackageSelect).pipe(first())
      .subscribe({
        next: (y) => {
               this.packages = this.packages.map(x => 
                { if (x.packageNumber == this.canceledPackageSelect.packageNumber){ 
                  x.packageStatus = "Cancelled"; 
                  x.requestHistory = y.requestHistory;
                  this.packageService.setCurrentPackage(x);}
                  this.isDeleting = false; 
                return x });
                this.canceledPackageSelect.packageStatus = "Cancelled";
                //close model cancelModel

        },
        error: error => {
          this.alertService.error(error);
          this.isDeleting = false;
        }
      })
      
  }
  requestCancelPackage() {
    this.isDeleting = true;
    if (this.cancelPackageRequestForm.invalid) {
      this.isDeleting = false;
      return;
    }
    this.canceledPackageSelect.comment = this.cancelRequestF.cancelReason.value;

    this.packageService.requestCancelPackage(this.canceledPackageSelect).pipe(first())
      .subscribe({
        next: (y) => {
               this.packages = this.packages.map(x => 
                { if (x.packageNumber == y.packageNumber){ 
                  x.packageStatus = "Cancelation Requested";
                  x.requestHistory = y.requestHistory;
                  this.packageService.setCurrentPackage(x);
                  this.canceledPackageSelect.packageStatus = "Cancelation Requested";
                  // this.cancelRequestF.cancelReason.setValue('Request has been successfully submitted. Please click close button to close the dialog.');
                }
                this.isDeleting = false;
                //close request model
                  document.getElementById("closeRequestCancelModal").click(); 
                return x});
        },
        error: error => {
          this.alertService.error(error);
          this.isDeleting = false;
        }
      })
      
  }
  requestEndorsePackage() {
    this.isDeleting = true;
    if (this.cancelPackageRequestForm.invalid) {
      this.isDeleting = false;
      return;
    }
    this.canceledPackageSelect.comment = this.cancelRequestF.cancelReason.value;
    this.packageService.setCurrentPackage(this.canceledPackageSelect)

    this.packageService.requestEndorsePackage(this.canceledPackageSelect).pipe(first())
      .subscribe({
        next: (y) => {
               this.packages = this.packages.map(x => 
                { if (x.packageNumber == y.packageNumber){ 
                  x.packageStatus = "Endorsement Requested";
                  x.requestHistory = y.requestHistory;
                  this.packageService.setCurrentPackage(x);
                  this.canceledPackageSelect.packageStatus = "Endorsement Requested";
                }
                this.isDeleting = false;
                return x});
        },
        error: error => {
          this.alertService.error(error);
          this.isDeleting = false;
        }
      })
      
  }

  processPackage(packageDtl: Package) {
    this.packageService.printPackage = packageDtl;
    this.setNewPrintCertificateState();
    this.router.navigate(['../issueCertificate/processPackage'], { relativeTo: null });
  }
  viewPackage(packageDtl: Package) {
    this.packageService.printPackage = packageDtl;
    this.setNewPrintCertificateState();
    this.router.navigate(['../issueCertificate/viewPackage'], { relativeTo: null });
  }
  preparePackage(packageDtl: Package) {
    this.packageService.printPackage = packageDtl;
    this.setNewPrintCertificateState();
    this.router.navigate(['../print'], { relativeTo: null });
  }
  setNewPrintCertificateState() {
    this.printCertificateState = new PrintCertificateState();
    this.printCertificateState.reportSearch = this.reportSearch;
    this.printCertificateState.packageList = this.packages; 
    this.packageService.setPrintCertificateState(this.printCertificateState);
  }
  uploadPackage(packageDtl: Package) {
    this.packageService.printPackage = packageDtl;
  }

  onPolicyPdfSelected(event){
    this.uploadSuccess = false;
    this.modelLoading = true;
    this.selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('customFileName', this.packageService.printPackage.packageNumber +"-Inspolicy");
    this.fileService.fileUpload(formData).subscribe(response => {
      this.packageService.printPackage.packageStatus = "Certificate Generated";
      this.packageService.updatePackageStatus().pipe(first()).subscribe({next: a => {
        this.modelLoading = false;
      },
      error: error => {
        this.alertService.error(error)
        this.modelLoading = false;
      }
    });
      this.uploadSuccess = true;
    });
  }

  isAnyActionAvailable(packageInput: any, user: any): boolean {
    return (packageInput.packageStatus == 'Submitted' && ((user.role == 'admin' || user.role == 'employee') || (user.specialRoleAccess && user.specialRoleAccess.includes('CanGenerateOldPolicyPackage') && packageInput.type=='Old'))) ||
      ((packageInput.packageStatus == 'Print Ready' || (packageInput.packageStatus =='Certificate Generated' && user.role == 'admin')) && (user.role == 'admin' || user.role == 'employee')) ||
      ((user.role == 'admin' || user.role == 'employee')) ||
      (['Certificate Generated','Submitted','Print Ready'].includes(packageInput.packageStatus) && (user.role == 'admin')) ||
      (packageInput.packageStatus== 'Print Ready');
  }

}
