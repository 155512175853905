import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { MakeModel } from '@app/_models/makeModel';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StateCity } from '@app/_models/stateCity';
import { PlanDetails } from '@app/_models/planDetails';
import { User } from '@app/_models';
import { DealerService } from '@app/_services/dealer-service';
import { Dealer } from '@app/_models/dealer';
import { Certificate } from '@app/_models/certificate';
import { CertificateService } from '@app/_services/certificate.service';
import { first } from 'rxjs/internal/operators/first';
import { CertificateResponse } from '@app/_models/certificateResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageDetails } from '@app/_models/packageDetails';
import { Package } from '@app/_models/package';
import { PackageService } from '../package.service';
import { FileService } from '@app/_services/file.service';
import { DealerModelConfig } from '@app/_models/dealerModelConfig';
import { DealerModelPrice } from '@app/_models/dealerModelPrice';
import { PremiumCalculation } from '@app/_models/premiumCalculation';

@Component({
  selector: 'app-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.less'],
})
export class AddCertificateComponent implements OnInit {
  certficateForm: UntypedFormGroup;
  showGstAmount = true;
  loading = false;
  isBalanceLow = false;
  firstTime = true;
  submitted = false;
  dealer = new Dealer();
  hours: number[] = Array.from({ length: 24 }, (x, i) => i);
  minutes: number[] = Array.from({ length: 60 }, (x, i) => i);
  makeModel: MakeModel[];
  configMakeModel: MakeModel[];
  models: string[];
  cities: string[];
  financeCompanies: string[];
  selectedFinanceCompanies: string[];
  stateCity: StateCity[];
  packages: PackageDetails[];
  selectedPackages: PackageDetails[];
  maxAmount = 99999;
  minAmount = 0;
  showFinance = false;
  selectedMakeModel: MakeModel;
  selectedFinance: string;
  nameRegexp = new RegExp('', 'i');
  startDateModel: NgbDateStruct;
  maxOdDateModel = { year: 2010, month: 1, day: 1 };
  minOdDateModel = { year: 2010, month: 1, day: 1 };
  dobString: string;
  customerDob: string;
  runningBalance: number;
  types = ['New', 'Old'];
  genders = ['Male', 'Female'];
  clientTypes = ['Individual', 'Corporate'];
  years: number[];
  relationships = [
    'Spouse',
    'Husband',
    'Wife',
    'Father',
    'Mother',
    'Son',
    'Daughter',
    'Brother',
    'Sister',
    'Grandson',
    'Granddaughter',
    'Other',
  ];
  selectPolicyExpiryClaim = [
    'Not expired',
    'Expired within 90 days',
  ];
  policyExpireArray = [
    '',
    'Not expired',
    'Expired within 90 days',
    'Expired more than 90 days',
  ];
  titles = ['Mr', 'Mrs.', 'Miss', 'Master', 'Shri'];
  registrtionNumberValidation = [];
  certificateResponse: Package;
  user: User;
  selectedFile: File = null;
  gSTAmount: number;
  gstPercentage = 0.18;
  isCorporate = false;
  User: User;
  currentDate = new Date();
  certificateCreated = false;
  issueDateString: string;
  issueDate: Date;
  startDate: Date;
  endDate: Date;
  minDobDate: Date;
  maxDobDate: Date;
  selectDealerModelPrice: DealerModelPrice = new DealerModelPrice();
  ageOrDobValue = ['dob', 'age'];
  minDobDateModel = { year: 2010, month: 1, day: 1 };
  maxDobDateModel = { year: 2010, month: 1, day: 1 };
  registrationDate = { year: 2024, month: 1, day: 1 };
  minRegistrationDateModel = { year: 2010, month: 1, day: 1 };
  maxRegistrationDateModel = { year: 2024, month: 1, day: 1 };
  newYears: Number[];
  showPaymentButton = false;
  showPaymentGatewayForm = false;
  renewCertificate = false;
  oldCertificateValue: Certificate;
  insuranceCompany: string[];
  packageInsuranceCompany: string[];
  dealerPackages: PackageDetails[];
  selectedPackage: PackageDetails;
  minOdDate: Date;
  maxOdDate: Date;
  package = new Package();
  aadhaarFormData = new FormData();
  panFormData = new FormData();
  packageNumber: string;
  panUrl: string;
  aadharUrl: string;
  rtoList: string[];
  invoiceUrl: any;
  breakInspectionReportUrl: any;
  dealarConfigFound: boolean;
  allDealerConfig: DealerModelConfig[];
  dealerConfig: DealerModelConfig;
  finCities: string[];
  premiumCalculation: Map<string, PremiumCalculation>;
  selectedPremiumCalc: PremiumCalculation;
  selectedVehicleYear: number;
  noClaimBonusYear: { ncbYear: number; ncbPercentage: string; }[]=[];
  selectedNoClaimBonusYear: { ncbYear: number; ncbPercentage: string; }[];
  vehicleAge: number;
  odPremium: number;
  nilDep: string = "Yes";
  rtiCover: string = "No";
  tpPdCover:string = "No";
  prePolClaim: string = "";
  ncbPercentValue: string = "0";
  editPackage: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private configDataService: ConfigDataService,
    private dealerService: DealerService,
    private packageService: PackageService,
    private fileServices: FileService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.user = this.accountService.userValue;
    // verify if url contains select-package
    this.editPackage = this.router.url.includes('select-package');

    this.certficateForm = this.formBuilder.group({
      vehicleMake: ['', Validators.required],
      vehicleRegistrationDate: [this.registrationDate],
      model: ['', Validators.required],
      type: ['', Validators.required],
      variant: [''],
      prePolClaim: ['No'],
      transferCase: ['Yes'],
      cubicCapacity: [0,[Validators.required,Validators.min(1)]],
      idv: ['', Validators.required],
      prevPolicyExpire: [this.policyExpireArray[1]],
      rto: ['', Validators.required],
      nilDep: ['Yes'],
      rtiCover: ['No'],
      tpPdCover:['No'],
      hypothecated: ['No', Validators.required],
      aadhaarNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$'),
        ],
      ],
      aadhaarFile: ['', Validators.required],
      panNumber: [''],
      panFile: [''],
      invoiceFile: [''],
      financeCompanyAddress: [],
      financeCompanyState: [],
      financeCompanyCity: [],
      prevOdInsurer: ['', Validators.required],
      prevOdPolicyNo: ['', Validators.required],
      prevOdPolicyExpiry: ['', Validators.required],
      prevTpInsurer: ['', Validators.required],
      prevTpPolicyNo: ['', Validators.required],
      prevTpPolicyExpiry: ['', Validators.required],
      // insuranceCompany:['',Validators.required],
      packageId: ['', Validators.required],
      vehicleYear: [''],
      registrationNumber: [''],
      chasisNumber: [
        '',
        [
          this.trimValidator,
          Validators.required,
          Validators.minLength(17),
          Validators.maxLength(17),
        ],
      ],
      engineNumber: ['', Validators.required],
      clientType: [this.clientTypes[0], Validators.required],
      title: [this.titles[0]],
      firstName: ['', [this.trimValidator, Validators.required]],
      middleName: [''],
      lastName: [' '],
      vehicleType: ['Two Wheeler'],
      email: ['', [this.trimValidator, Validators.required, Validators.email]],
      contactNumber: [
        '',
        [Validators.required, Validators.pattern('^[6-9][0-9]{9}$')],
      ],
      pincode: [
        '',
        [Validators.required, Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      employeeContactNumber: [0],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      ageOrDob: [this.ageOrDobValue[0]],
      gstin: [''],
      address: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      nomineeName: ['', Validators.required],
      nomineeRelationship: [this.relationships[0], Validators.required],
      nomineeGender: [this.genders[0], Validators.required],
      nomineeAge: [
        '',
        [Validators.required, Validators.min(1), Validators.max(100)],
      ],
      age: [''],
      startDate: ['', Validators.required],
      endDate: [''],
      issueDate: [''],
      balance: [''],
      userId: [''],
      packageNumber: [''],
      certificateNumber: [''],
      policyNumber: [''],
      financeCompanyName: [''],
      financeCoverAmount: [50000],
      invoiceValue: [0],
      vehicleColor: [''],
      packagePolicyTotalAmount: [{ value: 0, disabled: true }],
      ncbPercentValue:[],
      oldValueType:['PrevIdv'],
      odPremiumAmount:[0],
      tpPremiumAmount:[0],
      rtiCoverAmount:[0],
      tpPdCoverAmount:[0],
      nilDepAmount:[0],
      odDiscountAmount:[0],
      odNCBAmount:[0],
      CpaCoverAmount:[0],
      totalGstAmount:[0],
      odPremiumRate:[0],
      odDiscountPercentage:[0],
      odNCBPercentage:[0],
      nilDepPercentage:[0],
      rtiCoverPercentage:[0],
      cpaCoverPlanName:[''],
      breakInspectionReportFile:[''],
      breakInspectionReportDate:[''],
      breakInspectionReportTimeHH:[''],
      breakInspectionReportTimeMM:[''],
      breakInspectionReportNumber:[''],
    });
    this.configDataService.FinanceCompanyList$.subscribe((x) => {
      this.financeCompanies = x;
    });

    this.configDataService
      .getPackageId()
      .subscribe((x) => (this.packageNumber = 'PKG' + x));
    this.startDateModel = { year: 2010, month: 1, day: 1 };
    this.startDateModel.year = this.currentDate.getFullYear();
    this.startDateModel.day = this.currentDate.getDate();
    this.startDateModel.month = this.currentDate.getMonth() + 1;

    this.configDataService.RtoList$.subscribe((x) => {
      this.rtoList = x;
    });

    this.configDataService.getTodaysDate().subscribe((x) => {
      this.currentDate = new Date(x);
      this.issueDate = new Date(x);
      this.startDate = new Date(x);
      this.endDate = new Date(
        new Date().setFullYear(this.currentDate.getFullYear() + 1)
      );
      this.minDobDate = new Date(
        new Date().setFullYear(this.currentDate.getFullYear() - 80)
      );
      this.maxDobDate = new Date(
        new Date().setFullYear(this.currentDate.getFullYear() - 15)
      );
      this.newYears = [
        this.currentDate.getFullYear(),
        this.currentDate.getFullYear() - 1,
      ];

      this.startDateModel.year = this.currentDate.getFullYear();
      this.startDateModel.day = this.currentDate.getDate();
      this.startDateModel.month = this.currentDate.getMonth() + 1;
      this.certficateForm.controls['startDate'].setValue(this.startDateModel);
      this.maxRegistrationDateModel.year = this.currentDate.getFullYear();
      this.maxRegistrationDateModel.day = this.currentDate.getDate();
      this.maxRegistrationDateModel.month = this.currentDate.getMonth() + 1;
      this.minDobDateModel.year = this.minDobDate.getFullYear();
      this.minDobDateModel.day = this.minDobDate.getDate();
      this.minDobDateModel.month = this.minDobDate.getMonth() + 1;

      this.maxDobDateModel.year = this.maxDobDate.getFullYear();
      this.maxDobDateModel.day = this.maxDobDate.getDate();
      this.maxDobDateModel.month = this.maxDobDate.getMonth() + 1;
      // if (this.user.specialRoleAccess && this.user.specialRoleAccess.includes('canSeeAge'))
      // {
      //   this.certficateForm.controls['ageOrDob'].setValue(this.ageOrDobValue[0]);
      //   this.certficateForm.get('dob').clearValidators();
      //   this.certficateForm.get('dob').updateValueAndValidity();
      // }

      this.configDataService.StateCity$.subscribe((x) => {
        this.stateCity = x;

        this.configDataService.InsuranceCompany$.subscribe((x) => {
          this.insuranceCompany = x;

          this.configDataService.PremiumCalculation$.subscribe((x) => {
            this.premiumCalculation = x[0];
            this.getSelectedPremium();

            this.certficateForm.controls['endDate'].disable();
            this.certficateForm.controls['issueDate'].disable();
            this.certficateForm.controls['balance'].disable();

            // if ((this.user.role == "executive") || (this.user.role =="manager") || (this.user.role == "dealerOwner")) {
            this.dealerService
              .getByDealerId(this.user.dealerId)
              .subscribe((x) => {
                this.dealer = x;
                if (this.dealer.makeInvoiceUploadMandatory) {
                  this.certficateForm.controls['invoiceFile'].setValidators(
                    Validators.required
                  );
                  this.certficateForm.controls[
                    'invoiceFile'
                  ].updateValueAndValidity();
                }
                if (this.dealer.makeInvoiceValueMandatory) {
                  this.certficateForm.controls['invoiceValue'].setValidators(
                    Validators.required
                  );
                  this.certficateForm.controls[
                    'invoiceValue'
                  ].updateValueAndValidity();
                }

                if (
                  this.dealer.dealerBalance < this.dealer.dealerBalanceLimit
                ) {
                  this.isBalanceLow = true;
                  this.alertService.error(
                    'Dealer Balance Low. Create Package is blocked. Please call Insure customer service center'
                  );
                } else {
                  if (
                    this.dealer.dealerBalance <
                    this.dealer.dealerBalanceLimit * 0.9
                  ) {
                    this.alertService.info(
                      'Dealer Balance is low. Please recharge to avoid business interruption'
                    );
                  }
                }
                this.showPaymentButton = this.dealer.dealerPaymentShow
                  ? this.dealer.dealerPaymentShow == 'Yes'
                    ? true
                    : false
                  : false;
                this.makeModel = x.dealerMake;
                this.f.state.setValue(this.dealer.dealerState);
                this.f.city.setValue(this.dealer.dealerCity);
                this.f.email.setValue(this.dealer.dealerEmail);
                this.configDataService.Packages$.subscribe((a) => {
                  this.packages = a;
                  // this.packageInsuranceCompany = [...new Set(this.packages.map(a => a.insuranceCompany))];
                  this.dealerPackages = this.packages.filter(
                    (a) =>
                      this.dealer.dealerPackage &&
                      this.dealer.dealerPackage.includes(a.packageId)
                  );
                  // this.certficateForm.get('insuranceCompany').setValue(this.packageInsuranceCompany[0]);
                });
                this.configDataService
                  .getDealerModelConfig(this.user.dealerId)
                  .pipe(first())
                  .subscribe({
                    next: (x) => {
                      this.allDealerConfig = x;
                      this.dealerConfig = this.allDealerConfig.find(a=>[undefined,"Both",this.certficateForm.get('type').value].includes(a.applicableOn));
                      if (this.dealerConfig && this.dealerConfig.dealerModelPrices.length > 0) {
                        this.getMakeModelfromDealerConfig();
                        this.certficateForm.controls['type'].setValue('New');

                      } else {
                        this.setDefaultMakeModel();
                      }
                      if (this.editPackage) {
                        this.certficateForm.patchValue(this.packageService.printPackage);
                        
                      }
                    },
                    error: (error) => {
                      // this.alertService.error(error);
                      this.setDefaultMakeModel();
                    },
                  });
              });

            this.certficateForm.controls['userId'].setValue(this.user.id);
          });
        });
      });
    });

    this.maintainClientType();
    this.maintainModelandMakeValueChanges();
    this.maintainMiscValueChanges();
    this.maintainDateTimeValueChanges();
  }
  getMakeModelfromDealerConfig() {
    this.dealarConfigFound = true;
    // convert x.dealerModelPrices to MakeModel array format
    this.makeModel = [
      ...new Set(this.dealerConfig.dealerModelPrices.map((a) => a.make)),
    ].map((a) => {
      return {
        make: a,
        model: [
          ...new Set(
            this.dealerConfig.dealerModelPrices
              .filter((b) => b.make == a)
              .map((r) => r.model)
          ),
        ],
        productType: 'Two Wheeler',
      };
    });
    this.selectedMakeModel = this.makeModel[0];
  }

  setDefaultMakeModel() {
    this.dealarConfigFound = false;
    this.configDataService.MakeModelVersion$.subscribe((x) => {
      this.certficateForm.controls['balance'].setValue(
        this.dealer.dealerBalance
      );
      this.makeModel = x;
      this.selectedMakeModel = x.find(
        (x) => x.make == this.dealer.dealerMake[0].make
      );
      if (this.selectedMakeModel.model.length == 0) {
        this.configDataService.getMakeModelVersion().subscribe((a) => {
          this.makeModel = a;
          this.selectedMakeModel = x.find(
            (x) => x.make == this.dealer.dealerMake[0].make
          );
        });
      }
      this.certficateForm.controls['type'].setValue('New');
    });
  }
  get f() {
    return this.certficateForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();
    // stop here if form is invalid
    if (this.certficateForm.invalid) {
      const invalidFields = [];
      //tell the certificate form fields provide list of invalid controls
      Object.keys(this.certficateForm.controls).forEach((field) => {
        const control = this.certficateForm.get(field);
        if (control.invalid) {
          invalidFields.push(field);
        }
      });
      this.alertService.error('Missing Input field: ' + invalidFields[0]);
      return;
    }

    this.loading = true;

    var packageRequest = new Package(this.certficateForm.getRawValue());
    if (!packageRequest.email) packageRequest.email = ' ';

    if (this.isCorporate) {
      packageRequest.gender = 'Organisation';
      packageRequest.dob = 'NA';
      packageRequest.nomineeRelationship = 'NA';
    } else {
      packageRequest.employeeContactNumber = 'NA';
      if (this.certficateForm.get('prevPolicyExpire').value == this.policyExpireArray[2] || this.certficateForm.get('prevPolicyExpire').value == this.policyExpireArray[3]){
      packageRequest.breakInspectionReportTime = this.certficateForm.get('breakInspectionReportTimeHH').value + ':' + this.certficateForm.get('breakInspectionReportTimeMM').value;
      packageRequest.breakInspectionReportDate = this.convertTextDate(this.certficateForm.get('breakInspectionReportDate').value);
      }
      this.customerDob = this.convertTextDate(
        this.certficateForm.get('dob').value
      );
      packageRequest.dob = this.customerDob;
    }

    packageRequest.issueDateString =
      this.issueDate.getFullYear() +
      '-' +
      ('0' + (this.issueDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + this.issueDate.getDate()).slice(-2);
    packageRequest.startDate = this.certficateForm.get('issueDate').value;
    packageRequest.financeCompanyName =
      this.certficateForm.get('financeCompanyName').value;
    packageRequest.financeCoverAmount =
      this.certficateForm.get('financeCoverAmount').value;
    packageRequest.aadhaarUrl = this.aadharUrl ? this.aadharUrl : '';
    packageRequest.invoiceUrl = this.invoiceUrl;
    packageRequest.breakInspectionReportUrl = this.breakInspectionReportUrl;
    packageRequest.panUrl = this.panUrl;
    packageRequest.packageNumber = this.packageNumber;
    packageRequest.package = this.selectedPackage;
    packageRequest.packageStatus = 'Submitted';
    packageRequest.certificateStatus = 'Started';
    packageRequest.policyStatus = 'Started';
    packageRequest.dealerConfigFound = this.dealarConfigFound;
    packageRequest.dealerModelPrice = this.selectDealerModelPrice;
    console.log(JSON.stringify(packageRequest));
    if (packageRequest.registrationNumber.trim().length == 0) {
      packageRequest.registrationNumber = 'New';
    }

    this.packageService
      .issuePackage(packageRequest)
      .pipe(first())
      .subscribe({
        next: (x) => {
          this.certificateResponse = new Package(x);
          this.certificateCreated = true;
          this.packageService.printPackage = this.certificateResponse;
          this.router.navigate(['../issueCertificate/confirmPackage'], {
            relativeTo: null,
          });
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        },
      });

    return;
  }
  paymentGatewayShowToggle() {
    this.showPaymentGatewayForm = !this.showPaymentGatewayForm;
    console.log('i am here');
  }
  convertTextDate(value: NgbDateStruct) {
    if (value) {
      this.dobString = value.year.toString();
      if (value.month < 10) {
        this.dobString = this.dobString + '-0' + value.month;
      } else {
        this.dobString = this.dobString + '-' + value.month;
      }
      if (value.day < 10) {
        this.dobString = this.dobString + '-0' + value.day;
      } else {
        this.dobString = this.dobString + '-' + value.day;
      }
    }
    return this.dobString;
  }
  onReset() {
    this.certificateCreated = false;
    this.submitted = false;
    this.alertService.clear();
    window.location.reload();
  }
  financeSelected(inputFinance: string) {
    this.certficateForm.controls['financeCompanyName'].setValue(inputFinance);
    this.selectedFinance = inputFinance;
  }

  maintainClientType() {
    this.certficateForm.get('clientType').valueChanges.subscribe((value) => {
      if (value == 'Individual') {
        this.isCorporate = false;
        this.certficateForm.controls['nomineeGender'].setValue(this.genders[1]);
        this.certficateForm.get('gender').setValidators(Validators.required);
        this.certficateForm.get('dob').setValidators(Validators.required);
        this.certficateForm
          .get('nomineeRelationship')
          .setValidators(Validators.required);
        this.certficateForm.get('employeeContactNumber').clearValidators();
        this.certficateForm
          .get('nomineeName')
          .setValidators(Validators.required);
        this.certficateForm
          .get('nomineeAge')
          .setValidators(Validators.required);
        // if (this.f.type.value != 'Old') {
          this.f.aadhaarNumber.clearValidators();
          this.f.aadhaarNumber.setValidators([
            Validators.required,
            Validators.pattern('[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$'),
          ]);
          this.certficateForm.controls[
            'aadhaarNumber'
          ].updateValueAndValidity();
        // }
      } else {
        this.isCorporate = true;
        this.certficateForm.controls['nomineeGender'].setValue(this.genders[0]);
        this.certficateForm.get('gender').clearValidators();
        this.certficateForm.get('dob').clearValidators();
        this.f.dob.setValue({ year: 1001, month: 1, day: 1 });
        // if (this.f.type.value != 'Old') {
          this.f.aadhaarNumber.clearValidators();
          this.f.aadhaarNumber.setValidators([Validators.required]);
          this.certficateForm.controls[
            'aadhaarNumber'
          ].updateValueAndValidity();
        // }
        this.certficateForm.get('nomineeName').clearValidators();
        this.certficateForm.get('nomineeRelationship').clearValidators();
        this.certficateForm.get('nomineeAge').clearValidators();
        this.certficateForm
          .get('employeeContactNumber')
          .setValidators([Validators.required]);
        this.certficateForm.controls['nomineeGender'].setValue(this.genders[1]);
        this.certficateForm.controls['nomineeRelationship'].setValue(
          this.relationships[0]
        );
        this.certficateForm.controls['nomineeName'].setValue('');
        this.certficateForm.controls['nomineeAge'].setValue(20);
      }
      this.certficateForm.controls['gender'].updateValueAndValidity();
      this.certficateForm.controls['dob'].updateValueAndValidity();
      this.certficateForm.controls[
        'nomineeRelationship'
      ].updateValueAndValidity();
      this.certficateForm.controls[
        'employeeContactNumber'
      ].updateValueAndValidity();
      this.certficateForm.controls['nomineeName'].updateValueAndValidity();
      this.certficateForm.controls['nomineeAge'].updateValueAndValidity();
    });
  }

  maintainNomineeValidators() {
    if (!this.isCorporate) {
      this.certficateForm.controls['nomineeGender'].setValue(this.genders[1]);
      this.certficateForm.controls['nomineeRelationship'].setValue(
        this.relationships[0]
      );
      this.certficateForm.controls['nomineeName'].setValue('');
      this.certficateForm.controls['nomineeAge'].setValue(20);
    }
  }
  maintainDateTimeValueChanges() {
    this.certficateForm.get('startDate').valueChanges.subscribe((value) => {
      if (value != undefined) {
        if (
          value.month != undefined &&
          value.day != undefined &&
          value.year != undefined
        ) {
          this.startDate = new Date(this.convertTextDate(value));
          if (
            parseInt(value.month) == this.currentDate.getMonth() + 1 &&
            parseInt(value.day) == this.currentDate.getDate() &&
            parseInt(value.year) == this.currentDate.getFullYear()
          )
            this.startDate = new Date(this.currentDate);
          else this.startDate = new Date(this.startDate.setHours(0, 0, 0, 0));

          this.endDate = new Date(this.startDate);
          this.endDate = new Date(
            this.endDate.setFullYear(this.endDate.getFullYear() + 1)
          );
          this.endDate = new Date(
            this.endDate.setDate(this.endDate.getDate() - 1)
          );
          this.endDate = new Date(this.endDate.setHours(0, 0, 0, 0));
          this.certficateForm.controls['endDate'].setValue(this.endDate);
          this.certficateForm.controls['issueDate'].setValue(this.startDate);
          this.issueDate = this.startDate;
        }
      }
    });
    this.certficateForm.get('dob').valueChanges.subscribe((value) => {
      if (value && value.year && value.year != 1001) {
        this.certficateForm.controls['age'].setValue(
          this.currentDate.getFullYear() - parseInt(value.year)
        );
      }
    });
    this.certficateForm.get('vehicleYear').valueChanges.subscribe((value) => {
      if (value) {
        this.selectedVehicleYear = value;
        if (this.f.type.value == 'Old') {
          this.registrationDate.year = value;
          this.registrationDate.month = this.currentDate.getMonth() + 1;
          this.f.vehicleRegistrationDate.setValue(this.registrationDate);
          this.minRegistrationDateModel.year = value;
          this.minRegistrationDateModel.day = 1;
          this.minRegistrationDateModel.month = 1;
          this.vehicleAge = this.currentDate.getFullYear() - value + 1;
          if(this.vehicleAge > 5)
          {
            this.f.nilDep.setValue('No');
          }
          else
          {
            this.f.nilDep.setValue('Yes');
          }
          this.f.nilDep.updateValueAndValidity();
        }
        else
      {this.vehicleAge = 0;}
        this.selectedNoClaimBonusYear = this.noClaimBonusYear?.filter(a=>a.ncbYear >= value);
        this.calculateIdv();
      }
    });  
    this.certficateForm.get('type').valueChanges.subscribe((value) => {
      if (value == 'Old') {
        this.dealerConfig = this.allDealerConfig.find(a=>[undefined,"Both",value].includes(a.applicableOn));
        this.f.oldValueType.setValue('PrevIdv');
        if (this.dealerConfig && this.dealerConfig.dealerModelPrices.length > 0) {
          this.dealarConfigFound = true;
          this.getMakeModelfromDealerConfig();
        } else {
          this.dealarConfigFound = false;
          this.setDefaultMakeModel();
        }
        this.certficateForm.controls['vehicleYear'].setValue(
          this.currentDate.getFullYear()
        );
        this.certficateForm.get('registrationNumber').setValue('');
        this.certficateForm
          .get('registrationNumber')
          .setValidators(Validators.required);
        this.certficateForm.get('idv').clearValidators();
        this.certficateForm
          .get('prevPolicyExpire')
          .setValidators(Validators.required);
        this.certficateForm
          .get('prePolClaim')
          .setValidators(Validators.required);
        this.certficateForm
          .get('transferCase')
          .setValidators(Validators.required);
        this.noClaimBonusYear =[{ncbYear:this.currentDate.getFullYear(),ncbPercentage:"0"}
        ,{ncbYear:this.currentDate.getFullYear()-1,ncbPercentage:"1"}
        ,{ncbYear:this.currentDate.getFullYear()-2,ncbPercentage:"2"}
        ,{ncbYear:this.currentDate.getFullYear()-3,ncbPercentage:"3"}
        ,{ncbYear:this.currentDate.getFullYear()-4,ncbPercentage:"4"}
        ,{ncbYear:this.currentDate.getFullYear()-5,ncbPercentage:"5"}
        ,{ncbYear:this.currentDate.getFullYear()-6,ncbPercentage:"5"}
        ,{ncbYear:this.currentDate.getFullYear()-7,ncbPercentage:"5"}
        ,{ncbYear:this.currentDate.getFullYear()-8,ncbPercentage:"5"}
        ,{ncbYear:this.currentDate.getFullYear()-9,ncbPercentage:"5"}
        ,{ncbYear:this.currentDate.getFullYear()-10,ncbPercentage:"5"}
        ,{ncbYear:this.currentDate.getFullYear()-11,ncbPercentage:"5"}
        ,{ncbYear:this.currentDate.getFullYear()-12,ncbPercentage:"5"}
        ,{ncbYear:this.currentDate.getFullYear()-13,ncbPercentage:"5"}
        ,{ncbYear:this.currentDate.getFullYear()-14,ncbPercentage:"5"}
        ,{ncbYear:this.currentDate.getFullYear()-15,ncbPercentage:"5"}          
      ]
        this.years = [
          this.currentDate.getFullYear(),
          this.currentDate.getFullYear() - 1,
          this.currentDate.getFullYear() - 2,
          this.currentDate.getFullYear() - 3,
          this.currentDate.getFullYear() - 4,
          this.currentDate.getFullYear() - 5,
          this.currentDate.getFullYear() - 6,
          this.currentDate.getFullYear() - 7,
          this.currentDate.getFullYear() - 8,
          this.currentDate.getFullYear() - 9,
          this.currentDate.getFullYear() - 10,
          this.currentDate.getFullYear() - 11,
          this.currentDate.getFullYear() - 12,
          this.currentDate.getFullYear() - 13,
          this.currentDate.getFullYear() - 14,
        ];
        this.certficateForm.get('aadhaarFile').clearValidators();
        // this.certficateForm.get('aadhaarNumber').clearValidators();
        this.certficateForm.get('invoiceValue').clearValidators();
        this.certficateForm.get('invoiceFile').clearValidators();
      } else {
        this.f.oldValueType.setValue('Invoice');
        this.dealerConfig = this.allDealerConfig.find(a=>[undefined,"Both",value].includes(a.applicableOn));
        if (this.dealerConfig && this.dealerConfig.dealerModelPrices.length > 0) {
          this.dealarConfigFound = true;
          this.getMakeModelfromDealerConfig();
        } else {
          this.dealarConfigFound = false;
          this.setDefaultMakeModel();
        }
        this.years = [
          this.currentDate.getFullYear(),
          this.currentDate.getFullYear() - 1,
          this.currentDate.getFullYear() - 2,
        ];
        this.noClaimBonusYear =[{ncbYear:this.currentDate.getFullYear(),ncbPercentage:"0"}];
        this.certficateForm.get('registrationNumber').setValue('New');
        this.certficateForm.get('registrationNumber').clearValidators();
        this.certficateForm.get('idv').setValidators(Validators.required);
        if (this.dealer.makeInvoiceUploadMandatory) {
          this.certficateForm.controls['invoiceFile'].setValidators(
            Validators.required
          );
        }
        if (this.dealer.makeInvoiceValueMandatory) {
          this.certficateForm.controls['invoiceValue'].setValidators(
            Validators.required
          );
        }
        if (this.dealarConfigFound) {
          if (
            this.selectDealerModelPrice &&
            this.selectDealerModelPrice.aadharUploadMandatory &&
            this.selectDealerModelPrice.aadharUploadMandatory.toLowerCase() ==
              'yes'
          ) {
            this.certficateForm
              .get('aadhaarFile')
              .setValidators(Validators.required);
          } else {
            this.certficateForm.get('aadhaarFile').clearValidators();
          }
        } else {
          if (this.dealer.makeAadharUploadMandatory) {
            this.certficateForm
              .get('aadhaarFile')
              .setValidators(Validators.required);
          } else {
            this.certficateForm.get('aadhaarFile').clearValidators();
          }
        }
        // this.certficateForm
        //   .get('aadhaarNumber')
        //   .setValidators([
        //     Validators.required,
        //     Validators.pattern('[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$'),
        //   ]);
        this.certficateForm.get('prevPolicyExpire').clearValidators();
        this.certficateForm.get('prePolClaim').clearValidators();
        this.certficateForm.get('transferCase').clearValidators();
      }
      this.selectedPackages = this.dealerPackages?.filter(a => a.applicableOn == value);
      this.certficateForm.controls[
        'registrationNumber'
      ].updateValueAndValidity();
      this.certficateForm.get('idv').updateValueAndValidity();
      this.certficateForm.get('prevPolicyExpire').updateValueAndValidity();
      this.certficateForm.get('prePolClaim').updateValueAndValidity();
      this.certficateForm.get('transferCase').updateValueAndValidity();
      this.certficateForm.get('aadhaarFile').updateValueAndValidity();
      // this.certficateForm.get('aadhaarNumber').updateValueAndValidity();
      this.certficateForm.get('invoiceValue').updateValueAndValidity();
      this.certficateForm.get('invoiceFile').updateValueAndValidity();
      if (!this.renewCertificate)
        this.certficateForm.controls['vehicleYear'].setValue(this.years[0]);
    
      this.f.model.setValue('');
      this.f.vehicleMake.setValue(this.makeModel[0].make);
      this.f.packageId.setValue(this.selectedPackages[0].packageId);
      });
  }

  maintainModelandMakeValueChanges() {
    this.certficateForm.get('prePolClaim').valueChanges.subscribe((value) => {
      if(value){
        this.prePolClaim = value;
        this.calculateOdPremium(this.f.idv.value);
      }
    });
    this.certficateForm.get('tpPdCover').valueChanges.subscribe((value) => {
      if(value){
        this.tpPdCover = value;
        this.calculateOdPremium(this.f.idv.value);
      }
    });
    this.certficateForm.get('nilDep').valueChanges.subscribe((value) => {
      if (value) {
        this.nilDep = value;
        this.calculateOdPremium(this.f.idv.value);
      }
    });
    this.certficateForm.get('rtiCover').valueChanges.subscribe((value) => {
      if (value) {
        this.rtiCover = value;
        this.calculateOdPremium(this.f.idv.value);
      }
    });
    this.certficateForm.get('idv').valueChanges.subscribe((value) => {
      if (value) {
        this.calculateOdPremium(value);
      }
    });
    this.certficateForm.get('invoiceValue').valueChanges.subscribe((value) => {
      if (value) {
        this.selectDealerModelPrice.exShowroomAmount = value;
        this.calculateIdv();
      }
    });
    // this.certficateForm.get('oldValueType').valueChanges.subscribe((value) => {
    //   if (value) {
    //     this.selectDealerModelPrice.exShowroomAmount = this.f.invoiceValue.value;
    //     this.calculateIdv();
    //   }
    // });
    this.certficateForm.get('model').valueChanges.subscribe((value) => {
      if (value == 'Others') {
        this.certficateForm.get('variant').setValidators(Validators.required);
      } else {
        this.certficateForm.get('variant').clearValidators();
      }
      this.certficateForm.controls['variant'].updateValueAndValidity();
    });
    this.certficateForm.get('vehicleMake').valueChanges.subscribe((value) => {
      if (value != undefined) {
        if (this.dealarConfigFound ) {
          this.selectedMakeModel = this.makeModel.find((a) => a.make == value);
          this.certficateForm.controls['vehicleType'].setValue(
            this.selectedMakeModel?.productType
          );
          this.models = this.selectedMakeModel?.model;
        } else {
          this.configDataService.MakeModelVersion$.subscribe((x) => {
            this.selectedMakeModel = x.find((a) => a.make == value);
            this.certficateForm.controls['vehicleType'].setValue(
              this.selectedMakeModel?.productType
            );
            this.models = this.selectedMakeModel?.model;
          });
        }
      }
    });
    this.certficateForm.get('model').valueChanges.subscribe((value) => {
      if (value) {
        if (this.dealarConfigFound) {
          this.selectDealerModelPrice =
            this.dealerConfig.dealerModelPrices.find((a) => a.model == value);
          this.f.variant.setValue(this.selectDealerModelPrice.heroVariant);
          this.selectDealerModelPrice.exShowroomAmount = this
            .selectDealerModelPrice.exShowroomAmount
            ? this.selectDealerModelPrice.exShowroomAmount
            : 0;
          this.selectDealerModelPrice.iDVPercentage = this
            .selectDealerModelPrice.iDVPercentage
            ? this.selectDealerModelPrice.iDVPercentage
            : 95;
          this.selectDealerModelPrice.totalAmount = this.selectDealerModelPrice
            .totalAmount
            ? this.selectDealerModelPrice.totalAmount
            : 0;
          this.selectDealerModelPrice.cubicCapacity = this
            .selectDealerModelPrice.cubicCapacity
            ? parseInt(this.selectDealerModelPrice.cubicCapacity.toString())
            : 0;
          this.f.cubicCapacity.setValue(
            this.selectDealerModelPrice.cubicCapacity
          );
          if (this.f.type.value == 'Old') {
            this.selectDealerModelPrice.exShowroomAmount = 0;
          }

          this.f.invoiceValue.setValue(
            this.selectDealerModelPrice.exShowroomAmount
          );
          this.f.packagePolicyTotalAmount.setValue(
            this.selectDealerModelPrice.totalAmount
          );
          if (this.selectDealerModelPrice.invoiceUploadMandatory) {
            if (
              this.selectDealerModelPrice.invoiceUploadMandatory.toLowerCase() ==
              'yes'
            ) {
              this.certficateForm.controls['invoiceFile'].setValidators(
                Validators.required
              );
            } else {
              if (
                this.selectDealerModelPrice.invoiceUploadMandatory.toLowerCase() ==
                'no'
              ) {
                this.certficateForm.controls['invoiceFile'].clearValidators();
              }
            }
            this.certficateForm.controls[
              'invoiceFile'
            ].updateValueAndValidity();
          }

          if (this.selectDealerModelPrice.panUploadMandatory) {
            if (
              this.selectDealerModelPrice.panUploadMandatory.toLowerCase() ==
              'yes'
            ) {
              this.certficateForm.controls['panFile'].setValidators(
                Validators.required
              );
            } else {
              if (
                this.selectDealerModelPrice.panUploadMandatory.toLowerCase() ==
                'no'
              ) {
                this.certficateForm.controls['panFile'].clearValidators();
              }
            }
            this.certficateForm.controls['panFile'].updateValueAndValidity();
          }

          if (this.selectDealerModelPrice.aadharUploadMandatory) {
            if (
              this.selectDealerModelPrice.aadharUploadMandatory.toLowerCase() ==
              'yes'
            ) {
              this.certficateForm.controls['aadhaarFile'].setValidators(
                Validators.required
              );
            } else {
              if (
                this.selectDealerModelPrice.aadharUploadMandatory.toLowerCase() ==
                'no'
              ) {
                this.certficateForm.controls['aadhaarFile'].clearValidators();
              }
            }
            this.certficateForm.controls[
              'aadhaarFile'
            ].updateValueAndValidity();
          }
        }
      }
      else
      {
        this.f.variant.setValue('');
        this.f.cubicCapacity.setValue(0);
        this.f.invoiceValue.setValue(0);
        this.f.packagePolicyTotalAmount.setValue(0);
        this.f.idv.setValue(0);
      }
    });
  }
  calculateOdPremium(idv: number) {
    this.odPremium = 0;

    if (this.f.type.value == 'Old') {
      if (this.selectedPremiumCalc.odBaseRate) {
          this.odPremium = this.selectedPremiumCalc.odBaseRate * idv;
          this.f.odPremiumRate.setValue(this.selectedPremiumCalc.odBaseRate);
          this.f.odPremiumAmount.setValue(this.odPremium);
        }
        console.log("stage 1:" + this.odPremium);
      if(this.selectedPackage && this.selectedPackage.odDiscount > 0){
        this.f.odDiscountAmount.setValue(this.odPremium * this.selectedPackage.odDiscount);
        this.odPremium -= this.odPremium * this.selectedPackage.odDiscount;
        this.f.odDiscountPercentage.setValue(this.selectedPackage.odDiscount);
      }
        console.log("stage 2:" + this.odPremium);
        let odNCBAmount = 0;
        let odNCBPercentage = 0
        if (this.prePolClaim == 'No' && this.selectedPremiumCalc.ncbDiscount[this.vehicleAge.toString()]){
          odNCBAmount = this.odPremium * this.selectedPremiumCalc.ncbDiscount[this.vehicleAge.toString()];
          odNCBPercentage = this.selectedPremiumCalc.ncbDiscount[this.vehicleAge.toString()];
          // this.f.odNCBAmount.setValue(this.odPremium * this.selectedPremiumCalc.ncbDiscount[this.vehicleAge.toString()]);
          this.odPremium -= this.odPremium * this.selectedPremiumCalc.ncbDiscount[this.vehicleAge.toString()];
            // this.f.odNCBPercentage.setValue(this.selectedPremiumCalc.ncbDiscount[this.vehicleAge.toString()]);
        }
        else
          if (this.prePolClaim == 'Yes' && this.ncbPercentValue && this.selectedPremiumCalc.ncbDiscount[this.ncbPercentValue]){
            odNCBAmount = this.odPremium * this.selectedPremiumCalc.ncbDiscount[this.ncbPercentValue];
            odNCBPercentage = this.selectedPremiumCalc.ncbDiscount[this.ncbPercentValue];
            // this.f.odNCBAmount.setValue(this.odPremium * this.selectedPremiumCalc.ncbDiscount[this.ncbPercentValue]);
            this.odPremium -= this.odPremium * this.selectedPremiumCalc.ncbDiscount[this.ncbPercentValue];
          // this.f.odNCBPercentage.setValue(this.selectedPremiumCalc.ncbDiscount[this.ncbPercentValue]);
        }
        this.f.odNCBAmount.setValue(odNCBAmount);
        this.f.odNCBPercentage.setValue(odNCBPercentage);
  
        console.log("stage 3:" + this.odPremium);
        // console.log(this.selectedPremiumCalc.ndCover[this.vehicleAge.toString()]);
        // console.log(this.nilDep);
        // console.log(this.vehicleAge)
        if (this.nilDep == 'Yes' && this.selectedPremiumCalc.ndCover[this.vehicleAge.toString()]) {
        this.odPremium += idv * this.selectedPremiumCalc.ndCover[this.vehicleAge.toString()];
        this.f.nilDepAmount.setValue(idv * this.selectedPremiumCalc.ndCover[this.vehicleAge.toString()]);
        this.f.nilDepPercentage.setValue(this.selectedPremiumCalc.ndCover[this.vehicleAge.toString()]);
      }
      console.log("stage 4:" + this.odPremium);
      if (this.rtiCover == 'Yes' && this.selectedPremiumCalc.rtiCover[this.vehicleAge.toString()]) {
        this.odPremium += idv * this.selectedPremiumCalc.rtiCover[this.vehicleAge.toString()];
        this.f.rtiCoverAmount.setValue(idv * this.selectedPremiumCalc.rtiCover[this.vehicleAge.toString()]);
        this.f.rtiCoverPercentage.setValue(this.selectedPremiumCalc.rtiCover[this.vehicleAge.toString()]);
      }
      console.log("stage 4:" + this.odPremium);
      console.log(JSON.stringify(this.selectedPackage));
      if (this.selectedPackage) { 
        if (this.selectedPackage.tpApplicable) {
          this.odPremium += this.selectedPremiumCalc.tpRates;
          this.f.tpPremiumAmount.setValue(this.selectedPremiumCalc.tpRates);
          console.log("stage 5:" + this.odPremium);
          this.odPremium += this.dealer.dealerPlan.find(a => a.planTotalAmount > 0 && (a.applicableOnlyOnPolicyType && ["Old","One year OD Plus TP Renewal"].includes(a.applicableOnlyOnPolicyType)))?.planAmount;
          this.f.CpaCoverAmount.setValue(this.dealer.dealerPlan.find(a => a.planTotalAmount > 0 && (a.applicableOnlyOnPolicyType && ["Old","One year OD Plus TP Renewal"].includes(a.applicableOnlyOnPolicyType)))?.planAmount);
          this.f.cpaCoverPlanName.setValue(this.dealer.dealerPlan.find(a => a.planTotalAmount > 0 && (a.applicableOnlyOnPolicyType && ["Old","One year OD Plus TP Renewal"].includes(a.applicableOnlyOnPolicyType)))?.planId);
          if (this.tpPdCover == 'Yes') {
            this.odPremium += this.selectedPremiumCalc.tpPdCover;
            this.f.tpPdCoverAmount.setValue(this.selectedPremiumCalc.tpPdCover);
          }
          else
          {
            this.f.tpPdCoverAmount.setValue(0);
          }
        }
        else
        {
          console.log("stage 5:" + this.odPremium);
          this.odPremium += this.dealer.dealerPlan.find(a => a.planTotalAmount > 0 && (a.applicableOnlyOnPolicyType && ["Old","One year Od Renewal"].includes(a.applicableOnlyOnPolicyType)))?.planAmount;
          this.f.CpaCoverAmount.setValue(this.dealer.dealerPlan.find(a => a.planTotalAmount > 0 && (a.applicableOnlyOnPolicyType && ["Old","One year Od Renewal"].includes(a.applicableOnlyOnPolicyType)))?.planAmount);
          this.f.cpaCoverPlanName.setValue(this.dealer.dealerPlan.find(a => a.planTotalAmount > 0 && (a.applicableOnlyOnPolicyType && ["Old","One year Od Renewal"].includes(a.applicableOnlyOnPolicyType)))?.planId);
          this.f.tpPremiumAmount.setValue(0);
          this.f.tpPdCoverAmount.setValue(0);
        }       
    }
      console.log("stage 6:" + this.odPremium);

      this.f.totalGstAmount.setValue(this.odPremium * this.configDataService.getGST());
      this.odPremium += this.odPremium * this.configDataService.getGST()
    console.log("stage 7:" + this.odPremium);
    this.odPremium = parseInt(this.odPremium.toFixed(2));
    console.log("stage 8:" + this.odPremium);
  }
  else
  {
    this.odPremium = this.selectDealerModelPrice.totalAmount;
  } 
  this.f.packagePolicyTotalAmount.setValue(this.odPremium);

  }
  calculateIdv() {
    let idv = 0;
    if(this.f.invoiceValue.value == 0)
    {
      this.f.idv.setValue(0);
      if (this.f.type.value == 'Old') {
      this.f.packagePolicyTotalAmount.setValue(0);
    }
      return;
    }
    if (this.f.type.value == 'Old' && this.selectedVehicleYear && this.selectedVehicleYear > 2000 && this.f.cubicCapacity.value && this.f.cubicCapacity.value > 0 ) {
      if (this.f.oldValueType.value == 'Invoice') {
      idv = this.selectedPremiumCalc.ageDepreciation[this.vehicleAge.toString()] ? parseInt((this.selectDealerModelPrice.exShowroomAmount * (1 - this.selectedPremiumCalc.ageDepreciation[this.vehicleAge.toString()])).toFixed(2)):0;
    }
    else
    {
      idv =  parseInt((this.selectDealerModelPrice.exShowroomAmount * 90 /100).toFixed(2));
    }
    }
    else
    {
      idv =  parseInt((this.selectDealerModelPrice.exShowroomAmount * this.selectDealerModelPrice.iDVPercentage /100).toFixed(2));
    }
    this.f.idv.setValue(idv? idv:0);
  }
  maintainMiscValueChanges() {
    this.certficateForm.get('ncbPercentValue').valueChanges.subscribe((value) => {
      if (value) {
        this.ncbPercentValue = value;
        this.calculateOdPremium(this.f.idv.value);
      }
    });
    this.certficateForm.get('cubicCapacity').valueChanges.subscribe((value) => {
      if (value) {
        if (this.dealarConfigFound) {
          this.selectDealerModelPrice.cubicCapacity = value;
        }
        this.selectedPremiumCalc = this.premiumCalculation[this.getCubicCapacityOption(value)];
        this.calculateIdv();
      }
    });
    this.certficateForm.get('packageId').valueChanges.subscribe((value) => {
      if (value) {
        this.selectedPackage = this.selectedPackages.find(
          (a) => a.packageId == value
        );
        this.calculateOdPremium(this.f.idv.value);
        if (
          this.selectedPackage.prevInsuranceDetailShow.includes('GetODDetails')
        ) {
          this.certficateForm
            .get('prevOdInsurer')
            .setValidators(Validators.required);
          this.certficateForm
            .get('prevOdPolicyNo')
            .setValidators(Validators.required);
          this.certficateForm
            .get('prevOdPolicyExpiry')
            .setValidators(Validators.required);
        } else {
          this.certficateForm.get('prevOdInsurer').clearValidators();
          this.certficateForm.get('prevOdPolicyNo').clearValidators();
          this.certficateForm.get('prevOdPolicyExpiry').clearValidators();
        }
        this.certficateForm.get('prevOdInsurer').updateValueAndValidity();
        this.certficateForm.get('prevOdPolicyNo').updateValueAndValidity();
        this.certficateForm.get('prevOdPolicyExpiry').updateValueAndValidity();
        if (
          this.selectedPackage.prevInsuranceDetailShow.includes('GetTPDetails')
        ) {
          this.certficateForm
            .get('prevTpInsurer')
            .setValidators(Validators.required);
          this.certficateForm
            .get('prevTpPolicyNo')
            .setValidators(Validators.required);
          this.certficateForm
            .get('prevTpPolicyExpiry')
            .setValidators(Validators.required);
        } else {
          this.certficateForm.get('prevTpInsurer').clearValidators();
          this.certficateForm.get('prevTpPolicyNo').clearValidators();
          this.certficateForm.get('prevTpPolicyExpiry').clearValidators();
        }
        this.certficateForm.get('prevTpInsurer').updateValueAndValidity();
        this.certficateForm.get('prevTpPolicyNo').updateValueAndValidity();
        this.certficateForm.get('prevTpPolicyExpiry').updateValueAndValidity();
      }
    });

    // this.certficateForm.get('insuranceCompany').valueChanges.subscribe((value) => {
    //   if (value){
    //     this.selectedPackages = this.dealerPackages.filter(a=> a.applicableOn == this.certficateForm.controls['type'].value);
    //   }
    //   else
    //   {
    //     this.selectedPackages = this.dealerPackages;
    //   }
    // });

    this.certficateForm.get('panNumber').valueChanges.subscribe((value) => {
      if (value) {
        this.certficateForm
          .get('panNumber')
          .addValidators([
            this.trimValidator,
            this.capsValidator,
            Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$'),
          ]);
      } else {
        this.certficateForm
          .get('panNumber')
          .removeValidators([
            this.trimValidator,
            this.capsValidator,
            Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$'),
          ]);
      }
      this.certficateForm
        .get('panNumber')
        .updateValueAndValidity({ emitEvent: false });
    });
    this.certficateForm.get('ageOrDob').valueChanges.subscribe((value) => {
      if (value != undefined) {
        if (value == this.ageOrDobValue[1]) {
          // this.certficateForm.get('age').setValidators([Validators.required]);
          // this.certficateForm.get('age').setValidators(Validators.min(18));
          // this.certficateForm.get('age').setValidators(Validators.max(80));
          this.certficateForm.get('dob').clearValidators();
          this.minDobDateModel.year = 1001;
          this.minDobDateModel.month = 1;
          this.minDobDateModel.day = 1;
          this.certficateForm.controls['dob'].setValue(this.minDobDateModel);
        } else {
          {
            this.maxDobDateModel.year = this.maxDobDate.getFullYear();
            this.maxDobDateModel.day = this.maxDobDate.getDate();
            this.maxDobDateModel.month = this.maxDobDate.getMonth() + 1;
            this.minDobDateModel.year = this.minDobDate.getFullYear();
            this.minDobDateModel.day = this.minDobDate.getDate();
            this.minDobDateModel.month = this.minDobDate.getMonth() + 1;
            this.certficateForm.controls['dob'].setValue(this.maxDobDateModel);
            this.certficateForm.get('dob').setValidators(Validators.required);
            this.certficateForm.get('age').clearValidators();
          }
        }
        this.certficateForm.get('age').updateValueAndValidity();
        this.certficateForm.get('dob').updateValueAndValidity();
      }
    });

    this.certficateForm.get('state').valueChanges.subscribe((value) => {
      if (value != undefined) {
        this.configDataService.StateCity$.subscribe(
          (x) => (this.cities = x.find((a) => a.state == value).city)
        );
      }
    });
    this.certficateForm
      .get('financeCompanyState')
      .valueChanges.subscribe((value) => {
        if (value != undefined) {
          this.configDataService.StateCity$.subscribe(
            (x) => (this.finCities = x.find((a) => a.state == value).city)
          );
        }
      });

    this.certficateForm
      .get('prevPolicyExpire')
      .valueChanges.subscribe((value) => {
        if (value) {
          if ((value == this.policyExpireArray[2]) || (value == this.policyExpireArray[3])) {
            this.certficateForm.get('breakInspectionReportFile').setValidators(Validators.required);
            this.certficateForm.get('breakInspectionReportDate').setValidators(Validators.required);
            this.certficateForm.get('breakInspectionReportTimeHH').setValidators(Validators.required);
            this.certficateForm.get('breakInspectionReportTimeMM').setValidators(Validators.required);
            this.certficateForm.get('breakInspectionReportNumber').setValidators(Validators.required);
            if (this.editPackage)
            {
              this.certficateForm.get('breakInspectionReportDate').setValue(this.convertYYYYMMDDTongbDate(this.packageService.printPackage.breakInspectionReportDate))
              this.certficateForm.get('breakInspectionReportTimeHH').setValue(this.packageService.printPackage.breakInspectionReportTime.slice(0,2));
              this.certficateForm.get('breakInspectionReportTimeMM').setValue(this.packageService.printPackage.breakInspectionReportTime.slice(3,5));

            }
          }
          else
          {
            this.certficateForm.get('breakInspectionReportFile').clearValidators();
            this.certficateForm.get('breakInspectionReportDate').clearValidators();
            this.certficateForm.get('breakInspectionReportTimeHH').clearValidators();
            this.certficateForm.get('breakInspectionReportTimeMM').clearValidators();
            this.certficateForm.get('breakInspectionReportNumber').clearValidators();
          }
          this.certficateForm.get('breakInspectionReportFile').updateValueAndValidity();
          this.certficateForm.get('breakInspectionReportDate').updateValueAndValidity();
          this.certficateForm.get('breakInspectionReportTimeHH').updateValueAndValidity();
          this.certficateForm.get('breakInspectionReportTimeMM').updateValueAndValidity();
          this.certficateForm.get('breakInspectionReportNumber').updateValueAndValidity();

          if (value == this.policyExpireArray[3]) {
            this.minOdDate = new Date('2010-12-31');
            this.maxOdDate = new Date(
              new Date().setDate(this.currentDate.getDate() - 90)
            );
          } else {
            if (value == this.policyExpireArray[2]) {
              this.minOdDate = new Date(
                new Date().setDate(this.currentDate.getDate() - 90)
              );
              this.maxOdDate = new Date(this.currentDate);
            } else {
              this.minOdDate = new Date();
              this.maxOdDate = new Date('2999-12-31');
            }
          }
          this.maxOdDateModel.year = this.maxOdDate.getFullYear();
          this.maxOdDateModel.month = this.maxOdDate.getMonth() + 1;
          this.maxOdDateModel.day = this.maxOdDate.getDate();
          this.minOdDateModel.year = this.minOdDate.getFullYear();
          this.minOdDateModel.month = this.minOdDate.getMonth() + 1;
          this.minOdDateModel.day = this.minOdDate.getDate();
        }
      });

    this.certficateForm.get('hypothecated').valueChanges.subscribe((value) => {
      if (value != undefined) {
        if (value == 'Yes') {
          this.certficateForm
            .get('financeCompanyName')
            .setValidators(Validators.required);
          // this.certficateForm.get('financeCompanyAddress').setValidators(Validators.required);
          this.certficateForm
            .get('financeCompanyState')
            .setValidators(Validators.required);
          this.certficateForm
            .get('financeCompanyCity')
            .setValidators(Validators.required);
        } else {
          this.f.financeCompanyName.setValue("");
          this.f.financeCompanyState.setValue("");
          this.f.financeCompanyCity.setValue("");
          this.f.financeCompanyAddress.setValue("");
          this.certficateForm.get('financeCompanyName').clearValidators();
          // this.certficateForm.get('financeCompanyAddress').clearValidators();
          this.certficateForm.get('financeCompanyState').clearValidators();
          this.certficateForm.get('financeCompanyCity').clearValidators();
        }
        this.certficateForm.get('financeCompanyName').updateValueAndValidity();
        // this.certficateForm.get('financeCompanyAddress').updateValueAndValidity();
        this.certficateForm.get('financeCompanyState').updateValueAndValidity();
        this.certficateForm.get('financeCompanyCity').updateValueAndValidity();
      }
    });
  }
  convertYYYYMMDDTongbDate(inputDate: String): any {
    let date = new Date(inputDate.toString());
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }
  getCubicCapacityOption(cubicCapacity: number) {
    if (cubicCapacity <= 75) return 'upto75cc';
    if (cubicCapacity <= 150) return '76to150cc';
    if (cubicCapacity <= 350) return '151to350cc';
    return 'above350cc';
  }

  onAadhaarFileSelected(event) {
    this.selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('customFileName', this.packageNumber + '-Aadhaar');
    this.fileServices.fileUpload(formData).subscribe((response) => {
      this.aadharUrl = response.url;
    });
  }

  onInvoiceFileSelected(event) {
    this.selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('customFileName', this.packageNumber + '-Invoice');
    this.fileServices.fileUpload(formData).subscribe((response) => {
      this.invoiceUrl = response.url;
    });
  }
  onBreakInspectionReportFileSelected(event) {
    this.selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('customFileName', this.packageNumber + '-BreakInspectionReport');
    this.fileServices.fileUpload(formData).subscribe((response) => {
      this.breakInspectionReportUrl = response.url;
    });
  }

  onPanFileSelected(event) {
    this.selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('customFileName', this.packageNumber + '-Pan');
    this.fileServices.fileUpload(formData).subscribe((response) => {
      this.panUrl = response.url;
    });
  }

  fileUpload(formData: FormData) {
    this.loading = true;
    this.fileServices
      .fileUpload(formData)
      .pipe(first())
      .subscribe({
        next: (x) => {
          console.log(x);
          this.loading = false;
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        },
      });
  }
  trimValidator(control: AbstractControl) {
    const value = control.value;
    if (value && value.length != value.trim().length) {
      control.setValue(value.trim(), { emitEvent: false });
    }
    return null;
  }
  capsValidator(control: AbstractControl) {
    const value = control.value;
    if (value && value != value.toUpperCase()) {
      control.setValue(value.toUpperCase(), { emitEvent: false });
    }
    return null;
  }
  getSelectedPremium() {
    if (this.f.cubicCapacity.value <= 75) {
      this.selectedPremiumCalc = this.premiumCalculation['upto75cc'];
      return;
    }
    if (this.f.cubicCapacity.value <= 150) {
      this.selectedPremiumCalc = this.premiumCalculation['76to150cc'];
      return;
    }
    if (this.f.cubicCapacity.value <= 350) {
      this.selectedPremiumCalc = this.premiumCalculation['151to350cc'];
      return;
    }
    this.selectedPremiumCalc = this.premiumCalculation['above350cc'];
  }
}
