import { Package } from "./package";
import { ReportSearch } from "./reportSearch";

export class PrintCertificateState {
    reportSearch: ReportSearch;
    packageList: Package[];
    public constructor(init?: Partial<PrintCertificateState>) {
        Object.assign(this, init);
    }
    
}