import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { AddEditComponent } from './add-edit.component';
import {AddDealerComponent} from './add-dealer.component';
import {DealerListComponent} from './dealer-list.component';
import { ViewDealerComponent } from './view-dealer/view-dealer.component';
import { ListComponent } from './list.component';

const routes: Routes = [
    {
        path: '', component: LayoutComponent,
        children: [
            { path: '', component: DealerListComponent },
            { path: 'editDealer/:dealerId/add', component: AddEditComponent },
            { path: 'addDealer', component: AddDealerComponent },
            { path: 'editDealer/:dealerId/edit/:id', component: AddEditComponent },
            { path: 'editDealer/:id', component: AddDealerComponent },
            { path: 'add/:dealerId', component: AddEditComponent },
            { path: 'viewDealer', component: ViewDealerComponent },
            { path: 'viewDealer/add', component: AddEditComponent },
            { path: 'viewDealer/edit/:id', component: AddEditComponent },
            { path: 'userList', component: ListComponent },
            { path: 'userList/add', component: AddEditComponent },
            { path: 'userList/edit/:id', component: AddEditComponent },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UsersRoutingModule { }