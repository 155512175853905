<nav aria-label="breadcrumb" (click)="payoutShowToggle()">
  <ol class="breadcrumb" >
    <li class="breadcrumb-item active" aria-current="page" >
      <div class="breadcrumb-content">
      <i class="fa fa-plus breadcrumb-icon"  *ngIf="!isPayoutShow"></i>
      <i class="fa fa-minus breadcrumb-icon"  *ngIf="isPayoutShow"></i>
      <p class="h5 breadcrumb-text"> Payout Details</p>
    </div>
    </li>
  </ol>
</nav>
<div *ngIf="isPayoutShow">
  <a (click)="payoutPlanFormToggle()" class="btn btn-sm btn-success mb-2">Create Payout</a>&nbsp;
  <a (click)="payoutDetailFormToggle()" class="btn btn-sm btn-primary mb-2">Show Past Transactions</a>&nbsp;
  <a (click)="payoutScreen()" class="btn btn-sm btn-primary mb-2">Payouts</a>
  <div *ngIf="isPayoutAddFormShow">
    <form [formGroup]="payoutForm" (ngSubmit)="onPayoutSubmit()">
      <div class="form-row">
        <div class="form-group col">
          <label for="payoutAmount">Payout Amount</label>
          <input type="number" formControlName="payoutAmount" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && pmntForm.payoutAmount.errors }" />
          <div *ngIf="submitted && pmntForm.payoutAmount.errors" class="invalid-feedback">
            <div *ngIf="pmntForm.payoutAmount.errors.required">Payout Amount is required</div>
          </div>
        </div>
        <div class="form-group col">
          <label for="payoutGSTAmount">Payout GST Amount</label>
          <input type="number" formControlName="payoutGSTAmount" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && pmntForm.payoutGSTAmount.errors }" />
          <div *ngIf="submitted && pmntForm.payoutGSTAmount.errors" class="invalid-feedback">
            <div *ngIf="pmntForm.payoutGSTAmount.errors.required">Payout GST Amount is required. Put zero if only payment is released</div>
          </div>
        </div>
        <div class="form-group col">
          <label for="actualPayoutAmount"> Actual Paid Amount</label>
          <input type="number" formControlName="actualPayoutAmount" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && pmntForm.actualPayoutAmount.errors }" />
          <div *ngIf="submitted && pmntForm.actualPayoutAmount.errors" class="invalid-feedback">
            <div *ngIf="pmntForm.actualPayoutAmount.errors.required">Actual Payout Amount is required</div>
          </div>
        </div>
        <div class="form-group col">
          <label for="actualPayoutGSTAmount">Actual Payout GST Amount</label>
          <input type="number" formControlName="actualPayoutGSTAmount" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && pmntForm.actualPayoutGSTAmount.errors }" />
          <div *ngIf="submitted && pmntForm.actualPayoutGSTAmount.errors" class="invalid-feedback">
            <div *ngIf="pmntForm.actualPayoutGSTAmount.errors.required">Actual Payout GST Amount is required. Put zero if only payment is released</div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label for="invoiceNumber">Invoice Number</label>
          <input type="text" formControlName="invoiceNumber" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && pmntForm.invoiceNumber.errors }" />
        </div>
        <div class="form-group col">
          <label for="receiverPanNumber">Receiver PAN Number</label>
          <input type="text" formControlName="receiverPanNumber" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && pmntForm.receiverPanNumber.errors }" />
        </div>
        <div class="form-group col">
          <label for="receiverGstNumber">Receiver GST Number</label>
          <input type="text" formControlName="receiverGstNumber" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && pmntForm.receiverGstNumber.errors }" />
        </div>
        <div class="form-group col">
          <label for="receiverAccountNumber">Receiver Account Number</label>
          <input type="text" formControlName="receiverAccountNumber" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && pmntForm.receiverAccountNumber.errors }" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label for="comments">Comments</label>
          <input type="text" formControlName="comments" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && pmntForm.comments.errors }" />
          <div *ngIf="submitted && pmntForm.comments.errors" class="invalid-feedback">
            <div *ngIf="pmntForm.comments.errors.required">Comments is required</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button><a>&nbsp;&nbsp;</a>
        <button class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
          Refresh Page
        </button>
        <a (click)="payoutPlanFormToggle()" class="btn btn-link">Cancel</a>

      </div>
      <div class="form-group">
        <p *ngIf="isBalanceUpdated"> Balance Updated!!! Please refresh page to see new balance.</p>
      </div>

    </form>
  </div>
  <div *ngIf="isPayoutDetailShow">
    <form [formGroup]="payoutDetailForm" (ngSubmit)="getPayoutDetail()">
      <div class="form-row">
        <div class="form-group col">
          <label for="certificateNumber">Number of Records</label>
          <select class="form-select" formControlName="recordCount"
            [ngClass]="{ 'is-invalid': submitted && pmntDtlForm.recordCount.errors }">
            <option *ngFor="let recordCount of numberofRecords" [ngValue]="recordCount">
              {{ recordCount }}
            </option>
          </select>
          <div *ngIf="submitted && pmntDtlForm.recordCount.errors" class="invalid-feedback">
            <div *ngIf="pmntDtlForm.recordCount.errors.required">Record Count is required</div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Get Payout Details
          </button><a>&nbsp;&nbsp;</a>
        </div>
      </div>
    </form>
    <table class="table table-striped">
      <thead>
        <tr>
          <th style="width: 30%">Payout Date</th>
          <th style="width: 30%">Payout Amount</th>
          <th style="width: 30%">Payout GST Amount</th>
          <th style="width: 30%">Comments</th>
          <th style="width: 30%">Account Received</th>
          <th style="width: 30%">Pan Number</th>
          <th style="width: 30%">GST Number</th>
          <th style="width: 30%">Invoice Number</th>
          <th style="width: 30%">Before Tx Payout Amount</th>
          <th style="width: 30%">Before Tx Payout GST Amount</th>
          <th style="width: 30%">Before Tx Pending Amount</th>
          <th style="width: 30%">Before Tx Pending GST Amount</th>
          <th style="width: 30%">Payout Username</th>
          <th style="width: 30%">Actual Paid Amount</th>
          <th style="width: 30%">Actual GST Paid Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payoutDtl of payoutDetails">
          <td>{{payoutDtl.payoutDate}}</td>
          <td>{{payoutDtl.payoutAmount}}</td>
          <td>{{payoutDtl.payoutGSTAmount}}</td>
          <td>{{payoutDtl.comments}}</td>
          <td>{{payoutDtl.receiverAccountNumber}}</td>
          <td>{{payoutDtl.receiverPanNumber}}</td>
          <td>{{payoutDtl.receiverGstNumber}}</td>
          <td>{{payoutDtl.invoiceNumber}}</td>
          <td>{{payoutDtl.totalPayoutAmountBeforeTx}}</td>
          <td>{{payoutDtl.totalGSTPayoutAmountBeforeTx}}</td>
          <td>{{payoutDtl.totalPendingAmountBeforeTx}}</td>
          <td>{{payoutDtl.totalGSTPendingAmountBeforeTx}}</td>
          <td>{{payoutDtl.userName}}</td>
          <td>{{payoutDtl.actualPayoutAmount}}</td>
          <td>{{payoutDtl.actualPayoutGSTAmount}}</td>
        </tr>
        <tr *ngIf="!payoutDetails">
          <td colspan="4" class="text-center">
            <span class="spinner-border spinner-border-lg align-center"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>