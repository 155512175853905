import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {printRouting} from './print-routing.module';
import { PrintLayoutComponent } from './print-layout/print-layout.component';

// import { QRCodeModule } from 'angularx-qrcode';
import {  ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [PrintLayoutComponent, ],
  imports: [
    CommonModule,
    printRouting,
    // QRCodeModule,
    ReactiveFormsModule
  ],
  exports: [],
  bootstrap: [],
  providers: [DatePipe]

})
export class PrintModule { }
