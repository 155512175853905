import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportLayoutComponent } from './report-layout/report-layout.component';
import { CertificateReportComponent } from './certificate-report/certificate-report.component';
import {ReportsRoutingModule} from './reports-routing.module'
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { PrintCertificateComponent } from './print-certificate/print-certificate.component';
import { DealerAggregatedReportComponent } from './dealer-aggregated-report/dealer-aggregated-report.component';
import { DealerBalanceReportComponent } from './dealer-balance-report/dealer-balance-report.component';
import { PaymentPayoutReportComponent } from './payment-payout-report/payment-payout-report.component';
import { TotalBalanceReportComponent } from './total-balance-report/total-balance-report.component';
import { ShowRequestHistoryComponent } from '../_components/show-request-history/show-request-history.component';




@NgModule({
  declarations: [ReportLayoutComponent,TotalBalanceReportComponent, CertificateReportComponent, PrintCertificateComponent, 
    DealerAggregatedReportComponent,  DealerBalanceReportComponent, PaymentPayoutReportComponent],
  imports: [
    CommonModule
    ,ReactiveFormsModule
    ,ReportsRoutingModule
    ,NgbModule
    ,AgGridModule
    ,ShowRequestHistoryComponent
  ]
})
export class ReportsModule { }
