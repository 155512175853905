import { Component, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';
import { PdfService } from '@app/_services/pdf.service';
import { CertificateService } from '@app/_services/certificate.service';
import { Certificate } from '@app/_models/certificate';
import { PrintInstruction } from '@app/_models/printInstruction';
import { AccountService, AlertService } from '@app/_services';
import { ConfigDataService } from '@app/_services/config-data.service';
import { PackageService } from '@app/issue-certificate/package.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileService } from '@app/_services/file.service';
import { DatePipe } from '@angular/common';
import { PDFDocument } from 'pdf-lib';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { first } from 'rxjs';
import { Package } from '@app/_models/package';
import { Router } from '@angular/router';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.less']
})
export class PrintLayoutComponent implements OnInit {
  isLoading = false;
  n:string[];
  role: string;
  printCpaInsurancePolicy = true;
  certificate: Certificate;
  pdfSrc: SafeResourceUrl;
  xAxis: number =98;
  yAxis: number =190;
  height: number =40;
  width: number =573;
  pdf:jsPDF;
  xPosition:number = 0;
  yPosition:number = 0;
  marginTop:number = 10.89;
  marginStart:number = 10.14;
  marginEnd:number = 10.14;
  packageForm: UntypedFormGroup;

  PAGE_WIDTH = 575;
  PAGE_HEIGHT = 821;
  mergePdfDoc :any;
  selectedPrintInstruction = new PrintInstruction({
    "policyType": "default",
    "printPage": false,
    "printPage2": false,
    "printPage3": false,
    "printPageFinance": false,
    "printPageCarCPA": false,
    "printOnlyFirstPage": false
  });
  allianceLogo: any;
  acknowledgeInd = false;
  pdfDataUri: any;
  



  constructor( private configDataService: ConfigDataService,    private router: Router,
    private alertService: AlertService,private packageService:PackageService, private fileService: FileService,private datepipe:DatePipe,private formBuilder: UntypedFormBuilder
    , private accountService: AccountService,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.pdf = new jsPDF('portrait',"pt","a4",true);
    this.packageForm = this.formBuilder.group({
      acknowledge:[false],
      mergePdf:[]
    })
    this.fileService.getPdfData(this.packageService.printPackage.packageNumber + "-Inspolicy.pdf" ).pipe(first())
    .subscribe( { next: async(data) =>  {
      this.mergePdfDoc = await PDFDocument.create();
      if (data)
      {
      const firstPDF = await this.loadPDFFromBlob(data);
  const firstPDFPages = await this.mergePdfDoc.copyPages(firstPDF, firstPDF.getPageIndices());
  firstPDFPages.forEach((page) => this.mergePdfDoc.addPage(page));
}
  this.role = this.accountService.userValue.role;
  this.certificate = new Certificate(this.packageService.printPackage);
  await this.summaryPage();
  this.isLoading = false;
    },
    error: async(error) => {
      if(this.packageService.printPackage.type == "Old")
      {
        this.mergePdfDoc = await PDFDocument.create();
        this.role = this.accountService.userValue.role;
        this.certificate = new Certificate(this.packageService.printPackage);
        await this.summaryPage();
        this.isLoading = false;      
      }
      else
      {
      this.alertService.error(error);
      this.isLoading = false;
    }
    }

})
    this.packageForm.get('acknowledge').valueChanges.subscribe(
      value => {
        this.acknowledgeInd = value;
      }
    )

  }

  async loadPDFFromBlob(blob: Blob): Promise<PDFDocument> {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onload = async () => {
        try {
          const pdfBytes = new Uint8Array(fileReader.result as ArrayBuffer);
          console.log(pdfBytes);
          const pdfDoc = await PDFDocument.load(pdfBytes,{ ignoreEncryption: true });
          resolve(pdfDoc);
        } catch (err) {
          reject(err);
        }
      };
      fileReader.readAsArrayBuffer(blob);
    });
  }
  async summaryPage(){
    if (this.certificate.policyType && this.certificate.policyType.includes("HOS"))
    {
        this.cpaHosCertificate()
      }
    else
    if (this.certificate.policyType && this.certificate.policyType.includes("RSA"))
    {
        this.cpaRsaCertificate()
      }
    else
    {
      this.cpaCertificate()
    }
      this.pdf.addPage()
      this.xPosition = this.marginStart;
      this.yPosition = this.marginTop;
      //this.pdf.setTextColor('#000000').setFontSize(12).setFont('helvetica','bold');
      // this.pdf.addImage('assets/alliancelogo2.png','PNG',this.xPosition,this.yPosition,120,60,'FAST');
      this.pdf.setFillColor('#242B86');
      this.yPosition += 80;
      this.pdf.rect(this.xPosition,this.yPosition,this.PAGE_WIDTH,40,'F');
      this.pdf.setTextColor('#D5CEA3').setFontSize(20).setFont('helvetica','bold');
      this.pdf.text("Business Summary Sheet",180,this.yPosition + 30);
      this.yPosition += 50;
      this.pdf.setFillColor('#D1D1D1');
      this.pdf.rect(this.xPosition,this.yPosition,279,30,'DF');
      this.pdf.rect(280,this.yPosition,100,30,'DF');
      this.pdf.rect(380,this.yPosition,100,30,'DF');
      this.pdf.rect(480,this.yPosition,100,30,'DF');
      this.pdf.setTextColor('#000000').setFontSize(12).setFont('helvetica','bold');
      this.pdf.text("Particulars",this.xPosition + 140 ,this.yPosition + 18,{align:'center'});
      this.pdf.text("Net Amount",this.xPosition + 320,this.yPosition + 18,{align:'center'});
      this.pdf.text("GST",this.xPosition + 420,this.yPosition + 18,{align:'center'});
      this.pdf.text("Total",this.xPosition + 520,this.yPosition + 18,{align:'center'});
      this.yPosition += 30;
      this.pdf.setFillColor('#FFFFFF');
      this.pdf.rect(this.xPosition,this.yPosition,279,30,'DF');
      this.pdf.rect(280,this.yPosition,100,30,'DF');
      this.pdf.rect(380,this.yPosition,100,30,'DF');
      this.pdf.rect(480,this.yPosition,100,30,'DF');
      // this.pdf.setFillColor('#000000');
      this.summaryTableRow("Summary INS",parseFloat(this.packageService.printPackage.insurancePolicyAmount.toFixed(2)), parseFloat(this.packageService.printPackage.insurancePolicyGStAmount.toFixed(2)),parseFloat(this.packageService.printPackage.insurancePolicyTotalAmount.toFixed(2)));
      this.summaryTableRow("Summary AAPL",parseFloat(this.packageService.printPackage.planAmount.toFixed(2)), parseFloat(this.packageService.printPackage.planGSTAmount.toFixed(2)),parseFloat(this.packageService.printPackage.planTotalAmount.toFixed(2)));
      this.summaryTableRow("Total",parseFloat(this.packageService.printPackage.packagePolicyAmount.toFixed(2)), parseFloat(this.packageService.printPackage.packagePolicyGStAmount.toFixed(2)),parseFloat(this.packageService.printPackage.packagePolicyTotalAmount.toFixed(2)));
      // this.pdf.save(this.packageService.printPackage.packageNumber + ".pdf")
      this.isLoading = false;
      await this.mergePdf();
      // const pdfDataUri = this.pdf.output('datauristring');
      // this.loadPdfToMyframe(pdfDataUri);
   
    }
    cpaHosCertificate(){
      this.isLoading = true;
      this.xPosition = this.marginStart;
      this.yPosition = this.marginTop + 10;
      this.pdf.addImage('assets/alliancelogo2.png','PNG',this.xPosition,this.yPosition,100,50,'FAST');
      this.yPosition += 50 + 10;
      this.processHeader("Tax Invoice cum Certificate Number: " + this.certificate.certificateNumber,24)
      this.yPosition += 2;
      this.pdf.setFillColor('#FFFFFF');
      this.pdf.rect(this.xPosition,this.yPosition,380,42,'DF');
      this.pdf.rect(this.xPosition + 380,this.yPosition,195,42,'DF');
      this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
      this.pdf.text("Corporate Address: Alliance Assure Private Limited",this.xPosition + 4,this.yPosition + 10,{align:'left'});
      this.pdf.text("For Assistance, Please contact us at:",this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
      this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
      this.pdf.text("#29,HSVP Shopping Complex, Sector 39,",this.xPosition + 4,this.yPosition + 20,{align:'left'});
      this.pdf.text("Email ID: support@allianceassure.in",this.xPosition + 4 + 380,this.yPosition + 20,{align:'left'});
      this.pdf.text("Gurugram(Haryana)-122003,",this.xPosition + 4,this.yPosition + 30,{align:'left'});
      this.pdf.text("PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC",this.xPosition + 4,this.yPosition + 40,{align:'left'});
      this.yPosition += 42;
      let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
      let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
      this.firstTableRow("Effective Period: " + formattedDate + " - " + formattedEndDate,"IMD Code: " + this.certificate.dealerId);
      this.firstTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Dealer: " + this.certificate.dealerId);
      this.firstTableRow("DOB: " + this.certificate.dob,"Gender: " + this.certificate.gender);
      this.firstTableRow(("Email: " + this.certificate.email).slice(0,85),"Contact Number: " + this.certificate.contactNumber);
      this.pdf.setFillColor('#FFFFFF');
      this.pdf.rect(this.xPosition,this.yPosition,380,22,'DF');
      this.pdf.rect(this.xPosition + 380,this.yPosition,195,22,'DF');
      this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
      this.pdf.text("City/District: " + this.certificate.city,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
      let addressText = "Address: " + this.certificate.address.slice(0,76);
      this.pdf.text(addressText,this.xPosition + 4,this.yPosition + 10,{align:'left'});
      this.pdf.text(this.certificate.address.slice(77,154),this.xPosition + 4,this.yPosition + 10,{align:'left'});
      this.yPosition += 22
      this.firstTableRow("State: " + this.certificate.state,"Vehicle Type: " + this.certificate.vehicleType);
      this.firstTableRow(("Model/Variant: " + this.certificate.model +'/' +this.certificate.variant).slice(0,85),"OEM: " + this.certificate.vehicleMake);
      this.firstTableRow("Registration Number: " + this.certificate.registrationNumber,"Manufacturing Year: " + this.certificate.vehicleYear);
      this.firstTableRow("Engine Number: " + this.certificate.engineNumber,"Chasis Number: " + this.certificate.chasisNumber);
      this.yPosition += 10
      this.processHeader("Plan Features",21)
      this.pdf.setFillColor('#D1D1D1');
      this.pdf.rect(this.xPosition,this.yPosition,30,12,'DF');
      this.pdf.rect(this.xPosition +30,this.yPosition,130,12,'DF');
      this.pdf.rect(this.xPosition + 160,this.yPosition,340,12,'DF');
      this.pdf.rect(this.xPosition + 500,this.yPosition,75,12,'DF');
      this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
      this.pdf.text("S.No",this.xPosition + 4,this.yPosition + 10,{align:'left'});
      this.pdf.text("Benefits",this.xPosition + 34 + 65,this.yPosition + 10,{align:'center'});
      this.pdf.text("Description",this.xPosition + 164 + 170,this.yPosition + 10,{align:'center'});
      this.pdf.text("Value",this.xPosition + 500 + 40,this.yPosition + 10,{align:'center'});
      this.yPosition += 12;
      this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
      this.secondTableRow("1","Relay of urgent messages","Pass on message to Rider's friends, family","Yes");
      this.secondTableRow("2","Medical Assistance","Arranging for an ambulance/ hospital for Rider","Yes");
      this.secondTableRow("3","Hospital Appointment","Arranging appointment in the nearest available hospital","Yes");
      this.secondTableRow("4","Diagnostic Test Assistance","Arranging appointment in the nearest available diagnostic center","Yes");
      this.secondTableRow("5","Medicine delivery assistance","Arranging medicines from nearest available medical center","Yes");
      this.secondTableRow("6","Police assistance","Arranging contact details of nearest police station","Yes");
      this.secondTableRow("7","24*7 response center","24 by 7 response center available for client needs","Yes");
      this.secondTableRow("8","Taxi Assistance ","Arrange for taxi on Rider's / driver's request irrespective of breakdown location","Yes");
      this.secondTableRow("9","Hotel Assistance","Arrange for Hotel on Rider's / driver's request","Yes");
      this.secondTableRow("10","Complimentary insurance","Includes Complimentary personal accident insurance","Yes");
      this.secondTableRow("11","Number of Services","Proposed Number of Services ","3");
      this.yPosition += 5;
      this.pdf.text("Special Conditions (applicable to all coverages): (a) All additional expenses regarding any other service which does not form a part of the standard services ",this.xPosition ,this.yPosition + 10,{align:'left'});
      this.yPosition += 10;
      this.pdf.text("provided would be on chargeable basis to the insured.(b) This Certificate is valid subject to realisation of the payment and is effective from the Payment",this.xPosition ,this.yPosition + 10,{align:'left'});
      this.yPosition += 10;
      this.pdf.text(" realisation date or certificate issue date, whichever is later.",this.xPosition ,this.yPosition + 10,{align:'left'});
      this.yPosition += 22;
      this.processHeader("Payment Details",21)
      this.halfTableRow("Plan Amount",this.certificate.planAmount.toString());
      this.halfTableRow("SGST(9%)",this.certificate.SgstAmount.toString());
      this.halfTableRow("CGST(9%)",this.certificate.CgstAmount.toString());
      this.halfTableRow("IGST(18%)",this.certificate.IgstAmount.toString());
      this.halfTableRow("Total",this.certificate.planTotalAmount.toString());
      this.yPosition += 10
      this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
      this.pdf.text("For Alliance Assure Pvt Ltd",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
      this.yPosition += 12
      this.pdf.addImage('assets/signature.png','PNG',this.xPosition + 470,this.yPosition,100,50);
      this.yPosition += 52;
      this.yPosition += 10
  
      this.processHeader("Personal Accidental Cover Details",21)
      if (this.certificate.policyType && this.certificate.policyType.includes("Ifco"))
        {
          this.halfTableRow("Master Policy No: " + this.certificate.policyNumber + "-IFT","Master Policy Holder: Alliance Assure Private Limited" )
        }
      else
      {
        this.halfTableRow("Master Policy No: " + this.certificate.policyNumber,"Master Policy Holder: Alliance Assure Private Limited" )
      }
      this.halfTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + this.certificate.lastName,"Period of Insurance:" + formattedDate + " - " + formattedEndDate)
      this.halfTableRow("Nominee Name: " + this.certificate.nomineeName,"Nominee Relationship: " + this.certificate.nomineeRelationship );
      this.halfTableRow("Insurer: " + (this.certificate.policyType.includes('Ifco')?'IFFCO TOKIO GENERAL INSURANCE CO. LTD':'THE NEW INDIA ASSURANCE CO. LTD'),"Nominee Age/Gender: " + this.certificate.nomineeAge + "/" + this.certificate.nomineeGender);
      this.pdf.text("Special Conditions",this.xPosition ,this.yPosition + 10,{align:'left'});
      this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','bold');
      this.yPosition += 8;
      this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
      let message = ((this.certificate.planCPACoverAmount == 500000)?"1)Per individual SI":"1)Per individual SI is fixed Rs. ") + (this.inWords(this.certificate.planCPACoverAmount ? this.certificate.planCPACoverAmount: 1500000)).trim() + ((this.certificate.planCPACoverAmount == 500000)?" sum insured is fixed. ":".")  +" 2) Age Band - 18 to 70 yrs. 3) Accidental Death(AD)- Covers Death due to Accident only. 4) We shall pay compensation for death, in direct connection with the vehicle cover for above Roadside Assistance Certificate and of which he / she is registered owner or whilst driving such registered vehicle or whilst travelling in it as a co-driver, caused by violent accidental external and visible means which independent of any other cause shall within six calendar months of such injury result in Death 100% CSI. 5) No compensation shall be payable in respect of death or bodily injury directly or indirectly wholly or in part arising or resulting from or traceable to -(a) Intentional self injury suicide or attempted suicide physical defect or infirmity or (b) An accident happening whilst such person is under the influence of intoxicating liquor or drugs (c) Death due to non adherence of Traffic rules. B) Such compensation shall be payable directly to his/her legal representatives. 6) This cover is subject to -(a) The Insured is the registered owner of the vehicle and has direct connection with his/her death. (b) The Insured holds a valid and effective driving licence, in accordance with the provisions of Section 3 of Motor Vehicle Act, 1988, at the time of the accident. 7) Any form of nuclear, chemical and biological Terrorism is excluded. 8) Warranted that claim should be intimated to an insurer within 30 days of date of accident/incident.If a claim is intimated after the defined timeline then it will be repudiated. 9) Scope of Cover - 24 Hrs, Within India only."
      this.printDisclaimerMessage(message);
  
      // this.pdf.text("1)Per individual SI is fixed Rs. 15 Lakh. 2) Age Band - 18 to 70 yrs. 3) Accidental Death (AD) - Covers Death due to Accident only. 4) We shall pay compensation for death, in direct connection with the vehicle cover for  ",this.xPosition ,this.yPosition + 8,{align:'left'});
      // this.yPosition += 8
      // this.pdf.text("above Roadside Assistance Certificate and of which he / she is registered owner or whilst driving such registered vehicle or whilst travelling in it as a co-driver, caused by violent accidental external and visible means ",this.xPosition ,this.yPosition + 8,{align:'left'});
      // this.yPosition += 8
      // this.pdf.text("which independent of any other cause shall within six calendar months of such injury result in Death 100% CSI. 5) No compensation shall be payable in respect of death or bodily injury directly or indirectly wholly or in ",this.xPosition ,this.yPosition + 8,{align:'left'});
      // this.yPosition += 8
      // this.pdf.text("part arising or resulting from or traceable to -(a) Intentional self injury suicide or attempted suicide physical defect or infirmity or (b) An accident happening whilst such person is under the influence of intoxicating ",this.xPosition ,this.yPosition + 8,{align:'left'});
      // this.yPosition += 8
      // this.pdf.text("liquor or drugs. B) Such compensation shall be payable directly to his/her legal representatives. 6) This cover is subject to - (a) The Insured is the registered owner of the vehicle and has direct connection with his/her",this.xPosition ,this.yPosition + 8,{align:'left'});
      // this.yPosition += 8
      // this.pdf.text("death. (b) The Insured holds a valid and effective driving licence, in accordance with the provisions of Section 3 of Motor Vehicle Act, 1988, at the time of the accident. 7) Any form of Nuclear, Chemical and biological ",this.xPosition ,this.yPosition + 8,{align:'left'});
      // this.yPosition += 8
      // this.pdf.text("Terrorism is excluded. 8) Scope of Cover - 24 Hrs, Within India only.",this.xPosition ,this.yPosition + 8,{align:'left'});
      // this.yPosition += 8
  
      this.isLoading = false;
      }
  
  cpaRsaCertificate(){
    this.isLoading = true;
    this.xPosition += this.marginStart;
    this.yPosition += this.marginTop;
    this.pdf.addImage('assets/alliancelogo2.png','PNG',this.xPosition,this.yPosition,100,50,'FAST');
    this.yPosition += 50 + 5;
    this.processHeader("Tax Invoice cum Certificate Number: " + this.certificate.certificateNumber,24)
    this.yPosition += 2;
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,42,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,42,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("Corporate Address: Alliance Assure Private Limited",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("For Assistance, Please contact us at:",this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("#29,HSVP Shopping Complex, Sector 39,",this.xPosition + 4,this.yPosition + 20,{align:'left'});
    this.pdf.text("Email ID: support@allianceassure.in",this.xPosition + 4 + 380,this.yPosition + 20,{align:'left'});
    this.pdf.text("Gurugram(Haryana)-122003,",this.xPosition + 4,this.yPosition + 30,{align:'left'});
    this.pdf.text("PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC",this.xPosition + 4,this.yPosition + 40,{align:'left'});
    this.yPosition += 42;
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
    this.firstTableRow("Effective Period: " + formattedDate + " - " + formattedEndDate,"IMD Code: " + this.certificate.dealerId);
    this.firstTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Dealer: " + this.certificate.dealerId);
    this.firstTableRow("DOB: " + this.certificate.dob,"Gender: " + this.certificate.gender);
    this.firstTableRow(("Email: " + this.certificate.email).slice(0,85),"Contact Number: " + this.certificate.contactNumber);
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,22,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,22,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("City/District: " + this.certificate.city,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    let addressText = "Address: " + this.certificate.address.slice(0,76);
    this.pdf.text(addressText,this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text(this.certificate.address.slice(77,154),this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.yPosition += 22
    this.firstTableRow("State: " + this.certificate.state,"Vehicle Type: " + this.certificate.vehicleType);
    this.firstTableRow(("Model/Variant: " + this.certificate.model +'/' +this.certificate.variant).slice(0,85),"OEM: " + this.certificate.vehicleMake);
    this.firstTableRow("Registration Number: " + this.certificate.registrationNumber,"Manufacturing Year: " + this.certificate.vehicleYear);
    this.firstTableRow("Engine Number: " + this.certificate.engineNumber,"Chasis Number: " + this.certificate.chasisNumber);
    this.yPosition += 10
    this.processHeader("Coverages of Roadside Assistance - Toll Free Number - 1800 212 7559",15)
    this.pdf.setFillColor('#D1D1D1');
    this.pdf.rect(this.xPosition,this.yPosition,30,12,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,12,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,12,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("S.No",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Benefits",this.xPosition + 34 + 65,this.yPosition + 10,{align:'center'});
    this.pdf.text("Description",this.xPosition + 164 + 170,this.yPosition + 10,{align:'center'});
    this.pdf.text("Value",this.xPosition + 500 + 40,this.yPosition + 10,{align:'center'});
    this.yPosition += 12;
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.secondTableRow("1","Relay of urgent messages","Pass on message to Rider's friends, family","Yes");
    this.secondTableRow("2","Doctor Referral","Giving the contact details of nearest doctor to Rider","Yes");
    this.secondTableRow("3","Vehicle Breakdown-Phone Support ","Guiding the Rider on phone about vehicle related problems","Yes");
    this.secondTableRow("4","On Site Minor Repair","Arranging for a mechanic to do minor repairs on the spot","Yes");
    this.secondTableRow("5","Replacement of Keys","Arrange for pick-up and delivery of duplicate keys from Rider residence","Yes");
    this.secondTableRow("6","Lost Keys","Arrange for a locksmith or a technician to open the lock","Yes");
    this.secondTableRow("7","Fuel Delivery","Arrange for fuel delivery in case vehicle is out of fuel (Fuel cost on actual basis)","Yes");
    this.secondTableRow("8","Wrong Fueling","Arrange for tank cleaning or towing in case of wrong fueling","Yes");
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,30,52,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,52,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,52,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,52,'DF');
    this.pdf.text("9",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Flat tyre Support",this.xPosition + 34 ,this.yPosition + 10,{align:'left'});
    let mesage = "Arrange for technician to change the tyre or get it repaired, Material/spare parts if required to repair the Vehicle (including repair of flat spare stepney tyre) will be borne by the Insured. Incase the spare tyre is not available in the covered Vehicle, the flat tyre will be taken to thenearest flat tyre repair shop for repairs and re-attached to the Vehicle.All incidental charges for the same shall be borne by the Insured."
    this.pdf.text(mesage.slice(0,98),this.xPosition + 164 ,this.yPosition + 10,{align:'left'});
    this.pdf.text(mesage.slice(98,195),this.xPosition + 164 ,this.yPosition + 20,{align:'left'});
    this.pdf.text(mesage.slice(195,290),this.xPosition + 164 ,this.yPosition + 30,{align:'left'});
    this.pdf.text(mesage.slice(290,390),this.xPosition + 164 ,this.yPosition + 40,{align:'left'});
    this.pdf.text(mesage.slice(390,432),this.xPosition + 164 ,this.yPosition + 50,{align:'left'});
    this.pdf.text("Yes",this.xPosition + 495 + 40 ,this.yPosition + 10,{align:'center'});
    this.yPosition += 52
    this.secondTableRow("10","Battery Jump-Start","A technician to be arranged for battery jumpstart","Yes");
    this.secondTableRow("11","Mechanical & elec. breakdown","Arrange for towing in case of any mechanical breakdowns","Yes");
    this.secondTableRow("12","Towing for accidental cases","In case of accident arrange for towing to send it to repair garage","Yes");
    this.secondTableRow("13","Taxi Assistance ","Arrange for taxi on Rider's / driver's request irrespective of breakdown location","Yes");
    this.secondTableRow("14","Hotel Assistance","Arrange for Hotel on Rider's / driver's request","Yes");
    this.secondTableRow("15","Medical Assistance","Arranging for an ambulance/ hospital for Rider","Yes");
    this.secondTableRow("16","Vehicle Custody Services","Take custody of vehicle in case Rider cannot attend the vehicle ","Yes");
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,30,22,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,22,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,22,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,22,'DF');
    this.pdf.text("17",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Programme Start Date",this.xPosition + 34 ,this.yPosition + 10,{align:'left'});
    this.pdf.text("The date of commencement of coverage under the program.",this.xPosition + 164 ,this.yPosition + 10,{align:'left'});
    this.pdf.text("The program start date will be after 7 days from the program purchase date.",this.xPosition + 164 ,this.yPosition + 20,{align:'left'});
    this.pdf.text("After 7 Days",this.xPosition + 495 + 40 ,this.yPosition + 10,{align:'center'});
    this.yPosition += 22;
    this.secondTableRow("18","Number of Services","Proposed Number of Services ","4");
    this.yPosition += 5;
    this.pdf.text("Special Conditions (applicable to all coverages): (a) All additional expenses regarding replacement of a part, additional Fuel and any other service which does not ",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text("form a part of the standard services provided would be on chargeable basis to the insured.(b) This Certificate is valid subject to realisation of the payment and is",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text("effective from the Payment realisation date or certificate issue date, whichever is later.",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 12;
    this.pdf.setFillColor('#D1D1D1');
    this.pdf.rect(this.xPosition,this.yPosition,116,12,'DF');
    this.pdf.rect(this.xPosition +116,this.yPosition,116,12,'DF');
    this.pdf.rect(this.xPosition + 232,this.yPosition,116,12,'DF');
    this.pdf.rect(this.xPosition + 348,this.yPosition,116,12,'DF');
    this.pdf.rect(this.xPosition + 464,this.yPosition,110,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("Plan Amount",this.xPosition + 58,this.yPosition + 10,{align:'center'});
    this.pdf.text("SGST(9%)",this.xPosition + 58 + 116 ,this.yPosition + 10,{align:'center'});
    this.pdf.text("CGST(9%)",this.xPosition + 58 + 232 ,this.yPosition + 10,{align:'center'});
    this.pdf.text("IGST(18%)",this.xPosition + 58 + 348 ,this.yPosition + 10,{align:'center'});
    this.pdf.text("Total",this.xPosition + 58 + 464 ,this.yPosition + 10,{align:'center'});
    this.yPosition += 12
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,116,12,'DF');
    this.pdf.rect(this.xPosition +116,this.yPosition,116,12,'DF');
    this.pdf.rect(this.xPosition + 232,this.yPosition,116,12,'DF');
    this.pdf.rect(this.xPosition + 348,this.yPosition,116,12,'DF');
    this.pdf.rect(this.xPosition + 464,this.yPosition,110,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text(this.certificate.planAmount.toFixed(2).toString(),this.xPosition + 58,this.yPosition + 10,{align:'center'});
    this.pdf.text(this.certificate.SgstAmount.toFixed(2).toString(),this.xPosition + 58 + 116 ,this.yPosition + 10,{align:'center'});
    this.pdf.text(this.certificate.CgstAmount.toFixed(2).toString(),this.xPosition + 58 + 233 ,this.yPosition + 10,{align:'center'});
    this.pdf.text(this.certificate.IgstAmount.toFixed(2).toString(),this.xPosition + 58 + 348 ,this.yPosition + 10,{align:'center'});
    this.pdf.text(this.certificate.planTotalAmount.toFixed(2).toString(),this.xPosition + 58 + 464 ,this.yPosition + 10,{align:'center'});
    this.yPosition += 13
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','bold');
    this.pdf.text("For Alliance Assure Pvt Ltd",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
    this.yPosition += 10
    this.pdf.addImage('assets/signature.png','PNG',this.xPosition + 520,this.yPosition,50,25);
    this.yPosition += 27;
    this.processHeader("Doctor On Call/Chat",15)
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    this.pdf.text("Free Doctor Chat - Opt for a voice/video call. Get Online Prescriptions from them. Doctor over a Phone Call - You can speak to our specialist over a regular voice call too.Artificial Intelligence Healthcare Assistant - ",this.xPosition ,this.yPosition + 8,{align:'left'});
    this.yPosition += 8
    this.pdf.text("Connect with our easy to use our Artificial Intelligence-powered healthcare assistant who is always available at your fingertips.*T&C applicable - Only one service under this certificate applicable. All Doctor on Call/Chat ",this.xPosition ,this.yPosition + 8,{align:'left'});
    this.yPosition += 8
    this.pdf.text("Services may not be available in your area. Doctor chat/call will get activated after 2 days of registration at 1800 212 7559",this.xPosition ,this.yPosition + 8,{align:'left'});
    this.yPosition += 10
    this.processHeader("Personal Accidental Cover Details",15)
    if (this.certificate.policyType && this.certificate.policyType.includes("Ifco"))
      {
        this.halfTableRow("Master Policy No: " + this.certificate.policyNumber + "-IFT","Master Policy Holder: Alliance Assure Private Limited" )
      }
    else
    {
      this.halfTableRow("Master Policy No: " + this.certificate.policyNumber,"Master Policy Holder: Alliance Assure Private Limited" )
    }
    this.halfTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Period of Insurance:" + formattedDate + " - " + formattedEndDate)
    this.halfTableRow("Nominee Name: " + this.certificate.nomineeName,"Nominee Relationship: " + this.certificate.nomineeRelationship );
    this.halfTableRow("Insurer: " + (this.certificate.policyType.includes('Ifco')?'IFFCO TOKIO GENERAL INSURANCE CO. LTD':'THE NEW INDIA ASSURANCE CO. LTD'),"Nominee Age/Gender: " + this.certificate.nomineeAge + "/" + this.certificate.nomineeGender);
    this.pdf.text("Special Conditions",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','bold');
    this.yPosition += 8;
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    let message = ((this.certificate.planCPACoverAmount == 500000)?"1)Per individual SI":"1)Per individual SI is fixed Rs. ") + (this.inWords(this.certificate.planCPACoverAmount ? this.certificate.planCPACoverAmount: 1500000)).trim() + ((this.certificate.planCPACoverAmount == 500000)?" sum insured is fixed. ":".")  +" 2) Age Band - 18 to 70 yrs. 3) Accidental Death(AD)- Covers Death due to Accident only. 4) We shall pay compensation for death, in direct connection with the vehicle cover for above Roadside Assistance Certificate and of which he / she is registered owner or whilst driving such registered vehicle or whilst travelling in it as a co-driver, caused by violent accidental external and visible means which independent of any other cause shall within six calendar months of such injury result in Death 100% CSI. 5) No compensation shall be payable in respect of death or bodily injury directly or indirectly wholly or in part arising or resulting from or traceable to -(a) Intentional self injury suicide or attempted suicide physical defect or infirmity or (b) An accident happening whilst such person is under the influence of intoxicating liquor or drugs (c) Death due to non adherence of Traffic rules. B) Such compensation shall be payable directly to his/her legal representatives. 6) This cover is subject to -(a) The Insured is the registered owner of the vehicle and has direct connection with his/her death. (b) The Insured holds a valid and effective driving licence, in accordance with the provisions of Section 3 of Motor Vehicle Act, 1988, at the time of the accident. 7) Any form of nuclear, chemical and biological Terrorism is excluded. 8) Warranted that claim should be intimated to an insurer within 30 days of date of accident/incident.If a claim is intimated after the defined timeline then it will be repudiated. 9) Scope of Cover - 24 Hrs, Within India only."
    this.printDisclaimerMessage(message);
  }
  secondTableRow(column1Text:string,column2Text:string,column3Text:string,column4Text:string){
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,30,12,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,12,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,12,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,12,'DF');
    this.pdf.text(column1Text,this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text(column2Text,this.xPosition + 34 ,this.yPosition + 10,{align:'left'});
    this.pdf.text(column3Text,this.xPosition + 164 ,this.yPosition + 10,{align:'left'});
    this.pdf.text(column4Text,this.xPosition + 495 + 40 ,this.yPosition + 10,{align:'center'});
    this.yPosition += 12

  }
  halfTableRow(column1Text:string,column2Text:string)
  {
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,290,12,'DF');
    this.pdf.rect(this.xPosition + 290,this.yPosition,285,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text(column1Text,this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text(column2Text,this.xPosition + 4 + 290,this.yPosition + 10,{align:'left'});
    this.yPosition += 12
  }
  firstTableRow(column1Text:string,column2Text:string)
  {
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,12,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text(column1Text,this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text(column2Text,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.yPosition += 12
  }
  processHeader(headerText:string,width:number){
    this.pdf.setFillColor('#242B86');
    this.pdf.rect(this.xPosition,this.yPosition,this.PAGE_WIDTH,width,'DF');
    this.xPosition += this.PAGE_WIDTH/2;
    this.yPosition += width * 2/3;
    this.pdf.setTextColor('#D5CEA3').setFontSize(width/2).setFont('helvetica','bold');
    this.pdf.text(headerText,this.xPosition,this.yPosition,{align:'center'});
    this.yPosition +=width/3;
    this.xPosition = this.marginStart;

  }
  cpaCertificate(){
    this.isLoading = true;
    this.xPosition = this.marginStart;
    this.yPosition = this.marginTop + 10;
    this.pdf.addImage('assets/alliancelogo2.png','PNG',this.xPosition,this.yPosition,100,50,'FAST');
    this.yPosition += 50 + 10;
    this.processHeader("Tax Invoice cum Certificate Number: " + this.certificate.certificateNumber,24)
    this.yPosition += 2;
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,42,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,42,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("Corporate Address: Alliance Assure Private Limited",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("For Assistance, Please contact us at:",this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("#29,HSVP Shopping Complex, Sector 39,",this.xPosition + 4,this.yPosition + 20,{align:'left'});
    this.pdf.text("Email ID: support@allianceassure.in",this.xPosition + 4 + 380,this.yPosition + 20,{align:'left'});
    this.pdf.text("Gurugram(Haryana)-122003,",this.xPosition + 4,this.yPosition + 30,{align:'left'});
    this.pdf.text("PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC",this.xPosition + 4,this.yPosition + 40,{align:'left'});
    this.yPosition += 42;
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
    this.firstTableRow("Effective Period: " + formattedDate + " - " + formattedEndDate,"IMD Code: " + this.certificate.dealerId);
    this.firstTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Dealer: " + this.certificate.dealerId);
    this.firstTableRow("DOB: " + this.certificate.dob,"Gender: " + this.certificate.gender);
    this.firstTableRow(("Email: " + this.certificate.email).slice(0,85),"Contact Number: " + this.certificate.contactNumber);
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,22,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,22,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("City/District: " + this.certificate.city,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    let addressText = "Address: " + this.certificate.address.slice(0,76);
    this.pdf.text(addressText,this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text(this.certificate.address.slice(77,154),this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.yPosition += 22
    this.firstTableRow("State: " + this.certificate.state,"Vehicle Type: " + this.certificate.vehicleType);
    this.firstTableRow(("Model/Variant: " + this.certificate.model +'/' +this.certificate.variant).slice(0,85),"OEM: " + this.certificate.vehicleMake);
    this.firstTableRow("Registration Number: " + this.certificate.registrationNumber,"Manufacturing Year: " + this.certificate.vehicleYear);
    this.firstTableRow("Engine Number: " + this.certificate.engineNumber,"Chasis Number: " + this.certificate.chasisNumber);
    this.yPosition += 10
    this.processHeader("Plan Features",21)
    this.pdf.setFillColor('#D1D1D1');
    this.pdf.rect(this.xPosition,this.yPosition,30,12,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,12,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,12,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("S.No",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Benefits",this.xPosition + 34 + 65,this.yPosition + 10,{align:'center'});
    this.pdf.text("Description",this.xPosition + 164 + 170,this.yPosition + 10,{align:'center'});
    this.pdf.text("Value",this.xPosition + 500 + 40,this.yPosition + 10,{align:'center'});
    this.yPosition += 12;
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.secondTableRow("1","Relay of urgent messages","Pass on message to Rider's friends, family","Yes");
    this.secondTableRow("2","Medical Assistance","Arranging for an ambulance/ hospital for Rider","Yes");
    this.secondTableRow("3","Hospital Appointment","Arranging appointment in the nearest available hospital","Yes");
    this.secondTableRow("4","Diagnostic Test Assistance","Arranging appointment in the nearest available diagnostic center","Yes");
    this.secondTableRow("5","Medicine delivery assistance","Arranging medicines from nearest available medical center","Yes");
    this.secondTableRow("6","Police assistance","Arranging contact details of nearest police station","Yes");
    this.secondTableRow("7","24*7 response center","24 by 7 response center available for client needs","Yes");
    this.secondTableRow("8","Taxi Assistance ","Arrange for taxi on Rider's / driver's request irrespective of breakdown location","Yes");
    this.secondTableRow("9","Hotel Assistance","Arrange for Hotel on Rider's / driver's request","Yes");
    this.secondTableRow("10","Complimentary insurance","Includes Complimentary personal accident insurance","Yes");
    this.secondTableRow("11","Number of Services","Proposed Number of Services ","3");
    this.yPosition += 5;
    this.pdf.text("Special Conditions (applicable to all coverages): (a) All additional expenses regarding any other service which does not form a part of the standard services ",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text("provided would be on chargeable basis to the insured.(b) This Certificate is valid subject to realisation of the payment and is effective from the Payment",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text(" realisation date or certificate issue date, whichever is later.",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 22;
    this.processHeader("Payment Details",21)
    this.halfTableRow("Plan Amount",this.certificate.planAmount.toString());
    this.halfTableRow("SGST(9%)",this.certificate.SgstAmount.toString());
    this.halfTableRow("CGST(9%)",this.certificate.CgstAmount.toString());
    this.halfTableRow("IGST(18%)",this.certificate.IgstAmount.toString());
    this.halfTableRow("Total",this.certificate.planTotalAmount.toString());
    this.yPosition += 10
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("For Alliance Assure Pvt Ltd",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
    this.yPosition += 12
    this.pdf.addImage('assets/signature.png','PNG',this.xPosition + 470,this.yPosition,100,50);
    this.yPosition += 52;
    this.yPosition += 10

    this.processHeader("Personal Accidental Cover Details",21)
    if (this.certificate.policyType && this.certificate.policyType.includes("Ifco"))
      {
        this.halfTableRow("Master Policy No: " + this.certificate.policyNumber + "-IFT","Master Policy Holder: Alliance Assure Private Limited" )
      }
    else
    {
      this.halfTableRow("Master Policy No: " + this.certificate.policyNumber,"Master Policy Holder: Alliance Assure Private Limited" )
    }
    this.halfTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + this.certificate.lastName,"Period of Insurance:" + formattedDate + " - " + formattedEndDate)
    this.halfTableRow("Nominee Name: " + this.certificate.nomineeName,"Nominee Relationship: " + this.certificate.nomineeRelationship );
    this.halfTableRow("Insurer: " + (this.certificate.policyType.includes('Ifco')?'IFFCO TOKIO GENERAL INSURANCE CO. LTD':'THE NEW INDIA ASSURANCE CO. LTD'),"Nominee Age/Gender: " + this.certificate.nomineeAge + "/" + this.certificate.nomineeGender);
    this.pdf.text("Special Conditions",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','bold');
    this.yPosition += 8;
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    let message = ((this.certificate.planCPACoverAmount == 500000)?"1)Per individual SI":"1)Per individual SI is fixed Rs. ") + (this.inWords(this.certificate.planCPACoverAmount ? this.certificate.planCPACoverAmount: 1500000)).trim() + ((this.certificate.planCPACoverAmount == 500000)?" sum insured is fixed. ":".")  +" 2) Age Band - 18 to 70 yrs. 3) Accidental Death(AD)- Covers Death due to Accident only. 4) We shall pay compensation for death, in direct connection with the vehicle cover for above Roadside Assistance Certificate and of which he / she is registered owner or whilst driving such registered vehicle or whilst travelling in it as a co-driver, caused by violent accidental external and visible means which independent of any other cause shall within six calendar months of such injury result in Death 100% CSI. 5) No compensation shall be payable in respect of death or bodily injury directly or indirectly wholly or in part arising or resulting from or traceable to -(a) Intentional self injury suicide or attempted suicide physical defect or infirmity or (b) An accident happening whilst such person is under the influence of intoxicating liquor or drugs (c) Death due to non adherence of Traffic rules. B) Such compensation shall be payable directly to his/her legal representatives. 6) This cover is subject to -(a) The Insured is the registered owner of the vehicle and has direct connection with his/her death. (b) The Insured holds a valid and effective driving licence, in accordance with the provisions of Section 3 of Motor Vehicle Act, 1988, at the time of the accident. 7) Any form of nuclear, chemical and biological Terrorism is excluded. 8) Warranted that claim should be intimated to an insurer within 30 days of date of accident/incident.If a claim is intimated after the defined timeline then it will be repudiated. 9) Scope of Cover - 24 Hrs, Within India only."
    this.printDisclaimerMessage(message);
    this.isLoading = false;

    
  }
  printDisclaimerMessage(message:string){
    let lines = this.pdf.splitTextToSize(message,this.PAGE_WIDTH);
    this.pdf.text(lines, this.xPosition, this.yPosition + 8, { align: 'left' })

  }
  inWords (num) {
    num = Math.round (num);
   var a = ['','1 ','2 ','3 ','4 ', '5 ','6 ','7 ','8 ','9 ','10 ','11 ','12 ','13 ','14 ','15 ','16 ','17 ','18 ','19 '];
   var b = ['', '', '20','30','40','50', '60','70','80','90'];
     if ((num = num.toString()).length > 9) return 'overflow';
      this.n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
     if (!this.n) return; var str = '';
     str += (Number(this.n[1]) != 0) ? (a[Number(this.n[1])] || b[this.n[1][0]] + ' ' + a[this.n[1][1]]) + 'crore ' : '';
     str += (Number(this.n[2]) != 0) ? (a[Number(this.n[2])] || b[this.n[2][0]] + ' ' + a[this.n[2][1]]) + 'lakh ' : '';
     str += (Number(this.n[3]) != 0) ? (a[Number(this.n[3])] || b[this.n[3][0]] + ' ' + a[this.n[3][1]]) + 'thousand ' : '';
     str += (Number(this.n[4]) != 0) ? (a[Number(this.n[4])] || b[this.n[4][0]] + ' ' + a[this.n[4][1]]) + 'hundred ' : '';
     str += (Number(this.n[5]) != 0) ? ((str != '') ? 'and ' : '') + (a[Number(this.n[5])] || b[this.n[5][0]] + ' ' + a[this.n[5][1]]) + 'only ' : '';
     return str;
 }

  summaryTableRow(column1Txt: string,column2Text:number,column3Text:number,column4Text:number ){
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,272,30,'DF');
    this.pdf.rect(280,this.yPosition,100,30,'DF');
    this.pdf.rect(380,this.yPosition,100,30,'DF');
    this.pdf.rect(480,this.yPosition,100,30,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text(column1Txt,this.xPosition + 4,this.yPosition + 18,{align:'left'});
    this.pdf.text(column2Text.toString(),376,this.yPosition + 18,{align:'right'});
    this.pdf.text(column3Text.toString(),476,this.yPosition + 18,{align:'right'});
    this.pdf.text(column4Text.toString(),576,this.yPosition + 18,{align:'right'});
    this.yPosition += 30;
  }
  downloadCertificate(){
    this.pdf.save(this.certificate.certificateNumber + ".pdf")
  }
  showPdfToMyframe(){
    this.isLoading = true;
    this.fileService.getPdfData(this.packageService.printPackage.packageNumber + "-Package.pdf" )
    .subscribe(async data =>  {
      const pdfUrl = URL.createObjectURL(data);
      this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl);
      this.isLoading = false;
      document.getElementById('myFrame').setAttribute('src',this.pdfSrc.toString());    
    });
  }

  loadPdfToMyframe(pdfDataUri:any){
    this.pdfDataUri = pdfDataUri;
    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(pdfDataUri);
    this.isLoading = false;
    document.getElementById('myFrame').setAttribute('src',this.pdfSrc.toString());    
  }
  async mergePdf() {
    const secondPDF = await PDFDocument.load(this.pdf.output('arraybuffer'));
    const secondPDFPages = await this.mergePdfDoc.copyPages(secondPDF, secondPDF.getPageIndices());
    secondPDFPages.forEach((page) => this.mergePdfDoc.addPage(page));
    const pdfDataUri = await this.mergePdfDoc.saveAsBase64({ dataUri: true });
    this.isLoading = false;
    this.loadPdfToMyframe(pdfDataUri);
  }
  async downloadPackage(){
    this.isLoading = true;
    const mergedByte = await this.mergePdfDoc.save()
    this.isLoading = false;
  }
  onPolicyPdfSelected(event){
    this.isLoading = true;
    const selectedFile = event.target.files[0];
      let fileURL = URL.createObjectURL(selectedFile);
      this.isLoading = false;
      this.loadPdfToMyframe(fileURL);
  }

  getloading() {
    return this.isLoading;
  }
   sharePackage(){
    this.isLoading = true;
     this.convertAndSaveBlob();
  
  }

  convertAndSaveBlob(){
    fetch(this.pdfDataUri)
  .then(response => response.blob())
  .then(blob => {
    // create a Blob object with the PDF MIME type
    const pdfBlob = new Blob([blob], { type: 'application/pdf' });
    const selectedFile = pdfBlob;
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('customFileName', this.packageService.printPackage.packageNumber +"-Package.pdf");
    this.fileService.fileUpload(formData).subscribe({next: () => {
      this.sharePackageStatusUpdate()
      this.isLoading = false;
    },
    error: error => {
      this.alertService.error(error);
      this.isLoading = false;
    }
  }
  )
  
  });
  }
  sharePackageStatusUpdate(){
    this.packageService.sharePackage().pipe(first()).subscribe({
      next: (x) => { 
        this.alertService.success("Package " + this.packageService.printPackage.packageNumber +" Shared successfully");
        this.packageService.printPackage.packageStatus = "Print Ready";
        this.packageService.updatePackageInPrintCertificateState(this.packageService.printPackage);
        this.isLoading = false;
        this.router.navigate(['../reports/printCertificate'], { relativeTo: null });
      },
      error: error => {
        this.alertService.error(error);
        this.isLoading = false;
      }
    }
    )

  }
  async loadPDF(url: string): Promise<ArrayBuffer> {
    const response = await fetch(url);
    const data = await response.arrayBuffer();
    return data;
  }
}
