export class PlanDetails {
    planId: string;
    planAmount: number;
    planGstAmount: number;
    planTotalAmount: number;
    planMaximumAmount: number;
    planNetAmount: number;
    policyType: string;
    gstApplicable ?: string;
    gstShow ?: string;
    planMinimumAmount?: number;
    printCpaPolicy ?: string;
    planCPACoverAmount?:number;
    printRsaNumber?:string;
    counterId?:string;
    masterPolicyNumber?:string;
    certificatePrefix?:string;
    rsaNumberOfServices?:number;
    numberOfYears ?= 1;
    applicableOnlyOnPolicyType ?="";

    public constructor(init?: Partial<PlanDetails>) {
        Object.assign(this, init);
    }
}