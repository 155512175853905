import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Package } from '@app/_models/package';
import { FileService } from '@app/_services/file.service';
import { PackageService } from '../package.service';
import { AccountService } from '@app/_services';
import { User } from '@app/_models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-package',
  templateUrl: './view-package.component.html',
  styleUrls: ['./view-package.component.less']
})
export class ViewPackageComponent implements OnInit {

  packageNumber:string;
  selectPackage:Package;
  panImageSrc: any;
  invoiceImageSrc:any;
  aadhaarImageSrc: any;
  isCorporate = false;
  user:User;
  showInsuranceDetails: boolean;
  claimYear: number;
  breakInspectionReportImageSrc: any;


  constructor(private packageService:PackageService,private fileService:FileService,  private sanitizer: DomSanitizer, private accountServices:AccountService,private router:Router) { }

  ngOnInit(): void {
    this.user = this.accountServices.userValue;
    if (["admin","employee"].includes(this.user.role))
    {  this.showInsuranceDetails = true;}
    else
      {this.showInsuranceDetails = false;}

    this.selectPackage = this.packageService.printPackage;
    this.claimYear = new Date().getFullYear() - this.selectPackage.ncbPercentValue;
    this.isCorporate = this.selectPackage.clientType == "Individual"?false:true;
    if (this.selectPackage.panUrl){
      this.fileService.getImageData(this.selectPackage.panUrl)
      .subscribe(a => {
        let objectURL = URL.createObjectURL(a);
        this.panImageSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL);

      })
    }
    // .subscribe(a => this.panImageSrc = a )

    if (this.selectPackage.aadhaarUrl.length > 0){
      this.fileService.getImageData(this.selectPackage.aadhaarUrl)
    .subscribe(a => {
      let objectURL = URL.createObjectURL(a);
      this.aadhaarImageSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
      )
  }
  if (this.selectPackage.breakInspectionReportUrl){
    this.fileService.getImageData(this.selectPackage.breakInspectionReportUrl.toString())
      .subscribe(a => {
        let objectURL = URL.createObjectURL(a);
        this.breakInspectionReportImageSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
        )
    }  
  if (this.selectPackage.invoiceUrl){
    this.fileService.getImageData(this.selectPackage.invoiceUrl)
      .subscribe(a => {
        let objectURL = URL.createObjectURL(a);
        this.invoiceImageSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
        )
    }  
  }

  createImageFromBlob(image: Blob, imageType:string) {
    const reader = new FileReader();
    // reader.addEventListener('load', () => {
    //   if (imageType == "pan")
    //   {
    //     this.panImageSrc = reader.result as string;
    //   }
    //   else
    //   {
    //     this.aadhaarImageSrc = reader.result as string;

    //   }

    // }, false);
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      this.panImageSrc = reader.result as string;
    };
    // if (image) {
    //   reader.readAsDataURL(image);
    // }
  }
  downloadImage(imageType:string) {
    const link = document.createElement('a');
    if (imageType == "pan")
    {
      link.href = this.panImageSrc.changingThisBreaksApplicationSecurity;
      link.download = this.selectPackage.panUrl;
    }
    else {if (imageType == "invoice")
    {
      link.href = this.invoiceImageSrc.changingThisBreaksApplicationSecurity;
      link.download = this.selectPackage.invoiceUrl;

    }
    else {
      if (imageType == "breakInspectionReport")
      {
        link.href = this.breakInspectionReportImageSrc.changingThisBreaksApplicationSecurity;
        link.download = this.selectPackage.breakInspectionReportUrl.toString();
      }
    else
    {
      link.href = this.aadhaarImageSrc.changingThisBreaksApplicationSecurity;;
      link.download = this.selectPackage.aadhaarUrl;
    }
  }
  }

    link.click();
  }
  backToSearch(){
    this.router.navigate(['../reports/printCertificate'], { relativeTo: null });
  }
}
