<h1>Package List</h1>
<form [formGroup]="certificateReportForm">
  <div class="form-row">
    <div class="form-group col">
      <label for="certificateNumber">Package Number</label>
      <input type="text" formControlName="packageNumber" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.packageNumber.errors }" />
    </div>
    <div class="form-group col">
      <label for="chasisNumber">Chasis Number</label>
      <input type="text" formControlName="chasisNumber" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.chasisNumber.errors }" />
    </div>
    <div class="form-group col">
      <label for="firstName">Client First Name</label>
      <input type="text" formControlName="firstName" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
    </div>
    <div class="form-group col">
      <label for="lastName">Client Last Name</label>
      <input type="text" formControlName="lastName" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
    </div>
    <div class="form-group col">
      <label for="userName">Executive User Name</label>
      <input type="text" formControlName="userName" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
    </div>
    <div class="form-group col">
      <label for="issueDateFrom">Issue Date From</label>
      <div class="input-group">
        <input class="form-control" formControlName="issueDateFrom" ngbDatepicker #d="ngbDatepicker"
          [ngClass]="{ 'is-invalid': submitted && f.issueDateFrom.errors }">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
        </div>
      </div>
      <div *ngIf="submitted && f.issueDateFrom.errors" class="invalid-feedback">
        <div *ngIf="f.issueDateFrom.errors.required">Issue Date From</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="issueDateTo">Issue Date To</label>
      <div class="input-group">
        <input class="form-control" formControlName="issueDateTo" ngbDatepicker #issueDateTo="ngbDatepicker"
          [ngClass]="{ 'is-invalid': submitted && f.issueDateTo.errors }">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary fa fa-calendar" (click)="issueDateTo.toggle()"
            type="button"></button>
        </div>
      </div>
      <div *ngIf="submitted && f.issueDateTo.errors" class="invalid-feedback">
        <div *ngIf="f.issueDateTo.errors.required">Issue Date From</div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group">
      <button (click)="onSubmit()" [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Search
      </button><a>&nbsp;&nbsp;</a>
      <button class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
        Reset
      </button>
    </div>
  </div>
  <div class="form-row" style="width:100%; overflow: scroll;">
    <div class="form-group col" style="width:100%; overflow: scroll;">



      <table class="table table-striped" style="width:100%; min-height: 100px; overflow: scroll;">
        <thead>
          <tr>
            <th >Package Number</th>
            <th  *ngIf="user.role == 'admin' || user.role == 'employee'">Dealer Name</th>
            <th >Owner Name</th>
            <th >Certificate Number</th>
            <th >Bike Model</th>
            <th >Policy Number</th>
            <th >Policy Type</th>
            <th >Bike Issue Date</th>
            <th >Package Status</th>
            <th  *ngIf="user.role == 'admin' || user.role == 'employee'">Entered By</th>
            <th >Action</th>
          </tr>
        </thead>
        <tbody style="min-height: 50px;">
          <tr *ngFor="let package of packages">
            <td>{{package.packageNumber}}</td>
            <td *ngIf="user.role == 'admin' || user.role == 'employee'">{{package.dealerName}}</td>
            <td>{{package.firstName + " " + (package.lastName?package.lastName:"")}}</td>
            <td>{{package.certificateNumber}}</td>
            <td>{{package.model}}</td>
            <td>{{package.insurancePolicyNumber}}</td>
            <td>{{package.type == 'Old'? "Renewal":"New"}}</td>
            <td>{{package.issueDateString}}</td>
            <td>{{package.packageStatus}}</td>
            <td *ngIf="user.role == 'admin' || user.role == 'employee'">{{package.userNameProcessed}}</td>
            <td style="white-space: nowrap">

              <div class="dropdown" *ngIf="isAnyActionAvailable(package, user)">
                <button class="btn btn-sm dropdown-toggle"
                  [ngClass]="{'btn-secondary': ['Submitted','Certificate Generated'].includes(package.packageStatus) && ['admin','employee'].includes(user.role), 'btn-primary': !(['Submitted','Certificate Generated'].includes(package.packageStatus) && ['admin','employee'].includes(user.role))}"
                  type="button" id="dropdownActionButton" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  Actions
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownActionButton">

                  <a class="dropdown-item" (click)="processPackage(package)"
                    *ngIf="package.packageStatus== 'Submitted' && ((user.role == 'admin' || user.role == 'employee') || (user.specialRoleAccess && user.specialRoleAccess.includes('CanGenerateOldPolicyPackage') && package.type=='Old')) "
                    [attr.disabled]="package.packageStatus!= 'Submitted' ? true : null">
                    <i class="fa fa-cog"></i> Generate Package
                  </a>
                  <a class="dropdown-item" (click)="preparePackage(package)"
                    *ngIf="((package.packageStatus =='Certificate Generated' && user.role == 'employee')) || (['Certificate Generated','Print Ready','Endorsement Requested','Cancelation Requested'].includes(package.packageStatus) && user.role == 'admin' && (package.certificateNumber) )">
                    <i class="fa fa-arrow-down"></i> Prepare Package
                  </a>
                  <a class="dropdown-item" (click)="viewPackage(package)"
                    *ngIf="((user.role == 'admin' || user.role == 'employee'))">
                    <i class="fa fa-eye"></i> View
                  </a>
                  <a class="dropdown-item text-danger" (click)="selectCancelPackage(package)" data-bs-toggle="modal"
                    data-bs-target="#myModal"
                    *ngIf="['Certificate Generated','Submitted','Print Ready'].includes(package.packageStatus) && (user.role == 'admin' || user.role == 'employee')">
                    <i class="fa fa-times-circle"></i> Request Cancel
                  </a>
                  <a class="dropdown-item text-info" (click)="selectCancelPackage(package)" data-bs-toggle="modal"
                    data-bs-target="#myEndorseRequestModal"
                    *ngIf="['Certificate Generated','Print Ready'].includes(package.packageStatus) && (user.role == 'admin' || user.role == 'employee')">
                    <i class="fa fa-pen-nib"></i> Request Endorsement
                  </a>
                  <a class="dropdown-item" (click)="selectCancelPackage(package)" data-bs-toggle="modal"
                    data-bs-target="#showCommentHistoryModel"
                    *ngIf="(package && package.requestHistory && package.requestHistory.length > 0) && (user.role == 'admin' || user.role == 'employee')">
                    <i class="fa fa-upload"></i> Comment History
                  </a>
                  <div class="dropdown-divider" *ngIf="user.role == 'admin'"></div>
                  <a class="dropdown-item" (click)="selectCancelPackage(package)" data-bs-toggle="modal"
                  data-bs-target="#myPdfEndorseModel"
                  *ngIf="['Endorsement Requested'].includes(package.packageStatus) && (user.role == 'admin' )">
                  <i class="fa fa-pen-nib"></i> Endorse
                </a>
                  <a class="dropdown-item" (click)="uploadPackage(package)" data-bs-toggle="modal"
                    data-bs-target="#myPdfUploadModel"
                    *ngIf="['Certificate Generated','Submitted','Print Ready'].includes(package.packageStatus) && (user.role == 'admin' )">
                    <i class="fa fa-upload"></i> Upload
                  </a>
                  <a class="dropdown-item text-danger" (click)="selectCancelPackage(package)" data-bs-toggle="modal"
                    data-bs-target="#cancelModel"
                    *ngIf="['Cancelation Requested'].includes(package.packageStatus) && (user.role == 'admin')">
                    <i class="fa fa-times-circle"></i> Cancel
                  </a>
                  <a class="dropdown-item" (click)="downloadPackage(package)"
                    *ngIf="(package.packageStatus== 'Print Ready')||(['Cancelation Requested','Endorsement Requested'].includes(package.packageStatus) && (user.role == 'admin') && (package.certificateNumber))">
                    <i class="fa fa-print"></i> Print
                  </a>
                </div>
              </div>

            </td>
          </tr>
          <tr *ngIf="loading">
            <td colspan="4" class="text-center">
              <span class="spinner-border spinner-border-lg align-center"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>




</form>

<div class="modal" id="myModal">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Are you sure?</h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <app-show-request-history></app-show-request-history>
        <form [formGroup]="cancelPackageRequestForm">

          <div class="form-group">
            <label for="cancelReason">Reason(Add Details for future reference):</label>
            <textarea formControlName="cancelReason" class="form-control"
              [ngClass]="{ 'is-invalid': cancelRequestF.cancelReason.errors }"></textarea>
            <div *ngIf="cancelRequestF.cancelReason?.errors" class="invalid-feedback">
              <div *ngIf="cancelRequestF.cancelReason?.errors.required">Cancel Reason is required</div>
            </div>
          </div>

        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" id="closeRequestCancelModal" class="btn btn-primary btn-sm mr-1"
          data-bs-dismiss="modal">Close</button>
        <button (click)="requestCancelPackage()" class="btn btn-sm btn-danger mr-1"
          *ngIf="!(canceledPackageSelect && canceledPackageSelect.packageStatus == 'Cancelation Requested')">
          <span *ngIf="isDeleting" class="spinner-border spinner-border-sm"></span>
          <span *ngIf="!isDeleting">Yes</span>
        </button>

      </div>

    </div>
  </div>
</div>

<!-- <button type="button" (click)="uploadPackage(package)" class="btn btn-sm btn-info mr-1" *ngIf="['Certificate Generated','Submitted','Print Ready'].includes(package.packageStatus) && (user.role == 'admin')" data-bs-toggle="modal" data-bs-target="#myPdfUploadModel">
  Upload 
</button> -->
<!-- Modal Start -->
<div class="modal" id="myPdfUploadModel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Upload Endorse policy PDF</h4>
      </div>
      <div class="modal-body" *ngIf=modelLoading>
        <div class="spinner-border text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <!-- Modal body -->
      <div class="modal-body" *ngIf=!modelLoading>
        <app-show-request-history></app-show-request-history>

        <!-- show bootstrap message informing completion of Upload -->
        <div *ngIf="uploadSuccess" class="alert alert-success" role="alert">
          <strong>Policy PDF uploaded successfully!!! </strong>
        </div>
        <div *ngIf="uploadSuccess" class="alert alert-success" role="alert">
          <strong>Review printed certificate by clicking on Prepare Package!!! </strong>
        </div>

        <div class="form-group col">
          <label for="policyPdf">Policy PDF file</label>
          <input type="file" (change)="onPolicyPdfSelected($event)" accept="application/pdf" class="form-control" />
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm mr-1" data-bs-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>

<div class="modal" id="showCommentHistoryModel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Comments</h4>
      </div>
      <div class="modal-body">
        <app-show-request-history></app-show-request-history>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm mr-1" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="cancelModel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Cancel</h4>
      </div>
      <div class="modal-body">
        <app-show-request-history></app-show-request-history>
        <form [formGroup]="cancelPackageRequestForm">
          <div class="container">
            <div class="row" *ngIf="canceledPackageSelect && canceledPackageSelect.certificateNumber">
              <div class="col">
                <!-- create a bootstrap toggle for packageAmountReversed and if packageAmountReversed is false then show button to create transaction -->
                <!-- Bootstrap toggle -->
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="packageAmountReversedToggle"
                    formControlName="packageAmountReversed">
                  <label class="form-check-label" for="packageAmountReversedToggle">Has Insurance Company Reversed
                    Policy Amount?</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="cancelReason">Comments:</label>
              <textarea formControlName="cancelReason" class="form-control"
                [ngClass]="{ 'is-invalid': cancelRequestF.cancelReason.errors }"></textarea>
              <div *ngIf="cancelRequestF.cancelReason?.errors" class="invalid-feedback">
                <div *ngIf="cancelRequestF.cancelReason?.errors.required">Cancel Reason is required</div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm mr-1" data-bs-dismiss="modal">Close</button>
        <button (click)="cancelPackage()" class="btn btn-sm btn-danger mr-1"
          *ngIf="!(canceledPackageSelect && canceledPackageSelect.packageStatus == 'Cancelled')">
          <span *ngIf="isDeleting" class="spinner-border spinner-border-sm"></span>
          <span *ngIf="!isDeleting">Cancel</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="myEndorseRequestModal">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Endorsement</h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <app-show-request-history></app-show-request-history>
        <form [formGroup]="cancelPackageRequestForm">

          <div class="form-group">
            <label for="cancelReason">Endorsement Details(add information of all values modified):</label>
            <textarea formControlName="cancelReason" class="form-control"
              [ngClass]="{ 'is-invalid': cancelRequestF.cancelReason.errors }"></textarea>
            <div *ngIf="cancelRequestF.cancelReason?.errors" class="invalid-feedback">
              <div *ngIf="cancelRequestF.cancelReason?.errors.required">Endorsement Details is required</div>
            </div>
          </div>

        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" id="closeRequestCancelModal" class="btn btn-primary btn-sm mr-1"
          data-bs-dismiss="modal">Close</button>
        <button (click)="requestEndorsePackage()" class="btn btn-sm btn-danger mr-1"
          *ngIf="!(canceledPackageSelect && canceledPackageSelect.packageStatus == 'Endorsement Requested')">
          <span *ngIf="isDeleting" class="spinner-border spinner-border-sm"></span>
          <span *ngIf="!isDeleting">Request</span>
        </button>

      </div>

    </div>
  </div>
</div>

<div class="modal" id="myPdfEndorseModel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Endorsement</h4>
      </div>
      <div class="modal-body" *ngIf=modelLoading>
        <div class="spinner-border text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <!-- Modal body -->
      <div class="modal-body" *ngIf=!modelLoading>
        <app-show-request-history></app-show-request-history>
        <form [formGroup]="cancelPackageRequestForm">
          <div class="container">
            <h4>Step 1: Endorse the Certificate</h4>
            <div class="row" *ngIf="canceledPackageSelect && canceledPackageSelect.certificateNumber">
              <div class="col">
                <!-- Bootstrap toggle -->
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="packageAmountReversedToggle"
                    formControlName="packageAmountReversed">
                  <label class="form-check-label" for="packageAmountReversedToggle">Is certificate endorsed successfully?</label>
                </div>
              </div>
            </div>
        
            <!-- show bootstrap message informing completion of Upload -->
            <div *ngIf="uploadSuccess" class="alert alert-success" role="alert">
              <strong>Endorsement Completed!!! Modified policy PDF has been attached to package. Close this window by clicking on close button</strong>
            </div>
        
            <h4 *ngIf="cancelPackageRequestForm.get('packageAmountReversed').value">Step 2: Upload the Endorsed Policy</h4>
            <div class="form-group" *ngIf="cancelPackageRequestForm.get('packageAmountReversed').value">
              <label for="policyPdf">Upload Endorsed Policy PDF file</label>
              <input type="file" (change)="onPolicyPdfSelected($event)" accept="application/pdf" class="form-control" />
            </div>
        
            <div class="form-group">
              <label for="cancelReason">Comments:</label>
              <textarea formControlName="cancelReason" class="form-control"
                [ngClass]="{ 'is-invalid': cancelRequestF.cancelReason.errors }"></textarea>
              <div *ngIf="cancelRequestF.cancelReason?.errors" class="invalid-feedback">
                <div *ngIf="cancelRequestF.cancelReason?.errors.required">Endorsement comment is required</div>
              </div>
            </div>
          </div>
        </form>
      </div>


      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm mr-1" data-bs-dismiss="modal">Close</button>
        <button (click)="endorsePackage()" class="btn btn-sm btn-danger mr-1"
          *ngIf="(canceledPackageSelect && canceledPackageSelect.packageStatus == 'Endorsement Requested')">
          <span *ngIf="isDeleting" class="spinner-border spinner-border-sm"></span>
          <span *ngIf="!isDeleting">Endorsement Completed</span>
        </button>
      </div>

    </div>
  </div>
</div>
