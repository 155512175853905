import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Package } from '@app/_models/package';
import { Location } from '@angular/common';
import { AlertService } from '@app/_services';
import { first } from 'rxjs';
import { PackageService } from '../package.service';

@Component({
  selector: 'app-confirm-package',
  templateUrl: './confirm-package.component.html',
  styleUrls: ['./confirm-package.component.less']
})
export class ConfirmPackageComponent implements OnInit {

  selectPackage:Package;
  loading = false;
  confirmed = false;
  constructor(private router:Router,
    private alertService:AlertService,
    private packageService:PackageService,
    private location: Location) { }

  ngOnInit(): void {
    this.selectPackage = this.packageService.printPackage;

  }

  onConfirm(){
    this.loading = true;
    this.confirmed = true;
    this.packageService.confirmPackage(this.selectPackage)
    .pipe(first())
    .subscribe({
      next: (x) => {
        this.packageService.printPackage = new Package(x);
        this.alertService.success('Package - ' + x.packageNumber + ' submitted successfully', { keepAfterRouteChange: true });
        this.loading = false;
      },
      error: error => {
        this.alertService.error(error);
        this.loading = false;
      }
    });

  }
  onReset(){
    this.location.back();
  }
  onEdit(){
    this.router.navigate(['/issueCertificate/select-package']);
  }
}
