<div class="container" *ngIf="(requestHistory$ | async)?.length > 0">
  <div class="row">
    <div class="col-md-12">
      <h4>Comment History - {{package.packageNumber}}</h4>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>User</th>
            <th>type</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let request of requestHistory$ | async">
            <td>{{ request.requestedDate  | date: 'medium'}}</td>
            <td>{{ request.requestedBy }}</td>
            <td>{{ request.requestType }}</td>
            <td>{{ request.comment }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
