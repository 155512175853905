import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx/xlsx.mjs';
import { UntypedFormBuilder,UntypedFormGroup, Validators } from '@angular/forms';
import { Dealer } from '@app/_models/dealer';
import { PackageDetails } from '@app/_models/packageDetails';
import { AccountService, AlertService } from '@app/_services';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { first } from 'rxjs';
import { DealerModelPrice } from '@app/_models/dealerModelPrice';
import { DealerModelConfig } from '@app/_models/dealerModelConfig';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'app-dealer-price-list-maintain',
  templateUrl: './dealer-price-list-maintain.component.html',
  styleUrls: ['./dealer-price-list-maintain.component.less']
})
export class DealerPriceListMaintainComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  private gridApi!: GridApi;
  dealerModelConfig: DealerModelConfig[];

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }  
  
  gridReady = false;
  gridOptions: GridOptions;
  dealerPriceForm: UntypedFormGroup;
submitted: any;
dealers:Dealer[];
dealerModelPrices: DealerModelPrice[];
dealerModelPrice: DealerModelPrice;
selectedDealerModelConfig: DealerModelConfig;
  packages: PackageDetails[];
  excelData: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private configDataService: ConfigDataService,
    private dealerService: DealerService

  ) { }

  ngOnInit(): void {
    this.dealerPriceForm = this.formBuilder.group({
      dealerId: ['',Validators.required],
      applicableOn: ['Both',Validators.required],
      dealerName:[{value:'',disabled:true}],
      dealerCity:[{value:'',disabled:true}],
      uploadFile:[]
    });
    this.gridOptions = <GridOptions>{};
    this.gridOptions.columnDefs = [
      { headerName: "Make", field: "make", sortable: true, filter: true,editable: true },
      { headerName: "Model", field: "model", sortable: true, filter: true,editable: true },
      { headerName: "HeroVariant", field: "heroVariant", sortable: true, filter: true,editable: true },
      { headerName: "OrientalVariantCode", field: "orientalVariantCode", sortable: true, filter: true,editable: true },
      { headerName: "ExShowroomAmount", field: "exShowroomAmount", sortable: true, filter: true,editable: true },
      { headerName: "IDVPercentage", field: "iDVPercentage", sortable: true, filter: true,editable: true },
      { headerName: "TotalAmount", field: "totalAmount", sortable: true, filter: true,editable: true },
      { headerName: "InsuranceAmount", field: "insuranceAmount", sortable: true, filter: true,editable: true },
      { headerName: "AllianceAmount", field: "allianceAmount", sortable: true, filter: true,editable: true },
      { headerName: "AadharUploadMandatory", field: "aadharUploadMandatory", sortable: true, filter: true,editable: true },
      { headerName: "InvoiceUploadMandatory", field: "invoiceUploadMandatory", sortable: true, filter: true,editable: true },
      { headerName: "PanUploadMandatory", field: "panUploadMandatory", sortable: true, filter: true,editable: true },
      { headerName: "CubicCapacity", field: "cubicCapacity", sortable: true, filter: true,editable: true },
    ]
    this.dealerService.getAll().pipe(first())
    .subscribe({
      next: (x) => {
        this.dealers = x.filter(a=>a.dealerCreatePackage == true );
      },
      error: error => {
        this.alertService.error(error)
      }
    })
    this.f.dealerId.valueChanges.subscribe((a)=>{
      if(a){
        this.f.dealerName.setValue(this.dealers.find(x=>x.dealerId == a).dealerName);
        this.f.dealerCity.setValue(this.dealers.find(x=>x.dealerId == a).dealerCity);
        this.onSubmit();
      }
    })

    this.f.applicableOn.valueChanges.subscribe((a)=>{
      if(a){
        if (this.dealerModelConfig){
          this.selectedDealerModelConfig = this.dealerModelConfig.find(x=>[undefined,"Both",a].includes(x.applicableOn));
          this.gridReady = false;
          if(this.selectedDealerModelConfig && this.selectedDealerModelConfig.dealerModelPrices.length > 0){
            if (this.gridApi)
            {
            this.gridApi.setRowData(this.selectedDealerModelConfig.dealerModelPrices);
            }
          }
          else{
            if (this.gridApi)
            {
            this.gridApi.setRowData([]);
            }
          }
          this.gridReady = true;

        }
      }
    })
  }
  get f() { return this.dealerPriceForm.controls; }

  onSubmit(){
    this.submitted = true;
    if(this.dealerPriceForm.invalid){
      return;
    }
    this.gridReady = false;
    this.configDataService.getDealerModelConfig(this.f.dealerId.value).pipe(first()).subscribe({
      next: (x) => { 
        this.dealerModelConfig = x;
        this.selectedDealerModelConfig = this.dealerModelConfig.find(a=> [undefined,"Both",this.f.applicableOn.value].includes(a.applicableOn));
        if(this.selectedDealerModelConfig){
          if (this.gridApi)
          this.gridApi.setRowData(this.selectedDealerModelConfig.dealerModelPrices);
        }
        else{
          if (this.gridApi)
          this.gridApi.setRowData([]);
        }
        this.gridReady = true;
      },
      error: error => {
        this.alertService.error(error)
      }
    })

  }
  addRow(){
    this.dealerModelPrice = new DealerModelPrice();
    this.gridApi.applyTransaction({ add: [this.dealerModelPrice] });
  }
  async onFileChange(event:any){
    if (event.target.files.length !== 1) throw new Error('Cannot upload multiple files');
    const file = event.target.files[0];
    const data =  await file.arrayBuffer();
    const workbook = XLSX.read(data);
    this.gridReady = false;
    this.excelData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
      raw: false,
      header: 2, cellStyles: true
    }).filter(a => a.Model).map(a => {
      this.dealerModelPrice = new DealerModelPrice();
      this.dealerModelPrice.make = a.Make;
      this.dealerModelPrice.model = a.Model;
      this.dealerModelPrice.insuranceAmount = a.InsuranceAmount && a.InsuranceAmount > 0 ? a.InsuranceAmount : 0;
      this.dealerModelPrice.allianceAmount = a.AllianceAmount && a.AllianceAmount > 0 ? a.AllianceAmount : 0;
      this.dealerModelPrice.totalAmount = a.TotalAmount;
      this.dealerModelPrice.exShowroomAmount = a.ExShowroomAmount;
      this.dealerModelPrice.aadharUploadMandatory = a.AadharUploadMandatory;
      this.dealerModelPrice.invoiceUploadMandatory = a.InvoiceUploadMandatory;
      this.dealerModelPrice.panUploadMandatory = a.PanUploadMandatory;
      this.dealerModelPrice.cubicCapacity = a.CubicCapacity;
      this.dealerModelPrice.heroVariant = a.HeroVariant;
      this.dealerModelPrice.orientalVariantCode = a.OrientalVariantCode;
      this.dealerModelPrice.iDVPercentage = a.IDVPercentage;
      return this.dealerModelPrice;    
    });
    //populate aggrid variable here
    this.dealerModelPrices = this.excelData;
    this.gridOptions.rowData = this.dealerModelPrices;
    if (this.gridApi){
    this.gridApi.setRowData(this.dealerModelPrices);
  }
    this.gridReady = true;

  }
  saveData(){
    if(this.dealerPriceForm.invalid){
      return;
    }
    this.gridReady = false;
    this.selectedDealerModelConfig = new DealerModelConfig();
    this.selectedDealerModelConfig.dealerId = this.f.dealerId.value;
    this.selectedDealerModelConfig.applicableOn = this.f.applicableOn.value;
    const rowData = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    this.selectedDealerModelConfig.dealerModelPrices = rowData.sort((a,b)=>a.make + a.model > b.make + b.model ? 1 : -1);
    // replace selectedDealerModelConfig in dealerModelConfigs and if dont exist then add based on delarid and insurance company
    this.configDataService.updateDealerModelConfig(this.selectedDealerModelConfig).pipe(first()).subscribe({
      next: (x) => { 
        this.selectedDealerModelConfig = new DealerModelConfig(x);
        //replace selectedDealerModelConfig in dealerModelConfigs and if dont exist then add based on delarid and applicableon
        const index = this.dealerModelConfig.findIndex(a=>a.dealerId == this.selectedDealerModelConfig.dealerId && a.applicableOn == this.selectedDealerModelConfig.applicableOn);
        if (index > -1){
          this.dealerModelConfig[index] = this.selectedDealerModelConfig;
        }
        else{
          this.dealerModelConfig.push(this.selectedDealerModelConfig);
        }
        console.log(JSON.stringify(this.selectedDealerModelConfig.dealerModelPrices));
        this.gridOptions.rowData = this.selectedDealerModelConfig.dealerModelPrices;
        //refresh aggrid data with new data
        this.gridApi.setRowData(this.selectedDealerModelConfig.dealerModelPrices);
        // this.gridApi.setRowData(this.selectedDealerModelConfig.dealerModelPrices);
        this.gridReady = true;
        this.alertService.success("Dealer Model Config Updated Successfully");

      },
      error: error => {
        this.alertService.error(error)
      }
    });

  }
  exportToExcel() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: this.f.dealerId.value + "_DealerPriceList",
      //     columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.aggrid.api.exportDataAsCsv(params);
    // this.aggrid.api.exportDataAsExcel(params);
  }
  onDelete(){
    this.configDataService.deleteDealerModelConfig(this.f.dealerId.value).pipe(first()).subscribe({
      next: (x) => { 
        this.gridApi.setRowData([]);
        this.alertService.success("Dealer Model Config Deleted Successfully");
      },
      error: error => {
        this.alertService.error(error)
      }
    });
  }
}
