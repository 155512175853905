import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HomeComponent } from './home';;
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
;
import { MultiSelectDropdownComponent } from './_helpers/multi-select-dropdown/multi-select-dropdown.component';
import { PolicyNumberMaintainComponent } from './home/policy-number-maintain/policy-number-maintain.component'
;
import { AccountModule } from './account/account.module';
import { UsersModule } from './users/users.module';
import { ReportsModule } from './reports/reports.module';
import { IssueCertificateModule } from './issue-certificate/IssueCertificateModule';
import { PrintModule } from './print/print.module';
// import { QRCodeModule } from 'angularx-qrcode';;
import { ProcessPackageComponent } from './issue-certificate/process-package/process-package.component'
;
import { ViewPackageComponent } from './issue-certificate/view-package/view-package.component'
;
import { ConfirmPackageComponent } from './issue-certificate/confirm-package/confirm-package.component'
;
import { DealerPriceListMaintainComponent } from './home/dealer-price-list-maintain/dealer-price-list-maintain.component'
import { AgGridModule } from 'ag-grid-angular';
@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        // QRCodeModule,
        AccountModule,
        UsersModule,
        ReportsModule,
        IssueCertificateModule,
        PrintModule,
        AgGridModule,
        NgbModule
        ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        MultiSelectDropdownComponent,
        PolicyNumberMaintainComponent
,
        ProcessPackageComponent
,
        ViewPackageComponent ,
        ConfirmPackageComponent ,
        DealerPriceListMaintainComponent     ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };