<h1 *ngIf="isAddMode">Add User</h1>
<h1 *ngIf="!isAddMode">Edit User</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-row">
        <div class="form-group col">
            <label for="firstName">First Name</label>
            <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">First Name is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="lastName">Last Name</label>
            <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="username">Username</label>
            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="password">
                Password
                <em *ngIf="!isAddMode">(Leave blank to keep the same password)</em>
            </label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="role">Role</label>
            <select class="form-select" formControlName="role" [ngClass]="{ 'is-invalid': submitted && f.role.errors }" >
                <option *ngFor="let role of roles" [ngValue]="role">
                  {{ role }}
                </option>
              </select>
              <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                <div *ngIf="f.role.errors.required">Role needs to be selected</div>
            </div>
        </div>
        <div class="form-group col" *ngIf="user.role =='admin'">
            <label for="specialRoleAccess">Special Role</label>
            <select multiple="multiple" class="form-control" formControlName="specialRoleAccess" [ngClass]="{ 'is-invalid': submitted && f.specialRoleAccess.errors }" >
                <option *ngFor="let specialRole of specialRoles" [ngValue]="specialRole">
                  {{ specialRole }}
                </option>
              </select>
        </div>
        <div class="form-group col" *ngIf="user.role =='admin'">
            <label for="employeeDealerList">Employee Dealer List</label>
            <select multiple="multiple" class="form-control" formControlName="employeeDealerList" [ngClass]="{ 'is-invalid': submitted && f.employeeDealerList.errors }" >
                <option *ngFor="let dealer of dealerList" [ngValue]="dealer.dealerId">
                  {{ dealer.dealerGroupName ? dealer.dealerGroupName + "-" + dealer.dealerName : dealer.dealerName }}
                </option>
              </select>
        </div>

        <div class="form-group col">
            <label for="dealerId">Dealer Id</label>
            <input type="text" formControlName="dealerId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerId.errors }" />
            <div *ngIf="submitted && f.dealerId.errors" class="invalid-feedback">
                <div *ngIf="f.dealerId.errors.required">Dealer Id is required</div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
        </button>
        <a routerLink="../../" class="btn btn-link" *ngIf="!isAddMode">Cancel</a>
        <a routerLink="../" class="btn btn-link" *ngIf="isAddMode">Cancel</a>
    </div>
</form>

